import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { Flex, Slider, Promo } from '@beauty/beauty-market-ui';
import { CustomLink, OrganisationCard } from '../../../components';
import { StyledPromo } from '../../../components/Organisation/style';
import { ServiceIconsBlock } from '../../../components/Services/components/ServiceIconsBlock';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../constants';
import {
  isRtl,
  createQueryParams,
  getFullOrganisationLink,
  getTranslatedString,
  sortByOurChoice,
} from '../../../helpers';
import { AsyncComponent } from '../../../hoc';
import { useMediaScreen } from '../../../hooks';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { currentTopCategory } from '../../../store/redux-slices/categoriesSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { OrganisationRenderType } from '../../../types/organisation';
import { ID_ALL_CATEGORY } from '../constants';
import { OffersGrid, StyledButton } from '../style';

export const OrganisationsSection = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('sm');

  const { isForceFetch, favourites } = useAppSelector(selectUser);
  const currentCategory = useAppSelector(currentTopCategory);

  const currentCategoryId = currentCategory?.id || ID_ALL_CATEGORY;

  const { isLoading, organisations } = useGetOrganisations(currentCategoryId, isForceFetch);

  const rtl = isRtl();
  const language = getSelectedLanguage();

  const promoCard = (
    <StyledPromo key="promo">
      <Promo
        headline=""
        title={t('home.placeForBusiness')}
        isBlueHover
        href=""
        photos=""
        onShareClick={() => undefined}
      />
    </StyledPromo>
  );

  const organizationsList: Nullable<JSX.Element[]> = useMemo(() => {
    let list = organisations ? [...sortByOurChoice(organisations)] : [];
    isDesktop && (list = list.slice(0, 6));

    const result = !isLoading
      ? list.map((organisation: OrganisationRenderType) => (
          <OrganisationCard
            id={organisation.id}
            key={organisation.id}
            rating={organisation.rating}
            image={organisation.mainPhoto}
            headline={getTranslatedString(language, organisation.category)}
            title={organisation.name}
            photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
            reviews={t('organisation.reviews', { count: organisation.review })}
            badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
            href={`${RouterUrl.Organisation}/${organisation.id}`}
            isBlueHover
            favourite={favourites.ids?.includes(organisation.id)}
            state={{}}
          />
        ))
      : null;

    result && result.length < 6 && result.push(promoCard);
    return result;
  }, [organisations, currentCategory, isDesktop, t, isLoading, OurChoiceOrganisations, favourites.ids]);

  return (
    <Flex flexDirection="column" backgroundColor="grey" mb={['80px', '80px', '160px', '160px']}>
      <ServiceIconsBlock />
      <AsyncComponent isLoading={isLoading}>
        <Flex mt={['32px', '32px', '32px', '0']} mb={['0', '0', '40px', '40px']}>
          {isDesktop ? (
            <OffersGrid>{organizationsList}</OffersGrid>
          ) : (
            organizationsList &&
            !isEmpty(organizationsList) && <Slider mb="31px" inlineSlider slides={organizationsList} rtl={rtl} />
          )}
        </Flex>
      </AsyncComponent>
      <Flex justifyContent="center" width={['100%', '100%', 'auto', 'auto']}>
        <CustomLink to={`${RouterUrl.AllOffers}/${currentCategoryId}${createQueryParams({ map: true })}`}>
          <StyledButton>{t('home.allOffers')}</StyledButton>
        </CustomLink>
      </Flex>
    </Flex>
  );
};
