import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Separator, H2, Tabs, Tab, MailFillIcon, BodySmall, PhoneFillIcon, Div } from '@beauty/beauty-market-ui';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { IconWrapper, LoginChoiceWrapper, TabsWrapper } from '../style';
import CreateNewAccount from './CreateNewAccount';
import GoogleButton from './GoogleButton';
import LoginByEmail from './LoginByEmail';
import LoginByPhone from './LoginByPhone';
import { Rules } from './Rules';

const LogInto = () => {
  const { t } = useTranslation();
  const [isEmail, setIsEmail] = useState<boolean>(true);
  const { business } = useGetUrl();

  const choiceLogin = useMemo(
    () => [
      {
        icon: isEmail ? <PhoneFillIcon /> : <MailFillIcon />,
        text: isEmail ? t('login.phone') : t('login.email'),
        function: () => setIsEmail(!isEmail),
      },
      // {
      //   icon: <AppleIcon />,
      //   text: 'Apple ID',
      //   function: () => console.log('Apple ID'),
      // },
      // {
      //   icon: (
      //     <Icon stroke="none" fill="#4267B2" width="24px" heigth="24px">
      //       <FacebookIcon />
      //     </Icon>
      //   ),
      //   text: 'Facebook',
      //   function: () => console.log('Facebook'),
      // },
    ],
    [isEmail, t],
  );

  return (
    <>
      <H2 mb="0px">{t('login.login')}</H2>
      <BodySmall mb="30px" mt="16px">
        {isEmail ? t('login.emailHint') : t('login.phoneHint')}
      </BodySmall>
      <TabsWrapper>
        <Tabs stroked activeIndex={0}>
          <Tab label={t('login.customer')}>
            <Div mt="15px" width="100%">
              {isEmail ? <LoginByEmail /> : <LoginByPhone />}
            </Div>
          </Tab>

          <Tab label={t('login.business')} url={business} />
        </Tabs>
      </TabsWrapper>

      <CreateNewAccount />

      <Separator text={t('login.or')} />
      <LoginChoiceWrapper>
        {choiceLogin.map(item => (
          <IconWrapper key={item.text} onClick={() => item.function()}>
            {item.icon}
          </IconWrapper>
        ))}

        <GoogleButton />
      </LoginChoiceWrapper>
      <Rules />
    </>
  );
};

export default LogInto;
