import styled from 'styled-components';
import { Flex, BodySmall, colors, mediaQueries, Label, ArrowLeftIcon, Button, Div } from '@beauty/beauty-market-ui';

export const StatePanelWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${mediaQueries.md} {
    width: 290px;
  }
`;

export const StatePanelSticky = styled(Flex)`
  position: sticky;
  top: 144px;
  flex-direction: column;
`;

export const SelectPanelWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  ${mediaQueries.md} {
    width: calc(100% - 330px);
  }
`;

export const StatePanelInfo = styled(Flex)`
  flex-direction: column;
  margin-top: 8px;
  padding-top: 16px;
  gap: 40px;
`;

export const GreyBodySmall = styled(BodySmall)`
  && {
    color: ${props => props.theme.colors.grey.dark};
  }
  white-space: pre;
`;

export const AccountLogInWrapper = styled(Flex)`
  flex-direction: column;
  padding: 24px 16px;
  gap: 16px;
  background-color: ${colors.grey.light};
  border-radius: 12px;
`;

export const BookingWrapper = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  width: 100%;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: 40px;
  }
`;

export const MobileOffsetY = styled(Div)`
  margin: 45px 0 100px;

  ${mediaQueries.md} {
    margin-top: 0;
  }
`;

export const StyledLabel = styled(Label)`
  :hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  ${({ theme }) => theme.rtl && 'transform: scaleX(-1)'};
  width: ${({ width }) => width}px !important;
  height: ${({ height }) => height}px !important;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  min-width: 228px;
`;
