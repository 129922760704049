export enum DiscountNameType {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
  PromoCode = 'PROMO_CODE',
  HappyHour = 'HAPPY_HOUR',
}

export enum DiscountValueType {
  Percent = 'PERCENT',
  FixedAmount = 'FIXED_AMOUNT',
}

export type DiscountType = {
  createdAt: string;
  deleted: boolean;
  endDate: string;
  fixedAmount: number | null;
  headOrgId: string;
  id: string;
  isActive: boolean;
  percent: number;
  startDate: string;
  type: DiscountNameType;
  valueType: DiscountValueType;
};

export enum DiscountServiceValueType {
  Variable = 'VARIABLE',
  Fixed = 'FIXED',
}

type DiscountServiceType = {
  type: DiscountServiceValueType;
  list: DiscountType[];
};
