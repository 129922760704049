import { Nullable } from 'tsdef';
import { AccType } from '../constants';
import { Language } from './general';
import { OrganizationsMap } from './organisation';

export type UserType = {
  name: string;
  surname: string;
  email: string;
  code: string;
  number: string;
  userId: string;
  avatarUrl?: string;
  lang?: string;
  idNumber?: Nullable<string>;
};

export type SocialNetworkType = {
  label: string;
  id?: string;
  contactId?: string;
  link?: string;
};
type AccountDataType = {
  name?: string;
  surname?: string;
  dateOfBirth?: Nullable<Date>;
  gender?: Nullable<string>;
  idNumber?: Nullable<string>;
  avatarUrl?: string;
  language?: string;
};
type AddressDataType = {
  id: string;
  country: Nullable<string>;
  city: Nullable<string>;
  fullAddress: Nullable<string>;
  accType?: string;
};
export type PatchProfileType = {
  accountData?: AccountDataType;
  addressData?: AddressDataType[];
};

export type CreateAccountType = {
  name: string;
  surname: string;
  code: string;
  number: string;
  email: string;
  password: string;
  marketingNotes: boolean;
  accType: AccType;
  otp?: string;
  idNumber?: string;
};

export type ProfileType = {
  name: string;
  surname: string;
  dateOfBirth: string;
  gender: string;
  code: string;
  number: string;
  email: string;
  addressId: string;
  country: string;
  city: string;
  fullAddress: string;
  avatarUrl: string;
  currency: string;
  accountNotification: string[];
  telegram: Nullable<object>;
  idNumber: string;
  language: Language;
};

export type ActionType =
  | 'addCard'
  | 'deleteAccount'
  | 'editProfile'
  | 'logout'
  | 'changeEmail'
  | 'changePhone'
  | 'smsCode'
  | 'DISCONNECT_TELEGRAM'
  | null;

export type PatchEmailType = {
  email: string;
  newEmail: string;
  host: string;
  language: string;
};

export type PostPhoneType = {
  code: string;
  number: string;
};

export type PatchPhoneType = {
  newCode: string;
  newNumber: string;
  otp: string;
} & PostPhoneType;

export enum UserActions {
  ChangeNotificationLanguage = 'CHANGE_NOTIFICATION_LANGUAGE',
  ChangePassword = 'CHANGE_PASSWORD',
  UpdateProfile = 'UPDATE_PROFILE',
  UpdatePhone = 'UPDATE_PHONE',
  AddToFavourites = 'ADD_TO_FAVOURITES',
  RemoveFromFavourites = 'REMOVE_FROM_FAVOURITES',
  DisconnectTelegram = 'DISCONNECT_TELEGRAM',
  DeleteAccount = 'DELETE_ACCOUNT',
}

export type PatchPasswordType = {
  id: string;
  data: {
    oldPassword: string;
    newPassword: string;
  };
};

export enum ClientNotificationType {
  SmsAppointment = 'APPOINTMENT_SMS',
  TelegramAppointment = 'APPOINTMENT_TELEGRAM',
  WhatsAppAppointment = 'APPOINTMENT_WHATSAPP',
  EmailMarketing = 'MARKETING_EMAIL',
  SmsMarketing = 'MARKETING_SMS',
  TelegramMarketing = 'MARKETING_TELEGRAM',
  WhatsAppMarketing = 'MARKETING_WHATSAPP',
}

export type PatchNotificationResponse = {
  id: string;
  type: ClientNotificationType;
};

export type StoreFavouritesType = {
  ids: string[];
  items: OrganizationsMap;
  selectedId: Nullable<string>;
};
