import { getFullName } from '../../../../helpers';
import { SpecialistType } from '../../../../types/specialist';

export const convertTeamForDropDown = (team: SpecialistType[], currentSpecialistId?: string) =>
  team.map(spec => ({
    id: spec.id,
    text: getFullName(spec),
    selected: spec.id === currentSpecialistId,
    imgUrl: spec.avatarUrl,
    published: spec.published,
  }));

export const formatDateForTimeslot = (day: string, language: string) => {
  const dayFormatter = Intl.DateTimeFormat(language, { day: 'numeric' });
  const weekdayFormatter = Intl.DateTimeFormat(language, { weekday: 'short' });
  const monthFormatter = Intl.DateTimeFormat(language, { month: 'short' });

  const date = new Date(day);
  return `${weekdayFormatter.format(date)}, ${dayFormatter.format(date)} ${monthFormatter.format(date)}`;
};
