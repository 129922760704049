import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BodySmall, Slider, colors } from '@beauty/beauty-market-ui';
import { FilterType } from '../../../constants';
import { createQueryParams } from '../../../helpers';
import { isRtl } from '../../../helpers/rtl';
import { useMediaScreen } from '../../../hooks';
import { useGetTopCategories } from '../../../hooks/useGetTopCategories';
import { ID_ALL_CATEGORY } from '../../../page/HomePage/constants';
import { useGetSearchParams } from '../../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentTopCategory, setCurrentTopCategory } from '../../../store/redux-slices/categoriesSlice';
import { TopCategoryWithIcon } from '../../../types';
import { CustomLink } from '../../CustomLink/CustomLink';
import { IconFlex, ServiceBlock, Wrapper } from './style';

export type IconsBlockProps = {
  isBackground?: boolean;
  isSlideLink?: boolean;
};

type AllOffersParams = {
  categoryId: string;
};

export const ServiceIconsBlock = ({ isBackground = false, isSlideLink = false }: IconsBlockProps) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useMediaScreen('md');
  const rtl = isRtl();

  const { categoryId } = useParams() as AllOffersParams;

  const currentCategory = useAppSelector(currentTopCategory);

  const { topCategoriesWithIcons } = useGetTopCategories();

  const [queryParams] = useGetSearchParams();

  const getSlide = (category: TopCategoryWithIcon) => {
    const isSelected = currentCategory?.id === category.id;
    return (
      <ServiceBlock
        key={category.id}
        isSelected={isSelected}
        onClick={() => {
          // dispatch(setFilterType(FilterType.CATEGORY));
          dispatch(setCurrentTopCategory(category.id));
        }}
      >
        <IconFlex isSelected={isSelected}>{category.icon}</IconFlex>
        <BodySmall
          color={isSelected ? colors.blue.standard : colors.black.standard}
          style={{ whiteSpace: 'nowrap', margin: '0 8px' }}
        >
          {category.title}
        </BodySmall>
      </ServiceBlock>
    );
  };

  useEffect(() => {
    dispatch(setCurrentTopCategory(categoryId || ID_ALL_CATEGORY));
  }, [categoryId, topCategoriesWithIcons]);

  return (
    <Wrapper isBackground={isBackground}>
      <Slider
        position="relative"
        mt={['16px', '16px', '16px', `0`]}
        mb={0}
        width="100%"
        slidesGap={32}
        inlineSlider
        isWhiteFade={isDesktop}
        slides={topCategoriesWithIcons.map(category =>
          isSlideLink ? (
            <CustomLink to={`${RouterUrl.AllOffers}/${category.id}${createQueryParams(queryParams)}`} key={category.id}>
              {getSlide(category)}
            </CustomLink>
          ) : (
            getSlide(category)
          ),
        )}
        rtl={rtl}
      />
    </Wrapper>
  );
};
