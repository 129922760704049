import { Nullable } from 'tsdef';

export type CreateAppointmentParams = {
  orgServId?: string;
  clientId?: string;
  start?: string;
  end?: string;
  notes?: string;
  orgSpecId?: Nullable<string>;
};

export type RemoveAppointmentPayload = {
  id: string;
  params: {
    appointmentId: string;
  };
};

export enum VisitAction {
  RemoveAppointment = 'REMOVE',
}
