import { memo } from 'react';
import { Label, CalendarIcon, TimeslotsCard } from '@beauty/beauty-market-ui';
import { AvailableTimeslotType } from '../../../../../types/timeslot';
import { useTimeslotDay } from '../hooks';
import { Day, DayTimeslots } from '../style';

interface TimeslotsDayProps {
  day: string;
  timeslots: AvailableTimeslotType[];
}

const TimeslotsDay = ({ day, timeslots }: TimeslotsDayProps) => {
  const { selectedTimeslot, date, onClickTimeSlot } = useTimeslotDay(day);

  return (
    <Day>
      <Label icon={<CalendarIcon />} size="small" padding="0">
        {date}
      </Label>
      <DayTimeslots>
        {timeslots.map(timeslot => (
          <TimeslotsCard
            key={timeslot.start}
            data-timeslot-date={date}
            start={timeslot.start}
            end={timeslot.end}
            onClick={() => onClickTimeSlot(timeslot)}
            isActive={timeslot === selectedTimeslot}
            width="auto"
            benefit={timeslot.discount?.percent}
          />
        ))}
      </DayTimeslots>
    </Day>
  );
};

export default memo(TimeslotsDay);
