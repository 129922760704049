import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { TERMINATION_ARTICLES_COUNT } from '../constants';

type Props = {
  id: string;
};

export const Termination = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.termination.title')}</PrivacyItemTitleWrapper>
      {Array(TERMINATION_ARTICLES_COUNT)
        .fill(0)
        .map((__, i) => (
          <BodySmall key={i + 1} mb={i === TERMINATION_ARTICLES_COUNT - 1 ? '24px' : '16px'}>
            {t(`termsOfService.termination.article${i + 1}`)}
          </BodySmall>
        ))}
    </PrivacyItemWrapper>
  );
});

Termination.displayName = 'Termination';
