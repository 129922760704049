import { MapPointLocationWrapper, MapPointLocationCenter } from './style';

interface ClusterMarkerProps {
  lat?: number;
  lng?: number;
}

const MapPointLocation = (props: ClusterMarkerProps) => (
  <MapPointLocationWrapper>
    <MapPointLocationCenter />
  </MapPointLocationWrapper>
);

export default MapPointLocation;
