import styled from 'styled-components';
import { Flex, colors, transitionEffect } from '@beauty/beauty-market-ui';

// Transform is needed for correct marker positioning.
// for details see https://github.com/google-map-react/google-map-react/issues/854#issuecomment-899021073
export const ClusterMarkerWrapper = styled(Flex)`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: ${colors.white.standard};
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.24),
    0 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
  ${transitionEffect}

  & p {
    font-weight: 600;
    ${transitionEffect}
  }

  &:hover {
    background: ${colors.blue.standard};
    color: ${colors.white.standard};

    p {
      color: ${colors.white.standard};
    }
  }
`;

export const MarkerWrapper = styled(Flex)`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  width: 344px;
`;

export const PointWrapper = styled(Flex)`
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;
