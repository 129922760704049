import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ResetLinkStyle = styled(Link)`
  text-decoration: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
`;
