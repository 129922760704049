import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, CloseIcon } from '@beauty/beauty-market-ui';
import { ActionButton } from '../../../page/Booking/components/ActionButton';
import Popup from '../../../page/Booking/components/Popup';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentService, setService } from '../../../store/redux-slices/bookingSlice';

interface SelectedServicePopupProps {
  orgId: string;
}

const SelectedServicePopup = ({ orgId }: SelectedServicePopupProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const selectedService = useAppSelector(currentService);

  const handleBooking = useCallback(() => {
    if (!selectedService) return;

    const { id: serviceId } = selectedService;

    navigate(`${RouterUrl.Booking}/${orgId}/${serviceId}`);
  }, [dispatch, selectedService, orgId]);

  const handleClosePopup = useCallback(() => {
    dispatch(setService(null));
  }, [dispatch]);

  const controlButton = (
    <Button design="secondary" onClick={handleClosePopup}>
      <CloseIcon height="22" width="22" />
    </Button>
  );

  const actionButton = <ActionButton onClick={handleBooking}>{t('organisation.booking.bookAppointment')}</ActionButton>;

  return createPortal(
    <Popup actionButton={actionButton} controlButton={controlButton} />,
    document.getElementById('root') as HTMLElement,
  );
};

export default SelectedServicePopup;
