import { Nullable } from 'tsdef';
import { RouterUrl } from '../../routes/routes';

export interface Crumb {
  key: string;
  link: Nullable<RouterUrl>;
}

type BreadcrumbsDictionary = Partial<{
  [key in RouterUrl]: Crumb[];
}>;

// key use for translation => t(`breadcrumbs.${key}`, key)
const HOME = { key: 'home', link: RouterUrl.Homepage };
const ALL_OFFERS = { key: 'list', link: RouterUrl.AllOffers };
const PROFILE = { key: 'profile', link: RouterUrl.ClientProfile };
const APPOINTMENTS = { key: 'appointments', link: RouterUrl.ClientAppointments };
const FAVOURITES = { key: 'favourites', link: RouterUrl.ClientFavourites };
const DOCS = { key: 'client docs', link: RouterUrl.ClientDocs };
const FAQ = { key: 'faq', link: RouterUrl.FAQ };
const SUPPORT = { key: 'support', link: RouterUrl.Support };
const PRIVACY_POLICY = { key: 'privacy policy', link: RouterUrl.PrivacyPolicy };
const TERMS_OF_SERVICE = { key: 'terms of service', link: RouterUrl.TermsOfService };
const TERMS_OF_USE = { key: 'terms of use', link: RouterUrl.TermsOfUse };
const ABOUT_US = { key: 'aboutUs', link: RouterUrl.AboutUs };
const CONTACT_US = { key: 'contact us', link: RouterUrl.ContactUs };
const FOR_BUSINESS = { key: 'for business', link: RouterUrl.ForBusiness };
const SEARCH = { key: 'search', link: RouterUrl.Search };
const BUSINESS_JOIN = { key: 'business join', link: RouterUrl.BusinessJoin };
const JOIN_AS_BUSINESS = { key: 'join as business', link: RouterUrl.JoinAsBusiness };
const JOIN_AS_CLIENT = { key: 'join as client', link: RouterUrl.JoinAsClient };
const NOTIFICATIONS = { key: 'notifications', link: RouterUrl.Notifications };
const CHANGE_PLAN = { key: 'change plan', link: RouterUrl.ChangePlanPage };
const ACCOUNT_SETTINGS = { key: 'account settings', link: RouterUrl.AccountSetting };
const RETURN = { key: 'back', link: null };

const DICTIONARY_CRUMBS: BreadcrumbsDictionary = {
  [RouterUrl.Homepage]: [HOME],
  [RouterUrl.AllOffers]: [HOME, ALL_OFFERS],
  [RouterUrl.LoginIntro]: [HOME],
  [RouterUrl.LogOut]: [HOME],
  [RouterUrl.Login]: [HOME],
  [RouterUrl.LoginSMS]: [HOME],
  [RouterUrl.Welcome]: [HOME],
  [RouterUrl.Business]: [HOME],
  [RouterUrl.EveryOne]: [HOME],
  [RouterUrl.SignUp]: [HOME],
  [RouterUrl.EmailVerification]: [HOME],
  [RouterUrl.Registration]: [HOME],
  [RouterUrl.PhoneVerification]: [HOME],
  [RouterUrl.UpdatePassword]: [HOME],
  [RouterUrl.ResetPassword]: [HOME],
  [RouterUrl.SentEmailNotification]: [HOME],
  [RouterUrl.Organisation]: [HOME, ALL_OFFERS],
  [RouterUrl.NotPermission]: [HOME],
  [RouterUrl.Subscription]: [HOME],
  [RouterUrl.Booking]: [HOME],
  [RouterUrl.Client]: [RETURN],
  [RouterUrl.ClientProfile]: [RETURN],
  [RouterUrl.ClientAppointments]: [RETURN],
  [RouterUrl.ClientFavourites]: [RETURN],
  [RouterUrl.ClientDocs]: [HOME, DOCS],
  [RouterUrl.FAQ]: [HOME, FAQ],
  [RouterUrl.FAQQuestions]: [HOME, FAQ],
  [RouterUrl.Support]: [HOME, SUPPORT],
  [RouterUrl.PrivacyPolicy]: [HOME, PRIVACY_POLICY],
  [RouterUrl.TermsOfService]: [HOME, TERMS_OF_SERVICE],
  [RouterUrl.TermsOfUse]: [HOME, TERMS_OF_USE],
  [RouterUrl.AboutUs]: [HOME, ABOUT_US],
  [RouterUrl.ContactUs]: [HOME, CONTACT_US],
  [RouterUrl.ForBusiness]: [HOME, FOR_BUSINESS],
  [RouterUrl.Search]: [HOME, SEARCH],
  [RouterUrl.BusinessJoin]: [HOME, BUSINESS_JOIN],
  [RouterUrl.JoinAsBusiness]: [HOME, JOIN_AS_BUSINESS],
  [RouterUrl.JoinAsClient]: [HOME, JOIN_AS_CLIENT],
  [RouterUrl.Notifications]: [RETURN],
  [RouterUrl.ChangePlanPage]: [HOME, CHANGE_PLAN],
  [RouterUrl.AccountSetting]: [HOME, ACCOUNT_SETTINGS],
  [RouterUrl.BookingTimeslot]: [HOME, ALL_OFFERS],
};

export class BreadcrumbFactory {
  create(path: RouterUrl, additionalCrumbs: Crumb[] = []): Crumb[] {
    return [...(DICTIONARY_CRUMBS[path] || [HOME]), ...additionalCrumbs];
  }
}
