import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { PAYING_SECTIONS_BODY_PARTS_COUNT, PAYING_SECTIONS_COUNT } from '../constants';
import { Section } from './Section';

type Props = {
  id: string;
};

export const PayingForPartners = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.payingForPartners.title')}</PrivacyItemTitleWrapper>
      {Array(PAYING_SECTIONS_COUNT)
        .fill(0)
        .map((__, index) => (
          <Section
            key={index + 1}
            title={t(`termsOfService.payingForPartners.section${index + 1}.title`)}
            body={Array(PAYING_SECTIONS_BODY_PARTS_COUNT[index])
              .fill(0)
              .map((___, i) => t(`termsOfService.payingForPartners.section${index + 1}.body.part${i + 1}`))}
          />
        ))}
    </PrivacyItemWrapper>
  );
});

PayingForPartners.displayName = 'PayingForPartners';
