import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useUpdateQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return useCallback(
    (key: string, value?: string, doNavigate = false) => {
      const newParams = new URLSearchParams(searchParams);

      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }

      doNavigate
        ? navigate(`?${newParams.toString()}`, { replace: true })
        : setSearchParams(newParams, { replace: true });
    },
    [searchParams, setSearchParams, navigate],
  );
};
