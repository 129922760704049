import { useEffect } from 'react';
import { Map, MapsLibrary } from 'google-maps-react-markers';
import { useMediaScreen, useTheme } from '../../../hooks';
import { LatLngLiteral } from '../../../types';
import { getCloseBtn } from '../helper';

type MapCloseBtnType = {
  mapCenter: LatLngLiteral;
  onClick?: () => void;
  mapRef: React.RefObject<Map>;
  mapsControlsRef: React.RefObject<MapsLibrary>;
};

export const useMapCloseBtn = ({ mapCenter, mapRef, mapsControlsRef, onClick }: MapCloseBtnType) => {
  const theme = useTheme();
  const { isDesktop } = useMediaScreen('md');

  useEffect(() => {
    const map = mapRef.current;
    const mapsControls = mapsControlsRef.current;
    if (!map || !mapsControls || !onClick) return;

    const btnPosition = theme.rtl ? mapsControls.ControlPosition.LEFT_TOP : mapsControls.ControlPosition.RIGHT_TOP;
    map.controls[btnPosition].clear();

    const closeMapBtn = getCloseBtn({
      onClose: onClick,
      theme,
    });

    closeMapBtn && map.controls[btnPosition].push(closeMapBtn);
  }, [isDesktop, mapCenter, mapRef.current, mapsControlsRef.current, onClick, theme]);
};
