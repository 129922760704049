import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import { NotifyStatus, useNotify, EventStatus } from '@beauty/beauty-market-ui';
import { ID_FOR_ANY_SPECIALIST } from '../../../constants';
import { createAppointment, getAppointmentNotifyContent } from '../../../helpers';
import { useRequest } from '../../../hooks';
import { RouterUrl } from '../../../routes/routes';
import { removeAppointment } from '../../../store/asyncActions/visit';
import { useAppSelector } from '../../../store/hooks';
import {
  selectAppointmentsData,
  setAppointmentStatus,
  setLastAddedId,
} from '../../../store/redux-slices/appointmentsSlice';
import {
  currentOrganization,
  currentService,
  currentSpecialist,
  currentTimeslot,
  setOpenCheckoutSidebar,
} from '../../../store/redux-slices/bookingSlice';
import {
  selectAppointmentId,
  selectIsLogin,
  selectUser,
  selectUserId,
  updateSelectedAppointment,
} from '../../../store/redux-slices/userSlice';
import { CreateAppointmentParams, RemoveAppointmentPayload, VisitAction } from '../../../types';
import { AppointmentActions } from '../../../types/appointment';
import { PersonalDataFormTypes } from '../components/PersonalData/PersonalData.definitions';
import { getAppointmentParams, handleError } from '../helpers';

export const useAppointmentSubmit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotify();

  const [isLoading, setLoading] = useState(false);

  const appointments = useAppSelector(selectAppointmentsData);
  const appointmentId = useAppSelector(selectAppointmentId);
  const organisation = useAppSelector(currentOrganization);
  const specialist = useAppSelector(currentSpecialist);
  const timeslot = useAppSelector(currentTimeslot);
  const service = useAppSelector(currentService);
  const isLogin = useAppSelector(selectIsLogin);
  const userId = useAppSelector(selectUserId);
  const user = useAppSelector(selectUser);

  const deleteAppointment = useRequest<void, RemoveAppointmentPayload>(
    removeAppointment,
    VisitAction.RemoveAppointment,
  );

  const handleSuccess = (id: string, action: AppointmentActions) => {
    if (!organisation) return;
    notify(getAppointmentNotifyContent(NotifyStatus.SUCCESS, action, t));

    if (isLogin) {
      navigate(`${RouterUrl.ClientAppointments}/${userId}`);
      dispatch(setLastAddedId(id));
    } else if (organisation.id) {
      navigate(`${RouterUrl.Organisation}/${organisation.id}${location.search}`);
    }

    dispatch(updateSelectedAppointment(undefined));
    dispatch(setOpenCheckoutSidebar(false));
  };

  const onFormSubmit = useCallback(
    async (clientData: PersonalDataFormTypes) => {
      if (!service || !timeslot) return;

      setLoading(true);
      const selectedAppointment = appointments?.find(app => app.id === appointmentId);
      const createAction = isLogin ? AppointmentActions.Create : AppointmentActions.CreateByGuest;

      const actionType =
        selectedAppointment?.status && [EventStatus.CONFIRMED, EventStatus.PENDING].includes(selectedAppointment.status)
          ? AppointmentActions.Update
          : createAction;

      const handleCreateAppointment = async payload => {
        const { response, error } = await createAppointment(payload);
        response?.success
          ? handleSuccess(response.data.appointment[0].id, actionType)
          : handleError(actionType, notify, t, error);
        setLoading(false);
      };

      try {
        if (actionType === AppointmentActions.Update && appointmentId) {
          const rescheduleParams: CreateAppointmentParams = omitBy(
            {
              orgSpecId: specialist?.id === ID_FOR_ANY_SPECIALIST ? null : specialist?.id,
              orgServId: service?.id,
              start: timeslot?.utcTime,
              clientId: userId,
            },
            isNull,
          );

          await deleteAppointment({
            id: selectedAppointment?.clientVisitId as string,
            params: { appointmentId },
          });
          dispatch(setAppointmentStatus({ id: appointmentId as string, status: EventStatus.CANCELLED }));
          await handleCreateAppointment(rescheduleParams);
        } else {
          const createParams = getAppointmentParams({ service, timeslot, specialist }, userId, clientData, isLogin);
          handleCreateAppointment(createParams);
        }
      } catch (error) {
        handleError(actionType, notify, t);
      }
    },
    [user, isLogin, userId, notify, appointmentId, service, timeslot, specialist],
  );

  return { onFormSubmit, isLoading };
};
