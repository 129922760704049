import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import { apiRequest } from '../api/api';
import { NotificationEndpoints, UserEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';
import { ProfileForm } from '../page/Client/Profile/Profile.definitions';
import { RouterUrl } from '../routes/routes';
import { resetAppointments } from '../store/redux-slices/appointmentsSlice';
import { resetHistory } from '../store/redux-slices/historySlice';
import { removeOrganisation } from '../store/redux-slices/organisationSlice';
import { resetSearch } from '../store/redux-slices/searchSlice';
import { resetProfile, setNotifications, updateBooking, updateUser } from '../store/redux-slices/userSlice';
import {
  PatchEmailType,
  PatchPasswordType,
  PatchPhoneType,
  PatchProfileType,
  PostPhoneType,
  ProfileType,
} from '../types';

export const setNewProfile = (newData: ProfileForm, profile: ProfileType): PatchProfileType => {
  const { name, surname, dateOfBirth, gender, city, country, fullAddress, idNumber } = newData;

  const isAccount = name || surname || dateOfBirth || gender;

  const newProfile = () => {
    const result = {} as PatchProfileType;
    if (isAccount) {
      result.accountData = omitBy(
        {
          name,
          surname,
          dateOfBirth: moment.utc(dateOfBirth, 'DDMMYYYY').toDate(),
          gender: gender || null,
        },
        isNil,
      );
      result.accountData.idNumber = idNumber || null;
    }
    result.addressData = [
      {
        id: profile.addressId,
        country: country || null,
        city: city || null,
        fullAddress: fullAddress || null,
      },
    ];

    return result;
  };

  return newProfile();
};

export const uploadAvatar = async (profileId: string, params: FormData) => {
  const [, response] = await apiRequest.put({
    endpoint: UserEndpoints.AvatarUpload,
    endpointParams: profileId,
    data: params,
  });

  return response?.data;
};

export const deleteAvatar = async (profileId: string) => {
  const [, response] = await apiRequest.delete({
    endpoint: UserEndpoints.AvatarUpload,
    endpointParams: profileId,
  });

  return response?.data;
};

const updateNotification = async ({ query, data, rejectWithValue }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.NotificationUpdate,
    endpointParams: query,
    data,
  });

  return response ? response.data.data : rejectWithValue(error);
};

export const getProfileFromApi = (apiProfile): ProfileType => ({
  name: apiProfile.name,
  surname: apiProfile.surname,
  dateOfBirth: apiProfile.dateOfBirth,
  gender: apiProfile.gender,
  code: apiProfile.code,
  number: apiProfile.number,
  email: apiProfile.email,
  addressId: apiProfile.address?.address[0]?.id,
  country: apiProfile.address?.address[0]?.country ?? '',
  city: apiProfile.address?.address[0]?.city ?? '',
  fullAddress: apiProfile.address?.address[0]?.fullAddress ?? '',
  avatarUrl: apiProfile.avatarUrl,
  currency: apiProfile.currency,
  accountNotification: apiProfile.accountNotification.map(item => item.notification.type),
  telegram: apiProfile.telegram,
  idNumber: apiProfile.idNumber,
  language: apiProfile.language,
});

export const getProfile = async (userId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: UserEndpoints.Root,
    endpointParams: {
      id: userId,
      queryParams: 'include=contact,address,accountNotification',
    },
  });
  return response?.status === 200 ? getProfileFromApi(response.data) : undefined;
};

export const updateAccount = async (profileId: string, params: PatchProfileType) => {
  const [, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Root,
    endpointParams: { id: profileId },
    data: { ...params },
  });
  return response?.status;
};

export const deleteProfile = async (profileId: string, reason: string, info: string) => {
  const [, response] = await apiRequest.delete({
    endpoint: UserEndpoints.Root,
    endpointParams: { id: profileId, queryParams: `reason=${reason}&info=${info}` },
  });

  return response;
};

export const cleanUpAfterLogout = (dispatch, navigate) => {
  dispatch(
    updateUser({
      isLogin: false,
      user: { name: '', surname: '', email: '', code: '', number: '', userId: '', avatarUrl: '', idNumber: null },
    }),
  );
  dispatch(updateBooking({ service: null, timeslot: null, specialist: null }));
  dispatch(resetAppointments());
  dispatch(setNotifications({ new: [], archive: [] }));
  dispatch(removeOrganisation());
  dispatch(resetSearch());
  dispatch(resetProfile());
  dispatch(resetHistory());
  navigate(RouterUrl.Homepage);
};

export const updateEmail = async (params: PatchEmailType) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Email,
    data: { ...params, accType: AccType.USER },
  });
  return response ? response.data : error?.data;
};

export const updatePhoneSendSms = async (params: PatchPhoneType) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Phone,
    data: { ...params, accType: AccType.USER },
  });
  return response ? response.data : error?.data;
};

export const updatePhone = async (params: PostPhoneType) => {
  const [error, response] = await apiRequest.post({
    endpoint: UserEndpoints.Phone,
    data: { ...params, accType: AccType.USER },
  });
  return response || error;
};

const disconnectTelegram = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.delete({
    endpoint: NotificationEndpoints.Telegram,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error);
};

export const changePassword = async ({ id, data }: PatchPasswordType) => {
  const [error, response] = await apiRequest.patch({
    endpoint: UserEndpoints.Password,
    endpointParams: id,
    data,
  });
  return { response: response?.data, error: error?.status };
};

const fetchFavourites = async ({ id, rejectWithValue }) => {
  const [error, response] = await apiRequest.get({
    endpoint: UserEndpoints.Root,
    endpointParams: { id, queryParams: `include=favouriteOrganization` },
  });
  const data = response?.data.favouriteOrganization;
  return response ? data : rejectWithValue(error);
};

const addToFavourites = async ({ id, orgId, rejectWithValue }) => {
  const [error, response] = await apiRequest.post({
    endpoint: UserEndpoints.Favourites,
    endpointParams: { id },
    data: { organizationId: orgId },
  });
  return response ? response.data.data : rejectWithValue(error);
};

const removeFromFavourites = async ({ id, orgId, rejectWithValue }) => {
  const [error, response] = await apiRequest.delete({
    endpoint: UserEndpoints.Favourites,
    endpointParams: { id, queryParams: `organizationId=${orgId}` },
  });
  return response ? response.data : rejectWithValue(error);
};

export const profileAPI = {
  updateNotification,
  disconnectTelegram,
  fetchFavourites,
  addToFavourites,
  removeFromFavourites,
};
