import { H4, Flex, Avatar, BodySmall, FavouriteButton } from '@beauty/beauty-market-ui';
import { SpecialistButtonsWrapper, SpecInfo, StyledShareButton } from '../style';

type SpecCardProps = {
  name: string;
  info?: string;
  imgUrl?: string;
  onShare?: () => void;
  onLike?: () => void;
};

export const SpecCard = ({ imgUrl, name, info, onShare, onLike }: SpecCardProps) => (
  <Flex height="80px" mb="24px" justifyContent="space-between">
    <Flex>
      <Avatar size="m" url={imgUrl} />
      <SpecInfo>
        <H4 mb="5px">{name}</H4>
        {info && <BodySmall lowline>{info}</BodySmall>}
      </SpecInfo>
    </Flex>

    <SpecialistButtonsWrapper>
      {/* {onLike && <FavouriteButton onClick={onLike} />} */}
      {onShare && <StyledShareButton onClick={onShare} />}
    </SpecialistButtonsWrapper>
  </Flex>
);
