import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { BodyLarge, Flex } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../../../../components';
import { ProfileForm, ProfileFormFields } from '../../../Profile.definitions';

const OtherInformationForm = () => {
  const { t } = useTranslation();
  const { setFieldTouched } = useFormikContext<ProfileForm>();

  useEffect(() => {
    setFieldTouched(ProfileFormFields.IdNumber, true);
  }, []);

  return (
    <>
      <BodyLarge large mb="16px">
        {t('profile.otherInformation')}
      </BodyLarge>

      <Flex flexDirection="column" gap="16px">
        <FormikInput
          design="white"
          id={ProfileFormFields.IdNumber}
          name={ProfileFormFields.IdNumber}
          placeholder={t('profile.idNumber')}
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.Country}
          name={ProfileFormFields.Country}
          placeholder={t('profile.country')}
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.City}
          name={ProfileFormFields.City}
          placeholder={t('profile.city')}
        />
        <FormikInput
          design="white"
          id={ProfileFormFields.FullAddress}
          name={ProfileFormFields.FullAddress}
          placeholder={t('profile.address')}
        />
      </Flex>
    </>
  );
};

export { OtherInformationForm };
