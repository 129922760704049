import { TFunction } from 'react-i18next';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import { Nullable } from 'tsdef';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { ID_FOR_ANY_SPECIALIST } from '../../constants';
import { getAppointmentNotifyContent } from '../../helpers';
import { BookingType, CreateAppointmentParamsType, ErrorData } from '../../types';
import { AppointmentActions } from '../../types/appointment';
import { DiscountType, DiscountValueType } from '../../types/discount';
import { SpecialistType } from '../../types/specialist';

export enum PayBy {
  Cash = 'CASH',
  Card = 'CARD',
}

export const getAppointmentParams = (
  booking: BookingType,
  clientId: string,
  clientData: CreateAppointmentParamsType['clientData'],
  isLogin: boolean,
): CreateAppointmentParamsType => {
  const clientPayload = clientData?.idNumber
    ? clientData
    : (omit(clientData, 'idNumber') as CreateAppointmentParamsType['clientData']);
  const params: CreateAppointmentParamsType = {
    orgSpecId: booking.specialist?.id === ID_FOR_ANY_SPECIALIST ? null : booking.specialist?.id || null,
    orgServId: booking.service?.id,
    start: booking.timeslot?.utcTime,
    clientId: isLogin ? clientId : undefined,
    clientData: isLogin ? undefined : clientPayload,
  };

  return omitBy(params, isNull) as CreateAppointmentParamsType;
};

export const handleError = (
  action: AppointmentActions,
  notify: (props: NotifyPropsType) => void,
  t: TFunction<'translation', undefined>,
  error?: ErrorData,
) => {
  switch (error?.message) {
    case "Start can't be in the past":
    case 'Available specialist was not found':
    case 'This organisation does not support online payment': {
      const content = getAppointmentNotifyContent(NotifyStatus.ERROR, action, t);
      notify(error?.message ? { ...content, subtitle: t(`validation.${error.message}`) } : content);
      break;
    }
    default: {
      notify(getAppointmentNotifyContent(NotifyStatus.ERROR, action, t));
      break;
    }
  }
};

export const getAnySpecialistState = (
  name: string,
  isSelectedSpecialist: boolean,
  avatarUrl?: string,
  discount?: DiscountType[],
) =>
  ({
    id: ID_FOR_ANY_SPECIALIST,
    name,
    specialization: '',
    selected: isSelectedSpecialist,
    discount,
    rating: null,
    avatarUrl,
    published: true,
  }) as SpecialistType;

export const getMaxPercentDiscount = (discounts: DiscountType[] = []) => {
  if (!discounts || discounts.length === 0) return 0;

  return discounts.reduce((prev, cur) => {
    if (cur.valueType === DiscountValueType.Percent && cur.percent > prev) {
      return cur.percent;
    }

    return prev;
  }, 0);
};

export const getAllDiscount = (specialists: SpecialistType[]) => {
  if (!specialists || specialists.length === 0) return [];

  return specialists.reduce((prev, cur) => {
    if (cur.discount && cur.discount.length > 0) {
      return [...prev, ...cur.discount];
    }

    return prev;
  }, [] as DiscountType[]);
};

export const getMaxPercentDiscountInfo = (specialists: SpecialistType[]): Nullable<DiscountType> => {
  if (!specialists || specialists.length === 0) return null;

  const allDiscount = getAllDiscount(specialists);

  const maxDiscount = allDiscount.reduce((prev, cur) => {
    if (cur.valueType === DiscountValueType.Percent && cur.isActive && cur.percent > (prev.percent || 0)) {
      return cur;
    }

    return prev;
  }, {} as DiscountType);

  return maxDiscount.id ? maxDiscount : null;
};
