import { BodyLarge } from '@beauty/beauty-market-ui';
import { ClusterMarkerWrapper } from './style';

interface ClusterMarkerProps {
  markersNumber: number;
  zIndex?: number;
  onClick?: () => void;
  lng?: number;
  lat?: number;
}

const ClusterMarker = ({ markersNumber, onClick, zIndex, ...props }: ClusterMarkerProps) => (
  <ClusterMarkerWrapper onClick={onClick} zIndex={zIndex} {...props}>
    <BodyLarge lowline>{markersNumber}</BodyLarge>
  </ClusterMarkerWrapper>
);

export default ClusterMarker;
