import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { H4, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper } from '../../../style';
import { transComponents } from '../constants';

type Props = {
  id: string;
};

export const Introduction = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <H4 mb="40px">{t('termsOfService.fullVersion.title')}</H4>
      <H4 mb="24px">{t('termsOfService.introduction.title')}</H4>
      <BodySmall mb="16px">
        <Trans components={transComponents}>{t('termsOfService.introduction.article1')}</Trans>
      </BodySmall>
      <BodyLarge large mb="16px">
        {t('termsOfService.introduction.article2')}
      </BodyLarge>
      <BodySmall mb="16px">{t('termsOfService.introduction.article3')}</BodySmall>
      <BodySmall mb="24px">
        <Trans components={transComponents}>{t('termsOfService.introduction.article4')}</Trans>
      </BodySmall>
    </PrivacyItemWrapper>
  );
});

Introduction.displayName = 'Introduction';
