import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { H3 } from '@beauty/beauty-market-ui';
import { Services } from '../../../components';
import { useGetOrganisation } from '../../../hooks/useGetOrganisation';
import { useAppDispatch } from '../../../store/hooks';
import { resetBooking } from '../../../store/redux-slices/bookingSlice';
import { CategoryType } from '../../../types';
import { BookingParams } from '../../../types/booking';
import { Wrapper } from './style';

const BookingService = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { orgId } = useParams() as BookingParams;

  const { organisation } = useGetOrganisation(orgId, true);

  useEffect(() => {
    dispatch(resetBooking());
  }, []);

  if (!organisation?.orgName || organisation.offers?.categories?.length === 0) return null;

  const servicesProps = {
    offersCategories: (organisation.offers.categories || []) as CategoryType[],
    organisation,
  };

  return (
    <Wrapper>
      <H3>{t('organisation.offerings')}</H3>
      <Services {...servicesProps} />
    </Wrapper>
  );
};

export default BookingService;
