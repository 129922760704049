import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum ReviewAppointmentFormFields {
  Ratings = 'ratings',
  Description = 'description',
}

export type ReviewAppointmentFormTypes = {
  [ReviewAppointmentFormFields.Ratings]: any[];
  [ReviewAppointmentFormFields.Description]: string;
};

export enum SendEmailFormFields {
  Email = 'email',
}

export type SendEmailFormTypes = {
  [SendEmailFormFields.Email]: string;
};

export const initialSendEmailValues: SendEmailFormTypes = {
  [SendEmailFormFields.Email]: '',
};

export const sendEmailValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    email: Yup.string().required(t('validation.notEmpty')).email(t('validation.emailNotValid')),
  });
