import { stringForSendSupportEmail, stringToOpenSite } from '../../constants';
import { setLink } from '../../helpers';
import { RouterUrl } from '../../routes/routes';
import { StyledLi, StyledUl } from '../../style';

export const SUMMARY_ARTICLES_COUNT = 4;

export const KEY_TERMS_SECTIONS_COUNT = 6;
export const KEY_TERMS_SECTION_6_SUBSECTIONS_COUNT = 16;

export const USING_PLATFORM_SECTIONS_COUNT = 4;
export const USING_PLATFORM_SECTION_2_SUBSECTIONS_COUNT = 4;
export const USING_PLATFORM_SECTIONS_BODY_PARTS_COUNT = [1, 1, 2, 3];

export const HOW_TO_WORK_ARTICLES_COUNT = 3;
export const HOW_TO_WORK_SECTIONS_COUNT = 2;
export const HOW_TO_WORK_SECTIONS_BODY_PARTS_COUNT = [1, 2];

export const PAYING_SECTIONS_COUNT = 4;
export const PAYING_SECTIONS_BODY_PARTS_COUNT = [1, 3, 1, 3];

export const CHANGES_SECTIONS_COUNT = 9;
export const CHANGES_SECTIONS_BODY_PARTS_COUNT = [1, 0, 0, 0, 1, 1, 5, 4, 2];
export const CHANGES_SUBSECTIONS_COUNT = [0, 2, 1, 0, 0, 0];
export const CHANGES_ARTICLES_COUNT = [0, 1, 0, 3, 0, 0];
export const CHANGES_SUBSECTIONS_PARTS_COUNT = [0, [1, 3], [2], 0, 0, 0];

export const TERMINATION_ARTICLES_COUNT = 2;

export const GENERAL_SECTIONS_COUNT = 9;
export const GENERAL_SECTIONS_BODY_PARTS_COUNT = [1, 1, 1, 1, 1, 1, 1, 4, 3];

export const transComponents = {
  ul: <StyledUl />,
  li: <StyledLi />,
  aTerms: setLink(RouterUrl.TermsOfService),
  aSite: setLink(stringToOpenSite),
  aWidget: setLink(''),
  aApp: <div />,
  aEmail: setLink(stringForSendSupportEmail),
  aPrivacy: setLink(RouterUrl.PrivacyPolicy),
  aProfile: setLink(`${RouterUrl.ClientProfile}/:id`),
  aPaymentPrivacy: setLink('https://www.allpay.co.il/privacy', 's', true),
  aPaymentTerms: setLink('https://www.allpay.co.il/terms', 's', true),
};
