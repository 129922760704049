import { useTranslation } from 'react-i18next';
import { BodySmall, Flex, H5, MastercardColorIcon, PaymentCard, RadioButton } from '@beauty/beauty-market-ui';
import { PayBy } from '../helpers';
import { StyledLabel } from '../style';

const mockCardNumber = '4879621105870179';

type CardType = {
  type?: string;
  number?: string;
  holderName?: string;
};

const PaymentMethods = ({ method, setMethod }) => {
  const { t } = useTranslation();

  const mapUserCards: (card: CardType) => JSX.Element = card => (
    <PaymentCard
      key={card.number}
      title={card.type}
      cardNumber={card.number}
      cardIcon={<MastercardColorIcon />}
      cardHandler={() => setMethod(mockCardNumber)}
      padding="0 16px"
    />
  );

  const handleByCash = () => setMethod(PayBy.Cash);
  const handleByCard = () => setMethod(PayBy.Card);

  return (
    <>
      <H5 mt="40px" mb="16px">
        {t('organisation.booking.paymentMethods')}
      </H5>
      <BodySmall mb="44px">{t('organisation.booking.servicePaymentProcessed')}</BodySmall>
      <Flex px="16px" justifyContent="space-between">
        <Flex>
          <RadioButton checked={method === PayBy.Cash} onChange={handleByCash} />
          <StyledLabel onClick={handleByCash}>{t('organisation.booking.byCash')}</StyledLabel>
        </Flex>
        <Flex>
          <RadioButton checked={method === PayBy.Card} onChange={handleByCard} />
          <StyledLabel onClick={handleByCard}>{t('organisation.booking.byCard')}</StyledLabel>
        </Flex>
      </Flex>
      {/* <CardsWrapper>
        <Div>
          <Label mb="10px" padding="0">
            {t('organisation.booking.newCard')}
          </Label>
          <Link size="md" design="blue" href="#">
            {t('organisation.booking.add')}
          </Link>
        </Div>
        {userCards.map(mapUserCards)}
      </CardsWrapper> */}
    </>
  );
};

export default PaymentMethods;
