import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import {
  HOW_TO_WORK_ARTICLES_COUNT,
  HOW_TO_WORK_SECTIONS_BODY_PARTS_COUNT,
  HOW_TO_WORK_SECTIONS_COUNT,
} from '../constants';
import { Section } from './Section';

type Props = {
  id: string;
};

export const HowToWork = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.howToWork.title')}</PrivacyItemTitleWrapper>
      {Array(HOW_TO_WORK_ARTICLES_COUNT)
        .fill(0)
        .map((__, i) => (
          <BodySmall
            // eslint-disable-next-line react/no-array-index-key
            key={i + 1}
            mb={i === HOW_TO_WORK_ARTICLES_COUNT - 1 ? '24px' : '16px'}
          >
            {t(`termsOfService.howToWork.article${i + 1}`)}
          </BodySmall>
        ))}

      {Array(HOW_TO_WORK_SECTIONS_COUNT)
        .fill(0)
        .map((__, index) => (
          <Section
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
            title={t(`termsOfService.howToWork.section${index + 1}.title`)}
            body={Array(HOW_TO_WORK_SECTIONS_BODY_PARTS_COUNT[index])
              .fill(0)
              .map((___, i) => t(`termsOfService.howToWork.section${index + 1}.body.part${i + 1}`))}
          />
        ))}
    </PrivacyItemWrapper>
  );
});

HowToWork.displayName = 'HowToWork';
