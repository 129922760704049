import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import uniq from 'lodash/uniq';
import { Nullable } from 'tsdef';
import { Container, Crumbs } from '../../components';
import { clearBooking, getSelectedLanguage, SpecialistsRoles } from '../../constants';
import { getTranslation } from '../../helpers';
import { useGetOrganisationOfHead, useMediaScreen, useRequest } from '../../hooks';
import { useGetOrganisation } from '../../hooks/useGetOrganisation';
import { useGetOrganisationRating } from '../../hooks/useGetOrganisationRating';
import useSetCrumbsQuery from '../../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setOrganisation } from '../../store/redux-slices/historySlice';
import { selectOrganisation, setMap, setShowSearch } from '../../store/redux-slices/organisationSlice';
import {
  selectFavouritesIds,
  selectUser,
  setFavouriteId,
  ThunkUser,
  updateBooking,
} from '../../store/redux-slices/userSlice';
import { TeamMateType, UserActions } from '../../types';
import { About, Banner, Contact, HotOffers, Rating, Reviews } from './components';
import { FilterBottomSheet } from './components/FilterBottomSheet';
import Map from './components/Map';
import { bannerProps } from './constants';
import { AdditionalContentWrapper, ContactAndAboutWrapper, RatingAndRewiewsWrapper, StyledContainer } from './style';

type OrganisationParams = {
  orgId: string;
};

const Organisation = () => {
  const [showSecondBtn, setShowSecondBtn] = useState(false);

  const { isDesktop } = useMediaScreen('md');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = getSelectedLanguage();

  const [selectedRating, setSelectedRating] = useState<Nullable<number>>(null);
  const [selectedDateSorting, setSelectedDateSorting] = useState<Nullable<number>>(0);
  const [selectedSpecialist, setSelectedSpecialist] = useState<Nullable<string>>(null);

  const { orgId } = useParams() as OrganisationParams;
  const { isLogin, isForceFetch } = useAppSelector(selectUser);

  const { isLoading, organisation } = useGetOrganisation(orgId);
  const { organisations } = useGetOrganisationOfHead(organisation?.headOrgId);
  const { rating } = useGetOrganisationRating(orgId, isForceFetch);

  const [isFilterOpen, setFilterOpen] = useState(false);

  const { organisation: organisationFromStore, isMap } = useAppSelector(selectOrganisation);
  const favouritesIds = useAppSelector(selectFavouritesIds);
  const isFavourite = !!favouritesIds?.includes(orgId);

  const specialists = organisation?.team.filter(member => member.role === SpecialistsRoles.SPECIALIST);
  const address = organisationFromStore?.address?.address[0];
  const crumbsQuery = useSetCrumbsQuery(organisation?.name || '');

  const filteredOrganisations = useMemo(() => organisations?.filter(org => org.id !== orgId), [organisations, orgId]);

  const addToFavourites = useRequest(ThunkUser.addToFavourites, UserActions.AddToFavourites, true);
  const removeFromFavourites = useRequest(ThunkUser.removeFromFavourites, UserActions.RemoveFromFavourites, true);

  const handleChangeView = () => {
    dispatch(setMap(!isMap));
  };

  const handleSignInClick = () => {
    navigate(RouterUrl.Login, {
      state: { redirectedFrom: RouterUrl.Organisation, searchParams: window.location.search, orgId },
    });
  };

  const onLike = useCallback(() => {
    if (isLogin) {
      if (isFavourite) {
        dispatch(setFavouriteId(orgId));
        removeFromFavourites({ orgId });
      } else {
        addToFavourites({ orgId });
      }
    } else handleSignInClick();
  }, [isLogin, isFavourite, orgId]);

  const onShare = useCallback(() => {
    navigate(`${RouterUrl.Booking}/${orgId}`);
  }, [navigate, orgId + crumbsQuery]);

  useEffect(() => {
    organisation?.name &&
      dispatch(setOrganisation({ name: organisation.name, path: `${RouterUrl.Organisation}/${orgId}` }));
  }, [orgId, organisation?.name]);

  useEffect(() => {
    const handleShowBtn = () => {
      const scrolled = window.scrollY;

      if (scrolled > 620) {
        setShowSecondBtn(true);
      } else if (scrolled <= 620) {
        setShowSecondBtn(false);
      }
    };

    window.addEventListener('scroll', handleShowBtn);

    return () => {
      window.removeEventListener('scroll', handleShowBtn);
      dispatch(setMap(false));
    };
  }, [showSecondBtn]);

  useEffect(() => {
    dispatch(setShowSearch(!isMap));
  }, [isMap]);

  useEffect(() => {
    dispatch(updateBooking(clearBooking));
  }, []);

  return isLoading ? null : (
    <>
      {!isMap && <Crumbs path={RouterUrl.Organisation} currentPath={organisation?.name} />}
      {!isMap && organisation && (
        <>
          <StyledContainer wide flexDirection="column" width="100%">
            <Banner
              {...bannerProps(organisation)}
              headline={uniq(
                organisationFromStore?.category.map(cat => getTranslation(cat.parent?.title, language)),
              ).join(', ')}
              title={organisation.name}
              organisation={organisation}
              rating={rating?.rating ?? null}
              reviewsNum={rating?.reviews}
              onLike={onLike}
              onShare={onShare}
              isActive={isFavourite}
            />
          </StyledContainer>
          <Container flexDirection="column">
            <ContactAndAboutWrapper>
              <Contact
                organisation={organisation}
                showBtn={showSecondBtn && Boolean(isDesktop)}
                coordinates={address ? { lat: address.lat, lng: address.lng } : null}
                onMapClick={handleChangeView}
              />
              <About organisation={organisation} />
            </ContactAndAboutWrapper>
            <AdditionalContentWrapper>
              {rating?.rating && (
                <RatingAndRewiewsWrapper>
                  {rating && <Rating rating={rating} />}
                  <Reviews
                    selectedRating={selectedRating}
                    setSelectedRating={setSelectedRating}
                    selectedDateSorting={selectedDateSorting}
                    setSelectedDateSorting={setSelectedDateSorting}
                    selectedSpecialist={selectedSpecialist}
                    setSelectedSpecialist={setSelectedSpecialist}
                    orgId={orgId}
                    setFilterOpen={setFilterOpen}
                    team={organisation.team}
                  />
                </RatingAndRewiewsWrapper>
              )}
            </AdditionalContentWrapper>

            {!isEmpty(filteredOrganisations) && <HotOffers offers={filteredOrganisations} />}
          </Container>
        </>
      )}
      {isMap && organisationFromStore && (
        <Map onChangeView={handleChangeView} mapZoom={16} organization={organisationFromStore} />
      )}
      <FilterBottomSheet
        isOpen={isFilterOpen}
        isReset={!selectedRating && !(selectedDateSorting === 0) && !selectedSpecialist}
        setOpen={setFilterOpen}
        selectedRating={selectedRating}
        setSelectedRating={setSelectedRating}
        selectedDateSorting={selectedDateSorting}
        selectedSpecialist={selectedSpecialist}
        setSelectedDateSorting={setSelectedDateSorting}
        setSelectedSpecialist={setSelectedSpecialist}
        specialists={specialists as TeamMateType[]}
      />
    </>
  );
};

export default Organisation;
