import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapCard, useClickOutside } from '@beauty/beauty-market-ui';
import { useLocation } from '../../../hooks/useLocation';
import { RouterUrl } from '../../../routes/routes';
import { MarkerType } from '../../../types';
import { CustomLink } from '../../CustomLink/CustomLink';
import { calculateDistance, getMarkerLatLngLiteral } from '../helper';
import { CardWrapper } from '../style';

interface Props extends React.PropsWithChildren {
  marker: MarkerType;
  mapContainerRef: HTMLElement | null;
  onClickOutside?: () => void;
}

export const MapCardWrapper = ({ marker, mapContainerRef, onClickOutside }: Props) => {
  const { t } = useTranslation();

  const cardRef = useRef<HTMLElement | null>(null);

  const { userLocation } = useLocation();

  const [isDown, setIsDown] = useState(false);

  const geometry = google?.maps?.geometry?.spherical;

  useClickOutside(cardRef, () => onClickOutside && onClickOutside());

  useLayoutEffect(() => {
    const topOffsetCard = cardRef.current?.getBoundingClientRect().y || 0;
    const topOffsetMap = mapContainerRef?.getBoundingClientRect().y || 0;

    setIsDown(topOffsetCard < topOffsetMap);
  }, [cardRef, mapContainerRef]);

  if (!marker || !geometry) return null;

  const distanceInKm = calculateDistance(geometry, userLocation, getMarkerLatLngLiteral(marker));

  const distance = `${distanceInKm} ${t('home.map.km')} ${t('home.map.awayFromYou')}`;

  return (
    <CardWrapper ref={cardRef} isDown={isDown}>
      <CustomLink to={`${RouterUrl.Organisation}/${marker.id}`} target="_blank">
        <MapCard
          title={marker.name}
          distanceText={distance}
          reviewsText={t('organisation.reviews', { count: Number(marker.review) })}
          image={marker.image}
        />
      </CustomLink>
    </CardWrapper>
  );
};
