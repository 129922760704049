import { useEffect } from 'react';
import { Map, MapsLibrary } from 'google-maps-react-markers';
import { useMediaScreen, useTheme } from '../../../hooks';
import { useLocation } from '../../../hooks/useLocation';
import { LatLngLiteral } from '../../../types';
import { getControlButtons } from '../helper';

type MapControlButtonsType = {
  mapCenter: LatLngLiteral;
  mapZoom: number;
  mapRef: React.RefObject<Map>;
  mapsControlsRef: React.RefObject<MapsLibrary>;
  isShowControls: boolean;
};
export const useMapControlButtons = ({
  mapCenter,
  mapZoom,
  mapRef,
  mapsControlsRef,
  isShowControls,
}: MapControlButtonsType) => {
  const theme = useTheme();
  const { rtl } = theme;

  const { isDesktop } = useMediaScreen('md');

  const { userLocation } = useLocation();

  useEffect(() => {
    const map = mapRef.current;
    const mapsControls = mapsControlsRef.current;
    if (!map || !mapsControls) return;

    const controlPosition = rtl ? mapsControls.ControlPosition.LEFT_BOTTOM : mapsControls.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPosition].clear();

    if (!isShowControls) return;

    const controlsMapBtns = getControlButtons(map, userLocation, mapZoom, theme, isDesktop);
    map.controls[controlPosition].push(controlsMapBtns);
  }, [
    mapCenter,
    mapZoom,
    rtl,
    theme,
    isDesktop,
    mapRef.current,
    mapsControlsRef.current,
    isShowControls,
    userLocation,
  ]);
};
