import React from 'react';
import { useTranslation } from 'react-i18next';
import { Div } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../../components';
import { getSelectedLanguage, OurChoiceOrganisations } from '../../../../constants';
import { getTranslatedString, getTranslation } from '../../../../helpers';
import { RouterUrl } from '../../../../routes/routes';
import { useAppSelector } from '../../../../store/hooks';
import { selectFavouritesIds } from '../../../../store/redux-slices/userSlice';
import { FoundResponse, MarkerType } from '../../../../types';

interface Props extends React.PropsWithChildren {
  onHover?: (organisation: MarkerType) => void;
  onLeave?: () => void;
  organisation: MarkerType & FoundResponse;
  isActive?: boolean;
}

const OrganisationCardWrapper: React.FC<Props> = ({ organisation, isActive, onHover, onLeave }) => {
  const { t } = useTranslation();

  const favouritesIds = useAppSelector(selectFavouritesIds);

  const language = getSelectedLanguage();

  return (
    <Div
      onMouseEnter={() =>
        onHover &&
        onHover({
          ...organisation,
          image: organisation.mainPhoto || '',
          headline: organisation.category.map(cat => getTranslation(cat, language)).join(', '),
          title: organisation.name,
        })
      }
      onMouseLeave={onLeave}
    >
      <OrganisationCard
        id={organisation.id}
        key={organisation.id}
        rating={organisation.rating}
        image={organisation.mainPhoto}
        headline={getTranslatedString(language, organisation.category)}
        title={organisation.name}
        photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
        reviews={t('organisation.reviews', { count: organisation.review })}
        badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
        href={`${RouterUrl.Organisation}/${organisation.id}`}
        isBlueHover
        favourite={favouritesIds?.includes(organisation.id)}
        isActive={isActive}
        isBlank
      />
    </Div>
  );
};

export default React.memo(OrganisationCardWrapper);
