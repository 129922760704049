import { apiRequest } from '../api/api';
import { DefaultEndpoints } from '../api/endpoints/endpoints';
import { ErrorData } from '../types';

export const getReceiptById = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: DefaultEndpoints.Receipt,
    endpointParams: id,
    config: {
      responseType: 'blob',
    },
  });
  return { error: error?.data as ErrorData, response };
};

export const generalAPI = {
  getReceiptById,
};
