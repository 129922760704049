import React, { ReactNode, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import GoogleMap, { LatLngBounds, Map, MapsLibrary, onGoogleApiLoadedProps } from 'google-maps-react-markers';
import { telAvivGeolocation } from '../../constants';
import { useLocation } from '../../hooks/useLocation';
import { LatLngLiteral, MapOptions, CustomGoogleMapRef } from '../../types';
import { useMapCloseBtn } from './hooks/useMapCloseBtn';
import { useMapControlButtons } from './hooks/useMapControlButtons';
import MapPointLocation from './MapPointLocation';
import { GoogleMapWrapper } from './style';

type OnChangeMap = ({
  bounds,
  center,
  zoom,
}: {
  bounds: LatLngBounds;
  center: (number | undefined)[];
  zoom: number;
}) => void;

interface Props extends React.PropsWithChildren {
  className?: string;
  markers?: ReactNode;
  centerOfMap: LatLngLiteral;
  mapZoom?: number;
  minHeightMap?: string;
  onChangeMap?: OnChangeMap;
  onLoad?: (props: onGoogleApiLoadedProps) => void;
  isShowControls?: boolean;
  onClickCloseMap?: () => void;
}

const options: MapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  mapId: '4a89a47c8aa391b1',
  clickableIcons: false,
  gestureHandling: 'greedy',
};

const CustomGoogleMap = forwardRef<CustomGoogleMapRef, Props>(
  (
    {
      className,
      children,
      markers,
      centerOfMap,
      mapZoom = 12,
      minHeightMap,
      onChangeMap,
      onLoad,
      isShowControls = true,
      onClickCloseMap,
    },
    ref,
  ) => {
    const { userLocation } = useLocation();

    const mapRef = useRef<Map | null>(null);
    const mapsControlsRef = useRef<MapsLibrary | null>(null);
    const [mapInstance, setMapInstance] = useState<Map | null>(null);

    useImperativeHandle(ref, () => ({
      mapRef: mapRef.current,
      mapsControlsRef: mapsControlsRef.current,
    }));

    const mapCenter: LatLngLiteral = centerOfMap || userLocation || telAvivGeolocation;

    useMapControlButtons({
      mapCenter,
      mapZoom,
      mapRef,
      mapsControlsRef,
      isShowControls,
    });

    useMapCloseBtn({ mapCenter, mapRef, mapsControlsRef, onClick: onClickCloseMap });

    const onLoadMap = (props: onGoogleApiLoadedProps) => {
      const { map, maps } = props;
      mapRef.current = map;
      mapsControlsRef.current = maps;

      setMapInstance(map);

      onLoad && onLoad(props);
    };

    return (
      <GoogleMapWrapper className={className}>
        <GoogleMap
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          defaultCenter={mapCenter}
          defaultZoom={mapZoom}
          options={options}
          mapMinHeight={minHeightMap || '0vh'}
          onChange={onChangeMap}
          onGoogleApiLoaded={onLoadMap}
        >
          {markers}
          <MapPointLocation lat={userLocation.lat} lng={userLocation.lng} />
        </GoogleMap>
        {children}
      </GoogleMapWrapper>
    );
  },
);

CustomGoogleMap.displayName = 'CustomGoogleMap';

export default CustomGoogleMap;
