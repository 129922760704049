import styled, { css } from 'styled-components';
import { Flex, colors, mediaQueries, ProfileIcon } from '@beauty/beauty-market-ui';

const checkedStyles = css`
  background-color: ${colors.blue.standard};
  * {
    color: ${colors.white.standard}!important;
  }
  svg {
    background-color: ${colors.white.standard};
    path {
      stroke: ${colors.blue.standard};
    }
  }
`;

export const croppedText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled(Flex)<{ checked?: boolean }>`
  width: 100%;
  min-height: 96px;
  align-items: center;
  justify-content: start;
  gap: 8px;
  width: 100%;
  min-height: 96px;
  padding: 16px;
  box-sizing: border-box;
  background-color: ${colors.grey.light};
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;

  ${mediaQueries.md} {
    gap: 16px;
  }

  svg path {
    stroke: ${colors.grey.standard};
  }

  :hover {
    ${checkedStyles}
  }

  ${({ checked }) => checked && checkedStyles}
  * {
    letter-spacing: 0.1px;
  }
`;

export const BlockView = styled(Flex)`
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;

  p,
  span {
    width: 100%;
    ${croppedText}
  }
`;

export const StyledProfileIcon = styled(ProfileIcon)`
  width: 40px;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 50%;
`;
