import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Flex, H5, BodySmall, DataList, Separator, colors } from '@beauty/beauty-market-ui';
import { getMaxPercentDiscount } from '../../page/Booking/helpers';
import { Organisation } from '../../page/Organisation/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentService, setService } from '../../store/redux-slices/bookingSlice';
import { CategoryType, ServiceType, SpecialRequirementType } from '../../types';
import Categorizer from './components/Categorizer';
import SelectedServicePopup from './components/SelectedServicePopup';
import ServicesSidebar from './components/ServicesSidebar';
import { Container, ServicesWrapper, StyledFlex } from './style';

interface ServicesProps {
  offersCategories: CategoryType[];
  organisation: Organisation;
  specialRequirement?: SpecialRequirementType;
}

const Services = ({ offersCategories, specialRequirement, organisation }: ServicesProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const selectedService = useAppSelector(currentService);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const onServiceBook = useCallback(
    (service?: ServiceType | null) => {
      selectedService && selectedService.id === service?.id
        ? dispatch(setService(null))
        : service && dispatch(setService(service));
    },
    [dispatch, selectedService],
  );
  const featuredServices = offersCategories
    .flatMap(category => category.services)
    .filter(service => service?.discounts && service.discounts.length > 0);

  const updatedOffersCategories = [
    {
      id: 0,
      name: 'Featured',
      offersCount: featuredServices.length,
      services: featuredServices,
    },
    ...offersCategories,
  ] as CategoryType[];

  const onServiceInfo = (service: ServiceType) => {
    dispatch(setService(service));
    setIsSidebarOpen(prevValue => !prevValue);
  };

  const mapServices: (service: ServiceType) => JSX.Element = service => {
    const discountPercent = getMaxPercentDiscount(service.discounts);
    return (
      <Element key={service.id}>
        <DataList
          title={service.category as string}
          label={service.title as string}
          description={service.durationText as string}
          oldPrice={service.oldPrice as string}
          price={service.price}
          onLabelClick={() => onServiceInfo(service)}
          onCheckboxClick={() => onServiceBook(service)}
          defaultChecked={selectedService?.id === service.id}
          discount={discountPercent > 0 ? `${t('organisation.booking.saveUpTo')} ${discountPercent}%` : undefined}
        />
      </Element>
    );
  };

  const groups = updatedOffersCategories.map((group: CategoryType) => (
    <StyledFlex key={group.id} name={`category-${group.id}`}>
      <H5>{group.name}</H5>
      {!!group.description && (
        <BodySmall mt="8px" mb="24px" color={colors.grey.dark}>
          {group.description}
        </BodySmall>
      )}
      {group.services?.map(mapServices)}
      <Separator marginBottom={24} />
    </StyledFlex>
  ));

  return (
    <>
      <ServicesWrapper>
        <Categorizer categories={updatedOffersCategories} />

        <Container id="services">
          {specialRequirement ? (
            <Flex flexDirection="column" mb="24px" name="category-0">
              <H5>{specialRequirement.title}</H5>
              <BodySmall marginTop="8px">{specialRequirement.text}</BodySmall>
            </Flex>
          ) : null}
          {groups}
        </Container>

        {isSidebarOpen && selectedService && (
          <ServicesSidebar
            setIsOpen={setIsSidebarOpen}
            organisationName={organisation.name}
            serviceId={selectedService?.id}
            isServiceSelected={!!selectedService}
          />
        )}
      </ServicesWrapper>
      {selectedService && <SelectedServicePopup orgId={organisation.id} />}
    </>
  );
};

export default Services;
