import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Promo } from '@beauty/beauty-market-ui';
import { useRequest } from '../../../hooks';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUser, setFavouriteId, ThunkUser } from '../../../store/redux-slices/userSlice';
import { UserActions } from '../../../types';
import { FavouritePopup } from '../../FavouritePopup';
import { ShareOrganisationModal } from '../ShareModal';
import { StyledPromo } from '../style';

export const OrganisationCard = ({
  id,
  image,
  headline,
  title,
  rating,
  reviews,
  badge,
  photos,
  href,
  isBlueHover,
  isBlank = false,
  favourite,
  isActive = false,
  state = {},
}) => {
  const { isLogin } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [shareOpen, setShareOpen] = useState(false);
  const [favouriteOpen, setFavouriteOpen] = useState(false);

  const addToFavourites = useRequest(ThunkUser.addToFavourites, UserActions.AddToFavourites, true);
  const removeFromFavourites = useRequest(ThunkUser.removeFromFavourites, UserActions.RemoveFromFavourites, true);

  const isStub = id === '0';

  const handleSignInClick = () => {
    navigate(RouterUrl.Login, {
      state: { redirectedFrom: RouterUrl.Homepage, searchParams: window.location.search, ...state },
    });
  };

  const onFavouriteClick = () => {
    if (isStub) return;
    if (isLogin) {
      if (favourite) {
        dispatch(setFavouriteId(id));
        removeFromFavourites({ orgId: id });
      } else {
        addToFavourites({ orgId: id });
      }
    } else handleSignInClick();
  };

  return (
    <StyledPromo>
      <Promo
        key={id}
        title={title}
        image={image}
        badge={badge}
        headline={headline}
        reviews={reviews}
        photos={photos}
        rating={rating}
        isBlank={isBlank}
        isBlueHover={isBlueHover}
        href={isStub || href}
        onShareClick={() => isStub || setShareOpen(true)}
        onFavouriteClick={onFavouriteClick}
        isActive={favourite}
        isHighlighted={isActive}
      />
      <ShareOrganisationModal open={shareOpen} onClose={() => setShareOpen(false)} organisationId={id} />
      <FavouritePopup open={favouriteOpen} onClose={() => setFavouriteOpen(false)} />
    </StyledPromo>
  );
};
