import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormikProps } from 'formik';
import { Button, CoverStack, CoverStackSpecialist } from '@beauty/beauty-market-ui';
import { SidebarSheet } from '../../../components';
import { getSelectedLanguage } from '../../../constants';
import { formatDateToLocale } from '../../../helpers';
import { useAppSelector } from '../../../store/hooks';
import { currentOrganization, daySelected, setOpenCheckoutSidebar } from '../../../store/redux-slices/bookingSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { Spinner } from '../../../style';
import AccountLogIn from '../components/AccountLogIn';
import PaymentMethods from '../components/PaymentMethods';
import PersonalData from '../components/PersonalData/PersonalData';
import { PersonalDataFormTypes } from '../components/PersonalData/PersonalData.definitions';
import { PoliticInfo } from '../components/PoliticInfo';
import { PayBy } from '../helpers';
import { Wrapper } from '../style';
import { useAppointmentSubmit } from './hooks';

export const CheckoutSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formRef = useRef<FormikProps<PersonalDataFormTypes>>();

  const [paymentMethod, setPaymentMethod] = useState<string>(PayBy.Cash);
  const [isValid, setIsValid] = useState<boolean>(false);

  const organisation = useAppSelector(currentOrganization);
  const selectedDay = useAppSelector(daySelected);
  const user = useAppSelector(selectUser);

  const isBookDisabled = !isValid || !paymentMethod;

  const { onFormSubmit, isLoading } = useAppointmentSubmit();

  if (!organisation || !selectedDay) return null;

  const coverStackProps = {
    specialistType: t(organisation.offers.categories.map(cat => cat.name).join(', ')),
    specialistLabel: organisation.name,
    // rating: organisation.rating.total,
    imgUrl: organisation.mainPhoto,
  };

  const content = (
    <Wrapper>
      <CoverStack header={<CoverStackSpecialist {...coverStackProps} />} width="100%" />
      {!user.isLogin && <AccountLogIn />}
      <PersonalData ref={formRef} onFormSubmit={onFormSubmit} setIsValid={setIsValid} />
      <PaymentMethods method={paymentMethod} setMethod={setPaymentMethod} />
      <PoliticInfo />
    </Wrapper>
  );

  const footer = (
    <Button
      type="submit"
      disabled={isBookDisabled || isLoading}
      design="primary"
      width="100%"
      size="large"
      onClick={() => formRef.current && formRef.current.submitForm()}
    >
      {isLoading ? <Spinner /> : t('organisation.booking.book')}
    </Button>
  );

  return (
    <SidebarSheet
      isOpen
      onClose={() => dispatch(setOpenCheckoutSidebar(false))}
      label={`${t('organisation.booking.appointment')} ${formatDateToLocale(selectedDay, getSelectedLanguage())}`}
      descriptor={organisation.name}
      FooterBody={footer}
    >
      {content}
    </SidebarSheet>
  );
};
