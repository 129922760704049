import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { Nullable } from 'tsdef';
import { EventStatus } from '@beauty/beauty-market-ui';
import { AppointmentType } from '../../types';
import { CommonActions } from '../actions';
import { RootState } from '../store';

type AppointmentsState = {
  appointments: AppointmentType[];
  lastAddedId: Nullable<string>;
};

const initialState: AppointmentsState = {
  appointments: [],
  lastAddedId: null,
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    writeAppointments: (state, action: PayloadAction<{ data: AppointmentType[] }>) => {
      state.appointments = cloneDeep(action.payload.data /* .filter(item => item.status !== EventStatus.UNCLOSED) */);
    },
    setAppointmentStatus: (state, action: PayloadAction<{ id: string; status: EventStatus }>) => {
      const index = state.appointments.findIndex(item => item.id === action.payload.id);
      if (index > -1) {
        state.appointments[index].status = action.payload.status;
      }
    },
    rebookAppointment: (
      state,
      action: PayloadAction<{ id: string; date: string; time: string; service: string; price: string }>,
    ) => {
      let appointment = state.appointments.find(item => item.id === action.payload.id);
      appointment && (appointment = { ...appointment, ...action.payload });
    },
    resetAppointments: state => {
      state.appointments = [];
    },
    setLastAddedId: (state, action: PayloadAction<Nullable<string>>) => {
      state.lastAddedId = action.payload;
    },
  },
  extraReducers: builder => builder.addCase(CommonActions.logout, () => initialState),
});

export const { writeAppointments, rebookAppointment, resetAppointments, setLastAddedId, setAppointmentStatus } =
  appointmentsSlice.actions;

export const selectAppointments = (state: RootState) => state.appointments;
export const selectAppointmentsData = (state: RootState) => state.appointments.appointments;

export default appointmentsSlice.reducer;
