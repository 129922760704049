import React from 'react';
import { useTranslation } from 'react-i18next';
import { H3 } from '@beauty/beauty-market-ui';
import { ID_FOR_ANY_SPECIALIST } from '../../../constants';
import { getFullName } from '../../../helpers';
import { useDisableHeaderForMobile } from '../../../hooks';
import { useAppSelector } from '../../../store/hooks';
import { currentService, currentSpecialist } from '../../../store/redux-slices/bookingSlice';
import { SpecialistBookingCard } from '../components/SpecialistBookingCard/SpecialistBookingCard';
import { getMaxPercentDiscount } from '../helpers';
import { MobileOffsetY } from '../style';
import { useSpecialist } from './hooks/useSpecialist';
import PopupBookingSpecialist from './PopupBookingSpecialist';
import { SpecialistWrapper, Wrapper } from './style';

const BookingSpecialist = () => {
  const { t } = useTranslation();

  const serviceInfo = useAppSelector(currentService);
  const selectedSpecialist = useAppSelector(currentSpecialist);

  const { specialists, handleClickSpecialist } = useSpecialist();

  useDisableHeaderForMobile();

  if (!serviceInfo) return null;

  return (
    <MobileOffsetY>
      <Wrapper>
        <H3>{t('organisation.booking.selectSpecialist')}</H3>
        <SpecialistWrapper>
          {specialists.map(spec => (
            <SpecialistBookingCard
              key={spec.id}
              avatarUrl={spec.avatarUrl}
              title={getFullName(spec)}
              label={spec.specialization}
              checked={spec.selected}
              maxDiscount={getMaxPercentDiscount(spec.discount)}
              onClick={handleClickSpecialist(spec)}
            />
          ))}
        </SpecialistWrapper>

        {selectedSpecialist && <PopupBookingSpecialist specId={selectedSpecialist?.id} />}
      </Wrapper>
    </MobileOffsetY>
  );
};

export default BookingSpecialist;
