import { apiRequest } from '../api/api';
import { AuthEndpoints, GoogleEndpoints } from '../api/endpoints/endpoints';
import { AccType } from '../constants';

const logout = async () => {
  const [error, response] = await apiRequest.delete({
    endpoint: AuthEndpoints.Logout,
  });
  return { error: error?.data, response: response?.data };
};

const loginByGoogle = async (accType: AccType, token: string) => {
  const [error, response] = await apiRequest.post({
    endpoint: GoogleEndpoints.AuthAccount,
    data: { accType, token },
  });

  if (error) {
    throw error.data;
  }

  return response ? response.data : null;
};

export const authAPI = {
  logout,
  loginByGoogle,
};
