import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Flex, H5, Slider } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { isRtl } from '../../../../helpers';
import { getTranslation } from '../../../../helpers/utils';
import { AsyncComponent } from '../../../../hoc';
import { useMediaScreen } from '../../../../hooks';
import { BadgeType } from '../../../../types';
import { BadgeItem } from '../Rating/BadgeItem';

type BadgesProps = {
  items: BadgeType[];
  isLoading: boolean;
};

const Badges = ({ items, isLoading }: BadgesProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen();

  const rtl = isRtl();
  const language = getSelectedLanguage();

  if (isEmpty(items)) return null;

  const slides = items.map(badge => (
    <BadgeItem
      key={badge.title?.text}
      avatar={badge.activeIconUrl}
      title={getTranslation(badge.title, language)}
      subtitle={t(`organisation.rating.ratings`, { count: badge.count })}
    />
  ));

  return (
    <AsyncComponent isLoading={isLoading}>
      <Flex flexDirection="column" gap="16px">
        <H5>{t('organisation.rating.badges')}</H5>
        {isDesktop ? slides : <Slider inlineSlider slides={slides} rtl={rtl} />}
      </Flex>
    </AsyncComponent>
  );
};

export default memo(Badges);
