import React from 'react';
import { useTranslation } from 'react-i18next';
import { Caption, colors, Link } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';

interface Props extends React.PropsWithChildren {
  className?: string;
}

export const PoliticInfo: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Caption color={colors.grey.dark} mt={16}>
      {t('organisation.booking.policy')}
      <Link design="blue" size="xs" display="inline-block" href={`${RouterUrl.TermsOfService}#changes`} target="_blank">
        {t('organisation.booking.policyLink')}
      </Link>
    </Caption>
  );
};
