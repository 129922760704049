import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H3, Button, PinIcon } from '@beauty/beauty-market-ui';
import { OrganisationCard } from '../../../components';
import { FilterType, OurChoiceOrganisations, getSelectedLanguage } from '../../../constants';
import { getTranslatedString, sortByOurChoice } from '../../../helpers';
import { AsyncComponent } from '../../../hoc';
import { useGetOrganisations } from '../../../hooks/useGetOrganisations';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { currentTopCategory } from '../../../store/redux-slices/categoriesSlice';
import { searchState } from '../../../store/redux-slices/searchSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
import { ID_ALL_CATEGORY } from '../constants';
import { AllOffersHeader, AllOffersButtons, OffersGrid, AllOffersContainer } from '../style';

interface OffersListProps {
  onChangeView: (value: string) => void;
}

export const OffersList = ({ onChangeView }: OffersListProps) => {
  const { t } = useTranslation();

  const { isForceFetch, favourites } = useAppSelector(selectUser);
  const topCategory = useAppSelector(currentTopCategory);
  const topCategoryId = topCategory?.id || ID_ALL_CATEGORY;

  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);

  const { isLoading, organisations: categoryOrganisations } = useGetOrganisations(topCategoryId, isForceFetch);

  const language = getSelectedLanguage();
  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;

  const organizationsList = useMemo(() => sortByOurChoice(organisations), [organisations]);

  return (
    <AllOffersContainer>
      <AllOffersHeader>
        <H3>{`${t('home.chooseFrom')} ${organizationsList?.length} ${t('home.offerings')}`}</H3>
        <AllOffersButtons>
          {/* <Button
              {...buttonProps}
              onClick={onFilterOpen}
              prefix={<SlidersIcon height="15" width="15" />}
              count={selectedFiltersCount || false}
            >
              {t('home.filters')}
            </Button> */}
          <Button
            design="quaternary"
            size="small"
            width="100%"
            onClick={() => onChangeView('map')}
            prefix={<PinIcon height="15" width="15" />}
          >
            {t('home.showMap')}
          </Button>
        </AllOffersButtons>
      </AllOffersHeader>
      <AsyncComponent isLoading={isLoading}>
        <OffersGrid>
          {organizationsList.map(organisation => (
            <OrganisationCard
              id={organisation.id}
              key={organisation.id}
              rating={organisation.rating}
              image={organisation.mainPhoto}
              headline={getTranslatedString(language, organisation.category)}
              title={organisation.name}
              photos={t('organisation.photos', { count: organisation.photoCount ? organisation.photoCount - 1 : 0 })}
              reviews={t('organisation.reviews', { count: organisation.review })}
              badge={OurChoiceOrganisations.includes(organisation.id) ? t('organisation.ourChoice') : ''}
              href={`${RouterUrl.Organisation}/${organisation.id}`}
              isBlueHover
              favourite={favourites.ids?.includes(organisation.id)}
            />
          ))}
        </OffersGrid>
      </AsyncComponent>
    </AllOffersContainer>
  );
};
