import { telAvivGeolocation } from '../constants';
import { useAppSelector } from '../store/hooks';
import { searchState } from '../store/redux-slices/searchSlice';
import { LatLngLiteral } from '../types';
import { useGeolocation } from './useGeolocation';

export const useLocation = () => {
  const { geolocation } = useAppSelector(searchState);
  const { askedLocation } = useGeolocation();

  const userLocation: LatLngLiteral =
    askedLocation && geolocation ? { lat: Number(geolocation.lat), lng: Number(geolocation.lng) } : telAvivGeolocation;

  return { userLocation };
};
