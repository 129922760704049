import { useTranslation } from 'react-i18next';
import { H3 } from '@beauty/beauty-market-ui';
import { Services } from '../../../components';
import { CategoryType } from '../../../types';
import { OfferingsWrapper } from '../style';
import { Organisation } from '../types';

interface OfferingsProps {
  categories: CategoryType[];
  organisation: Organisation;
}

const Offerings = ({ categories, organisation }: OfferingsProps) => {
  const { t } = useTranslation();

  return categories.find(category => category.offersCount) ? (
    <OfferingsWrapper>
      <H3 width="100%">{t('organisation.offerings')}</H3>
      <Services offersCategories={categories} organisation={organisation} />
    </OfferingsWrapper>
  ) : null;
};

export default Offerings;
