import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CommonActions } from '../actions';
import { RootState } from '../store';

export interface HeaderState {
  isOnTop: boolean;
  isShowMenu: boolean;
  isShowHeader: boolean;
}

const initialState: HeaderState = {
  isOnTop: false,
  isShowMenu: true,
  isShowHeader: true,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    updateIsOnTop: (state, action: PayloadAction<{ isOnTop: boolean }>) => ({
      ...state,
      ...action.payload,
    }),
    updateShowMenu: (state, action: PayloadAction<{ isShowMenu: boolean }>) => ({
      ...state,
      ...action.payload,
    }),
    updateShowHeader: (state, action: PayloadAction<{ isShowHeader: boolean }>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updateIsOnTop, updateShowMenu, updateShowHeader } = headerSlice.actions;

export const headerState = (state: RootState) => state.header;
export const isShowHeader = (state: RootState) => state.header.isShowHeader;

export default headerSlice.reducer;
