import { Link as ScrollSpyLink } from 'react-scroll';
import styled from 'styled-components';
import { display, margin, space, width } from 'styled-system';
import {
  Flex,
  mediaQueries,
  colors,
  LinkBasicStyles,
  getColorsForLink,
  getFontForLink,
} from '@beauty/beauty-market-ui';

export const SpyLink = styled(ScrollSpyLink)`
  ${({ theme }) => LinkBasicStyles(theme)};
  ${({ size, theme }) => getFontForLink(size, theme)};
  gap: 8px;
  display: flex;
  text-decoration: none;
  ${({ disabled }) => `cursor: ${disabled ? 'not-allowed' : 'pointer'};`}
  ${({ design, disabled, theme, isNotStroke }) => design && getColorsForLink(design, !!disabled, !!isNotStroke, theme)};
  ${margin}
  ${space}
  ${width}
  ${display}
`;

export const MenuWrapper = styled(Flex)`
  gap: 16px;
  width: calc(100vw - 16px);
  z-index: 2;
  position: sticky;
  top: 72px;
  align-self: flex-start;
  align-items: center;
  flex-shrink: 0;
  background-color: ${colors.white.standard};
  margin-bottom: 0;
  height: 72px;

  ${mediaQueries.md} {
    margin-top: 6px;
    margin-bottom: 0;
    width: 181px;
    top: 120px;
    flex-direction: column;
    height: auto;
    align-items: start;
  }

  a {
    font-weight: 500 !important;
    line-height: 24px !important;
  }
`;
