import { ReactNode } from 'react';
import { BookingPopup } from '../../../components';
import { useMediaScreen } from '../../../hooks';
import CustomBottomSheet from './CustomBottomSheet/CustomBottomSheet';
import SelectedService from './SelectedService';

export interface PopupProps {
  controlButton: ReactNode;
  actionButton: ReactNode;
}

const Popup = ({ actionButton, controlButton }: PopupProps) => {
  const { isMobile } = useMediaScreen('md');

  const mobileFooter = (
    <>
      {controlButton}
      {actionButton}
    </>
  );

  return isMobile ? (
    <CustomBottomSheet content={<SelectedService />} footer={mobileFooter} />
  ) : (
    <BookingPopup primaryButton={actionButton} secondaryButton={controlButton} content={<SelectedService />} />
  );
};

export default Popup;
