import { useTranslation } from 'react-i18next';
import { BodySmall, Flex, Caption, colors, H7 } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers';
import { useAppSelector } from '../../../store/hooks';
import { currentService } from '../../../store/redux-slices/bookingSlice';
import { usePrice } from '../hooks';
import { GreyBodySmall } from '../style';
import { OldPrice, ServicePrice } from './style';

const SelectedService = () => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const service = useAppSelector(currentService);

  const { priceBase, priceDiscount, discountPercent } = usePrice();

  if (!service) return null;

  return (
    <Flex justifyContent="space-between" alignItems="center" width="inherit" gap="7px">
      <Flex flexDirection="column" gap="2px">
        <BodySmall>{getTranslation(service.title, language)}</BodySmall>
        <Flex flexWrap="wrap">
          <GreyBodySmall lowline>{service.durationText}</GreyBodySmall>
          <GreyBodySmall lowline> • </GreyBodySmall>
          <GreyBodySmall lowline>{getTranslation(service.category, language)}</GreyBodySmall>
        </Flex>
      </Flex>
      <ServicePrice>
        {priceDiscount ? (
          <>
            <H7 textAlign="right">{priceDiscount}</H7>
            <OldPrice>{priceBase}</OldPrice>
          </>
        ) : (
          <H7 textAlign="right">{priceBase}</H7>
        )}
        {discountPercent ? (
          <Caption
            lowline
            color={colors.green.standard}
          >{`${t('organisation.booking.saveUpTo')} ${discountPercent}%`}</Caption>
        ) : null}
      </ServicePrice>
    </Flex>
  );
};

export default SelectedService;
