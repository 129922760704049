import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ID_ALL_CATEGORY } from '../../page/HomePage/constants';
import { TopCategory } from '../../types';
import { RootState } from '../store';

export interface CategoriesState {
  topCategories: TopCategory[];
  currentTopCategory: TopCategory;
}

const initialState: CategoriesState = {
  topCategories: [],
  currentTopCategory: { title: '', id: ID_ALL_CATEGORY },
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCurrentTopCategory: (state, action: PayloadAction<string>) => {
      state.currentTopCategory =
        state.topCategories.find(category => category.id === action.payload) || state.topCategories[0];
    },
    setAllTopCategories: (state, action: PayloadAction<TopCategory[]>) => {
      state.topCategories = action.payload;
    },
  },
});

export const { setCurrentTopCategory, setAllTopCategories } = categoriesSlice.actions;

export const currentTopCategory = (state: RootState) => state.categories.currentTopCategory;
export const allTopCategories = (state: RootState) => state.categories.topCategories;

export default categoriesSlice.reducer;
