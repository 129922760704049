import { Nullable } from 'tsdef';
import { ActionType, ProfileType } from '../../../../../types/user';
import LogoutSidebar from '../../../../LogoutSidebar/LogoutSidebar';
import { AddCardSidebar } from './AddCardSidebar';
import { ChangeEmailSidebar } from './ChangeEmailSidebar';
import { ChangePhoneSidebar } from './ChangePhoneSidebar';
import { DeleteAccountSidebar } from './DeleteAccountSidebar';
import { EditProfileSidebar } from './EditProfileSidebar';
import { SendSmsCodeSidebar } from './SendSmsCodeSidebar';

type ProfileSidebarProps = {
  action: ActionType;
  setAction: (action: ActionType) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setPopupOpen: (isOpen: boolean) => void;
  setNewEmail: (email: string) => void;
  newRegion: string;
  setNewRegion: (region: string) => void;
  newNumber: string;
  setNewNumber: (number: string) => void;
  profile: ProfileType;
  setPhoto: (image?: Nullable<File>) => void;
  isEditMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  photo?: Nullable<File>;
};

const ProfileSidebar = ({
  action,
  setAction,
  profile,
  isOpen,
  setIsOpen,
  setPopupOpen,
  setNewEmail,
  newRegion,
  setNewRegion,
  newNumber,
  setNewNumber,
  photo,
  setPhoto,
  isEditMode,
  setEditMode,
}: ProfileSidebarProps) => {
  switch (action) {
    case 'addCard':
      return <AddCardSidebar isOpen={isOpen} setIsOpen={setIsOpen} />;
    case 'deleteAccount':
      return <DeleteAccountSidebar isOpen={isOpen} setIsOpen={setIsOpen} />;
    case 'logout':
      return <LogoutSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />;
    case 'changeEmail':
      return (
        <ChangeEmailSidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setPopupOpen={setPopupOpen}
          email={profile.email}
          setNewEmail={setNewEmail}
        />
      );
    case 'changePhone':
      return (
        <ChangePhoneSidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setAction={setAction}
          setNewRegion={setNewRegion}
          setNewNumber={setNewNumber}
        />
      );
    case 'smsCode':
      return <SendSmsCodeSidebar isOpen={isOpen} setIsOpen={setIsOpen} code={newRegion} phone={newNumber} />;
    default:
      return (
        <EditProfileSidebar
          profile={profile}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setPhoto={setPhoto}
          photo={photo}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
        />
      );
  }
};

export { ProfileSidebar };
