import { TFunction } from 'react-i18next';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { apiRequest } from '../api/api';
import { OrganisationEndpoints, ServicesEndpoints, UserEndpoints } from '../api/endpoints/endpoints';
import { ReviewAppointmentFormTypes } from '../page/Client/Appointments/components/Appointments.definitions';
import { EventStatus } from '../page/Client/Appointments/constants';
import { FullStatus } from '../page/Client/Appointments/types';
import { AppointmentType, CreateAppointmentParamsType, ErrorData } from '../types';

export const userLocale = (t: TFunction<'translation', undefined>) => t('locale');
export const timeFormatter = (locale = 'ru') => Intl.DateTimeFormat(locale, { timeStyle: 'short' });
export const dateFormatter = locale => Intl.DateTimeFormat(locale, { dateStyle: locale === 'HE' ? 'short' : 'medium' });

export const sortAppointmentsByDate = (
  firstAppointment: AppointmentType,
  secondAppointment: AppointmentType,
  isReverse = false,
) => {
  const firstAppointmentDate = new Date(firstAppointment.start);
  const secondAppointmentDate = new Date(secondAppointment.start);
  const difference = firstAppointmentDate.getTime() - secondAppointmentDate.getTime();
  return isReverse ? -difference : difference;
};

export const sortAppointmentsConfirmedFirst = (
  firstAppointment: AppointmentType,
  secondAppointment: AppointmentType,
) => (firstAppointment.status === 'CONFIRMED' && secondAppointment.status !== 'CONFIRMED' ? -1 : 0);

export const DatePrefix = (date: string, t: TFunction<'translation', undefined>): string => {
  const today = moment().format().substring(0, 10);
  const tomorrow = moment().add(1, 'days').format().substring(0, 10);
  const yesterday = moment().subtract(1, 'days').format().substring(0, 10);
  const cutDate = date.substring(0, 10);

  switch (cutDate) {
    case today:
      return t('datePrefix.today');
    case tomorrow:
      return t('datePrefix.tomorrow');
    case yesterday:
      return t('datePrefix.yesterday');
    default:
      return '';
  }
};

export const getFullDate = (date: string, t: TFunction<'translation', undefined>, language: string) => {
  const time = moment.parseZone(date).format('HH:mm');

  return `${time}, ${DatePrefix(date, t)}${dateFormatter(language).format(new Date(date))} `;
};

export const setTitle = (status: EventStatus, t: TFunction<'translation', undefined>) => {
  switch (status) {
    case EventStatus.CONFIRMED:
      return t('appointments.labels.confirmed');
    case EventStatus.INPROGRESS:
      return t('appointments.labels.inProgress');
    case EventStatus.PAST:
      return t('appointments.labels.past');
    case EventStatus.CANCELLED:
      return t('appointments.labels.cancelled');
    case EventStatus.WAITING:
      return t('appointments.labels.waiting');
    case EventStatus.PENDING:
      return t('appointments.labels.pending');
    default:
      return '';
  }
};

export const cancelAppointment = async (appointmentId: string, clientVisitId: string) => {
  const [, response] = await apiRequest.delete({
    endpoint: ServicesEndpoints.CancelAppointment,
    endpointParams: { clientVisitId, appointmentId },
  });
  return response;
};

export const sendReviewAppointment = async (organisationId: string, values: ReviewAppointmentFormTypes) => {
  const [, response] = await apiRequest.patch({
    endpoint: OrganisationEndpoints.Organisation,
    endpointParams: { orgId: organisationId, queryParams: '' },
    data: { ...values },
  });

  return response?.data;
};

export const createAppointment = async params => {
  const [error, response] = await apiRequest.post({
    endpoint: UserEndpoints.CreateAppointment,
    data: { ...params },
  });

  return { response: response?.data, error: error?.data as ErrorData };
};

export const getShownEvents = (events, status) => {
  const part = events
    ?.filter(event => {
      if (status === FullStatus.CANCELLED) return [FullStatus.NOSHOW, FullStatus.CANCELLED].includes(event.status);
      if (status === FullStatus.PAST) return [FullStatus.PAST, FullStatus.UNCLOSED].includes(event.status);
      if (status !== FullStatus.CONFIRMED) return event.status === status;
      return [FullStatus.CONFIRMED, FullStatus.PENDING, FullStatus.INPROGRESS].includes(event.status);
    })
    .sort((a, b) => sortAppointmentsByDate(a, b, [FullStatus.PAST, FullStatus.CANCELLED].includes(status)));
  // const total = cloneDeep(events)?.sort(sortAppointmentsByDate).sort(sortAppointmentsConfirmedFirst);
  return status !== FullStatus.ALL && part.length ? part : cloneDeep(events);
};

export const getCardStatus = (status: EventStatus) => {
  switch (true) {
    case status === EventStatus.INPROGRESS:
      return EventStatus.CONFIRMED;
    case status === EventStatus.CANCELLED || status === EventStatus.NOSHOW:
      return EventStatus.CANCELLED;
    case status === EventStatus.PAST || status === EventStatus.UNCLOSED:
      return EventStatus.PAST;
    default:
      return status;
  }
};
