import { TFunction } from 'react-i18next';
import { NotifyStatus } from '@beauty/beauty-market-ui';

export const getErrorNotifyContent = (t: TFunction<'translation', undefined>, message?: string) => {
  const errorKey = message || 'alerts.subtitle.unknown';
  return {
    id: errorKey,
    status: NotifyStatus.ERROR,
    title: t(`alerts.title.error`),
    subtitle: t(errorKey),
  };
};
