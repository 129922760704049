import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { ID_FOR_ANY_SPECIALIST } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  currentOrganization,
  currentService,
  currentSpecialist,
  setSpecialist,
} from '../../../../store/redux-slices/bookingSlice';
import { SpecialistType } from '../../../../types/specialist';
import { getAllDiscount, getAnySpecialistState } from '../../helpers';

export const useSpecialist = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const serviceInfo = useAppSelector(currentService);
  const selectedSpecialist = useAppSelector(currentSpecialist);
  const organisation = useAppSelector(currentOrganization);

  const team: SpecialistType[] = serviceInfo?.orgSpecialist || [];

  const anySpecialistState = useMemo(
    () =>
      getAnySpecialistState(
        t('organisation.booking.allSpecialists'),
        !selectedSpecialist || selectedSpecialist.id === ID_FOR_ANY_SPECIALIST,
        organisation?.mainPhoto,
        getAllDiscount(team),
      ),
    [team, selectedSpecialist, organisation],
  );

  const specialists = useMemo(() => {
    if (team.length === 0) {
      return [anySpecialistState];
    }

    return [
      anySpecialistState,
      ...team.map(spec => ({
        ...spec,
        selected: spec.id === selectedSpecialist?.id,
      })),
    ].filter(person => person.published);
  }, [selectedSpecialist, team, anySpecialistState]);

  const handleClickSpecialist = useCallback(
    (specialist: SpecialistType) => () => {
      dispatch(setSpecialist(specialist));
    },
    [],
  );

  useEffect(() => {
    if (!selectedSpecialist) {
      dispatch(setSpecialist(anySpecialistState));
    } else if (selectedSpecialist.id === ID_FOR_ANY_SPECIALIST && !isEqual(selectedSpecialist, anySpecialistState)) {
      dispatch(setSpecialist(anySpecialistState));
    }
  }, [anySpecialistState, selectedSpecialist]);

  return { specialists, handleClickSpecialist };
};
