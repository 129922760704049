import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../routes/routes';
import Container from '../Container';
import { BreadcrumbFactory, Crumb } from './dictionary';
import { BreadcrumbsContainer } from './style';

type CrumbsProps = {
  path: RouterUrl;
  additionalCrumbs?: Crumb[];
  currentPath?: string;
  fixed?: boolean;
};

export const Crumbs: React.FC<CrumbsProps> = ({ path, fixed, currentPath, additionalCrumbs = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadcrumbs = useMemo(() => {
    const baseCrumbs = new BreadcrumbFactory().create(path, additionalCrumbs);
    const translatedCrumbs = baseCrumbs.map(crumb => ({
      name: t(`breadcrumbs.${crumb.key}`, crumb.key),
      onItemClick: () => (crumb.link ? navigate(crumb.link) : navigate(-1)),
    }));

    return currentPath ? [...translatedCrumbs, { name: currentPath, onItemClick: () => undefined }] : translatedCrumbs;
  }, [path, additionalCrumbs, currentPath, t, navigate]);

  return (
    <BreadcrumbsContainer fixed={fixed}>
      <Container>
        <Breadcrumbs items={breadcrumbs} />
      </Container>
    </BreadcrumbsContainer>
  );
};
