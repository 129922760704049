import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useNotify } from '@beauty/beauty-market-ui';
import { AccType } from '../../constants';
import { authorizeWithProvider, getErrorNotifyContent } from '../../helpers';
import { authAPI } from '../../helpers/authAPI';
import { useAppDispatch } from '../../store/hooks';

export const useGoogleAuth = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotify();

  const authSuccess = async (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    if (!tokenResponse.access_token) return;

    try {
      const response = await authAPI.loginByGoogle(AccType.USER, tokenResponse.access_token);
      authorizeWithProvider(response, dispatch, navigate);
    } catch (e) {
      notify(getErrorNotifyContent(t));
      console.error(e);
    }
  };

  const authFail = (errorResponse: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    notify(getErrorNotifyContent(t));
    console.error(errorResponse);
  };

  const login = useGoogleLogin({
    onSuccess: authSuccess,
    onError: authFail,
  });

  return { login };
};
