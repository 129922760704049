import React from 'react';
import { useMediaScreen } from '../../../hooks';
import { StyledButton } from '../style';

interface Props extends React.PropsWithChildren {
  onClick: () => void;
  disabled?: boolean;
}

export const ActionButton: React.FC<Props> = ({ onClick, children, disabled }) => {
  const { isDesktop } = useMediaScreen('md');

  return (
    <StyledButton onClick={onClick} size={isDesktop ? 'large' : 'medium'} disabled={disabled}>
      {children}
    </StyledButton>
  );
};
