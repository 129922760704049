import React from 'react';
import { Button } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../hooks';
import { StyledArrowLeftIcon } from '../style';

interface Props extends React.PropsWithChildren {
  onClick: () => void;
}

export const ArrowBack: React.FC<Props> = ({ onClick }) => {
  const { isDesktop } = useMediaScreen('md');

  const sizeArrow = isDesktop ? '24' : '20';

  return (
    <Button design="secondary" onClick={onClick} px={16} py={isDesktop ? 16 : 14}>
      <StyledArrowLeftIcon height={sizeArrow} width={sizeArrow} />
    </Button>
  );
};
