import { useEffect } from 'react';
import { useScrollBelowLine } from '../../hooks/useScrollBelowLine';
import { useAppDispatch } from '../../store/hooks';
import { updateIsOnTop } from '../../store/redux-slices/headerSlice';
import { HowWeHelp } from './HowWeHelp';
import { Managing } from './Managing';
import { OurBenefits } from './OurBenefits';
import { StyledContainer } from './style';
import { TariffPlans } from './TariffPlans';
import { TopSection } from './TopSection';

export const ForBusiness = () => {
  const isScrollBelow10 = useScrollBelowLine(10);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isScrollBelow10) dispatch(updateIsOnTop({ isOnTop: true }));
    else dispatch(updateIsOnTop({ isOnTop: false }));
  }, [isScrollBelow10, dispatch]);

  return (
    <StyledContainer>
      <TopSection />
      <HowWeHelp />
      <OurBenefits />
      <Managing />
      <TariffPlans />
    </StyledContainer>
  );
};
