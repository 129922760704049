import { Dispatch, SetStateAction } from 'react';
import { Map } from 'google-maps-react-markers';
import Supercluster from 'supercluster';
import { Nullable } from 'tsdef';
import { RaiteTab } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../hooks';
import { LatLngLiteral, MarkerType } from '../../../types';
import ClusterMarker from '../components/ClusterMarker';
import { MapCardWrapper } from '../components/MapCardWrapper';
import MapMarker from '../components/MapMarker';
import { PointWrapper } from '../components/style';
import { xLng, yLat } from '../helper';

interface MarkerProps {
  readonly clusterTreesPoints: MarkerType[];
  onMarkerClick?: (marker: MarkerType) => void;
  readonly activeCardId?: number;
  hoverElementId?: string | null;
  mapRef?: Map | null;
  mapContainerRef: HTMLElement | null;
  supercluster?: Supercluster;
  mapCenter?: LatLngLiteral;
  setInfoCardData: Dispatch<SetStateAction<Nullable<MarkerType>>>;
}

export const useMarkers = ({
  clusterTreesPoints,
  onMarkerClick,
  activeCardId,
  mapRef,
  supercluster,
  mapCenter,
  hoverElementId,
  mapContainerRef,
  setInfoCardData,
}: MarkerProps) => {
  const { isDesktop } = useMediaScreen('md');

  const handleClusterClick = (marker: MarkerType) => {
    if (!mapRef || !supercluster || !mapCenter) return;

    const childZoom = supercluster.getClusterExpansionZoom(marker.id);
    mapRef.setZoom(childZoom);
    mapRef.panTo({
      lng: marker.x ? xLng(marker.x) : mapCenter.lng,
      lat: marker.y ? yLat(marker.y) : mapCenter.lat,
    });
  };

  return clusterTreesPoints.map(marker =>
    marker.numPoints ? (
      <ClusterMarker
        key={`${marker.id}-cluster`}
        lng={marker.x && xLng(marker.x)}
        lat={marker.y && yLat(marker.y)}
        zIndex={6}
        markersNumber={marker.numPoints}
        onClick={() => handleClusterClick(marker)}
      />
    ) : (
      <MapMarker
        key={`${marker.index}-marker`}
        lng={marker.x && xLng(marker.x)}
        lat={marker.y && yLat(marker.y)}
        zIndex={activeCardId && marker.index === activeCardId ? 99 : 5}
        onClick={() => onMarkerClick && onMarkerClick(marker)}
      >
        {isDesktop && activeCardId && marker.index === activeCardId && supercluster?.points?.[marker.index] && (
          <MapCardWrapper
            marker={supercluster?.points?.[marker.index]}
            mapContainerRef={mapContainerRef}
            onClickOutside={() => setInfoCardData(null)}
          />
        )}
        <PointWrapper>
          <RaiteTab
            rate={supercluster.points[marker.index]?.rating}
            isHighlighted={supercluster.points[marker.index]?.id === hoverElementId}
          />
        </PointWrapper>
      </MapMarker>
    ),
  );
};
