import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { COSMETOLOGY_TOP_CATEGORY, getSelectedLanguage } from '../constants';
import { getTranslation } from '../helpers';
import { fetchServiceDetails } from '../helpers/organisation';
import { getFormattedCurrency, getHoursAndMinutesForUserLocale } from '../page/Organisation/helpers';
import { useAppSelector } from '../store/hooks';
import { selectOrganisation } from '../store/redux-slices/organisationSlice';
import { ServiceType } from '../types';

export const useGetServiceInfo = (serviceId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceInfo, setServiceInfo] = useState<Nullable<ServiceType>>(null);
  const language = getSelectedLanguage();
  const { t } = useTranslation();
  const { organisation } = useAppSelector(selectOrganisation);

  const nullPrice = t('organisation.booking.nullPrice');

  useEffect(() => {
    let isServiceInfoLoading = true;

    const fetchServiceInfo = async () => {
      setIsLoading(true);
      const currentService = serviceId && (await fetchServiceDetails(serviceId));

      if (!currentService) {
        setIsLoading(false);
        return;
      }
      const { id, price, orgSpecialist, headOrgService } = currentService;
      const { title, descr, category, photo, duration } = headOrgService;

      if (isServiceInfoLoading) {
        setServiceInfo({
          id,
          title: getTranslation(title, language),
          label: getTranslation(category?.title, language),
          description: getTranslation(descr, language),
          // TODO: remove price choise after BE fixed
          price:
            price !== null && category.parentId !== COSMETOLOGY_TOP_CATEGORY
              ? getFormattedCurrency(price, organisation?.headOrganization.currency, language)
              : nullPrice,
          categoryId: category?.id,
          duration,
          durationText: getHoursAndMinutesForUserLocale(duration, language),
          orgSpecialist,
          category: getTranslation(category?.title, language),
          photos: photo,
          discounts: [],
        });

        setIsLoading(false);
      }
    };

    serviceId && fetchServiceInfo();

    return () => {
      isServiceInfoLoading = false;
    };
  }, [serviceId]);

  return {
    isLoading,
    serviceInfo,
  };
};
