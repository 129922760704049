import { useParams } from 'react-router-dom';
import { useDisableHeaderForMobile } from '../../../hooks';
import { useAppSelector } from '../../../store/hooks';
import { isOpenCheckoutSidebar } from '../../../store/redux-slices/bookingSlice';
import { BookingParams } from '../../../types/booking';
import Timeslots from '../components/Timeslots';
import { CheckoutSidebar } from '../Sidebar/CheckoutSidebar';
import { MobileOffsetY } from '../style';
import PopupBookingTimeslot from './PopupBookingTimeslot';

const BookingTimeslot = () => {
  const { serviceId, specialistId } = useParams() as BookingParams;

  const isOpenSidebar = useAppSelector(isOpenCheckoutSidebar);

  useDisableHeaderForMobile();

  if (!serviceId || !specialistId) return null;

  return (
    <MobileOffsetY>
      <Timeslots />
      <PopupBookingTimeslot />
      {isOpenSidebar && <CheckoutSidebar />}
    </MobileOffsetY>
  );
};

export default BookingTimeslot;
