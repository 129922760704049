import { Nullable } from 'tsdef';
import { DiscountType } from './discount';
import { ServiceType } from './service';
import { SpecialistType } from './specialist';
import { AvailableTimeslotType } from './timeslot';

export enum DateslotStatus {
  Available = 'AVAILABLE',
  Booked = 'BOOKED',
  Holiday = 'HOLIDAY',
}

export type BookingType = {
  service?: Nullable<ServiceType>;
  timeslot?: Nullable<AvailableTimeslotType>;
  specialist?: Nullable<SpecialistType>;
};

export type BookingParams = {
  orgId: string;
  serviceId?: string;
  specialistId?: string;
};

export type DateSlot = {
  date: string;
  discount: DiscountType[];
  status: DateslotStatus;
};
