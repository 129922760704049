import styled, { css } from 'styled-components';
import { Flex, Link, mediaQueries, truncatedText } from '@beauty/beauty-market-ui';
import MapImage from '../../../../assets/map.png';

export const ContactWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  top: 90px;

  ${mediaQueries.md} {
    min-width: 290px;
    width: 290px;
    position: sticky;
  }
`;

export const ContactBody = styled(Flex)`
  gap: 15px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaQueries.md} {
    flex-direction: row-reverse;
  }
`;

export const Map = styled(Flex)`
  flex-basis: 70px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 70px;
  background: url(${MapImage});
  background-size: cover;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    stroke: ${({ theme }) => theme.colors.blue.standard};

    path {
      fill: ${({ theme }) => theme.colors.blue.standard};
    }
  }
`;

export const ContactInfoBlock = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: max-content;
  ${mediaQueries.md} {
    width: 204px;
  }
`;

export const ContactInfo = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  ${({ theme }) =>
    theme.rtl &&
    css` svg {
  transform: scaleX(-1);
  `}
  }
`;

export const TruncatedLink = styled(Link)`
  display: block !important;
  ${truncatedText};
`;

export const IconWrapper = styled(Flex)`
  width: 16px;
  height: 16px;
  padding: 1px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;
