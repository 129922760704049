import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { Container, Crumbs } from '../../components';
import { ID_FOR_ANY_SPECIALIST } from '../../constants';
import { useMediaScreen } from '../../hooks';
import { useGetOrganisation } from '../../hooks/useGetOrganisation';
import { useGetServiceInfo } from '../../hooks/useGetServiceInfo';
import { RouterUrl } from '../../routes/routes';
import { useAppDispatch } from '../../store/hooks';
import { resetBooking, setOrganization, setService, setSpecialist } from '../../store/redux-slices/bookingSlice';
import { BookingParams } from '../../types/booking';
import { StatePanel } from './components';
import { getAllDiscount, getAnySpecialistState } from './helpers';
import { BookingWrapper, SelectPanelWrapper } from './style';

export const Booking = () => {
  const { t } = useTranslation();

  const { isDesktop } = useMediaScreen('md');

  const { orgId, serviceId, specialistId } = useParams() as BookingParams;

  const { organisation } = useGetOrganisation(orgId, true);
  const { serviceInfo } = useGetServiceInfo(serviceId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!serviceInfo) return;

    serviceInfo && dispatch(setService(serviceInfo));
  }, [serviceInfo]);

  useEffect(() => {
    organisation && dispatch(setOrganization(organisation));
  }, [organisation]);

  useEffect(() => {
    const currentSpecialist = serviceInfo?.orgSpecialist?.find(spec => spec.id === specialistId);

    if (currentSpecialist) {
      dispatch(setSpecialist(currentSpecialist));
    } else if (specialistId === ID_FOR_ANY_SPECIALIST) {
      dispatch(
        setSpecialist(
          getAnySpecialistState(
            t('organisation.booking.allSpecialists'),
            true,
            organisation?.mainPhoto,
            getAllDiscount(serviceInfo?.orgSpecialist || []),
          ),
        ),
      );
    }
  }, [serviceInfo, specialistId, organisation]);

  useEffect(
    () => () => {
      dispatch(resetBooking());
    },
    [],
  );

  if (!organisation) return null;

  return (
    <>
      {isDesktop && <Crumbs path={RouterUrl.Organisation} currentPath={organisation?.name} fixed />}
      <Container>
        <BookingWrapper>
          {isDesktop && <StatePanel organisation={organisation} />}
          <SelectPanelWrapper>
            <Outlet />
          </SelectPanelWrapper>
        </BookingWrapper>
      </Container>
    </>
  );
};
