import { TFunction } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { ID_NUMBER_REGEXP, passwordReg } from '../../../constants';
import { getShortCountryByCode, nameValidationSchema } from '../../../helpers';

export enum RegistrationFormFields {
  Name = 'name',
  Surname = 'surname',
  Password = 'password',
  Number = 'number',
  Email = 'email',
  Region = 'code',
  IdNumber = 'idNumber',
  Agreement = 'agreement',
}

export type DecodedToken = {
  orgId: string;
  email: string;
  accType: string;
  iat: number;
  exp: number;
};
export type RegistrationForm = {
  [RegistrationFormFields.Name]: string;
  [RegistrationFormFields.Surname]: string;
  [RegistrationFormFields.Region]: string;
  [RegistrationFormFields.Number]: string;
  [RegistrationFormFields.Email]: string;
  [RegistrationFormFields.IdNumber]: string;
  [RegistrationFormFields.Password]: string;
  [RegistrationFormFields.Agreement]: boolean;
};

export const initialValues: RegistrationForm = {
  [RegistrationFormFields.Name]: '',
  [RegistrationFormFields.Surname]: '',
  [RegistrationFormFields.Region]: '+972',
  [RegistrationFormFields.Number]: '',
  [RegistrationFormFields.Email]: '',
  [RegistrationFormFields.IdNumber]: '',
  [RegistrationFormFields.Password]: '',
  [RegistrationFormFields.Agreement]: false,
};

export const registrationFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [RegistrationFormFields.Name]: nameValidationSchema(t),
    [RegistrationFormFields.Surname]: nameValidationSchema(t),
    [RegistrationFormFields.Region]: Yup.string().trim(),
    [RegistrationFormFields.Number]: Yup.string()
      .trim()
      .required(t('validation.phoneRequired'))
      .test('validate-phone', t('validation.enterValidPhone'), function validatePhone(phone) {
        const code = this.parent[RegistrationFormFields.Region];
        if (code) {
          return isValidPhoneNumber(`${code} ${phone}`, getShortCountryByCode(code));
        }
        return false;
      }),
    [RegistrationFormFields.IdNumber]: Yup.string().matches(ID_NUMBER_REGEXP, t('validation.validIdNumber')),
    [RegistrationFormFields.Password]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });
