import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Caption, Language, SupportIcon, colors } from '@beauty/beauty-market-ui';
import { setSelectedLocale } from '../../api/api.helpers';
import { LOCALES } from '../../api/constants';
import { excludeFooterRouteList, excludeMenuRouteList } from '../../constants';
import { isRtl } from '../../helpers/rtl';
import { useMediaScreen } from '../../hooks';
import { RouterUrl } from '../../routes/routes';
import { useAppSelector } from '../../store/hooks';
import { isShowFooter } from '../../store/redux-slices/footerSlice';
import ExtendedFooter from '../ExtendedFooter/ExtendedFooter';
import { languageOptions } from './const';
import { BottomFooterWrapper, FooterContainer, FooterWrapper, MixFlex, StyledLink, StyledSeparator } from './style';

// TODO Add translations
const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { isDesktop } = useMediaScreen('md');
  const rtl = isRtl();

  const hideFooter = excludeFooterRouteList.find(route => location.pathname.includes(route));
  const mobileLanguagePlacement = rtl ? 'top-right' : 'top-left';

  const isHigher = !excludeMenuRouteList.find(route => location.pathname.includes(route));

  const isFooterVisible = useAppSelector(isShowFooter);
  if (!isFooterVisible) return null;

  return (
    <FooterWrapper isHigher={isHigher}>
      {!hideFooter && (
        <>
          <ExtendedFooter />
          <StyledSeparator />
        </>
      )}

      <FooterContainer>
        <BottomFooterWrapper>
          <MixFlex alignItems="start">
            <Caption>© 2023 Beautymarket.co.il</Caption>
            <StyledLink icon={<SupportIcon style={{ fill: 'none' }} />} href={RouterUrl.Support} size="s">
              <Caption color={colors.blue.standard}>{t('footer.support')}</Caption>
            </StyledLink>
          </MixFlex>
          <Language
            currentLanguage={i18n.resolvedLanguage}
            handleLanguageSelect={(language: string) => {
              i18n.changeLanguage(language);
              setSelectedLocale(LOCALES[language]);
            }}
            options={languageOptions(t)}
            placement={isDesktop ? 'top' : mobileLanguagePlacement}
            mode={isDesktop ? 'full' : 'minimal'}
          />
        </BottomFooterWrapper>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
