import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { tokenService } from '../../api';
import { RouterUrl } from '../../routes/routes';
import { CommonActions } from '../../store/actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectIsLogin } from '../../store/redux-slices/userSlice';

export const AuthGuard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLogin = useAppSelector(selectIsLogin);
  const token = tokenService.getAccessToken();

  useEffect(() => {
    if (!token) {
      isLogin && navigate(RouterUrl.Login);
      dispatch(CommonActions.logout());
    }
  }, [dispatch, navigate, token, isLogin]);

  return <Outlet />;
};
