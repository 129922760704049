import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { getNotifyContent } from '../helpers';
import { getErrorMessage } from '../helpers/notifyContent';
import { createAppAsyncThunk } from '../store/create-app-async-thunk';
import { useAppDispatch } from '../store/hooks';
import { UserActions, VisitAction } from '../types';
import { ErrorData } from '../types/api';

type RequestFunction<T, K> = (params: T) => Promise<K>;

export const useRequest = <ReturnValue, ThunkArguments>(
  request: ReturnType<typeof createAppAsyncThunk<ReturnValue, ThunkArguments>>,
  action: UserActions | VisitAction,
  hideNotifySuccess = false,
): RequestFunction<ThunkArguments, ReturnValue> => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notify = useNotify();

  return useCallback(
    (params: ThunkArguments) =>
      dispatch(request(params))
        .unwrap()
        .then((response: ReturnValue) => {
          if (!hideNotifySuccess) {
            const message = getNotifyContent(NotifyStatus.SUCCESS, action, t, ' ');
            if (message) notify(message);
          }
          return response;
        })
        .catch((err: ErrorData) => {
          const errorMessage = getErrorMessage(t, err.message);
          const showMessage = getNotifyContent(NotifyStatus.ERROR, action, t, errorMessage);
          if (showMessage) notify(showMessage);
          throw err;
        }),
    [request, action, hideNotifySuccess, dispatch, notify, t],
  );
};
