import { NavigateFunction } from 'react-router-dom';
import { RouterUrl } from '../routes/routes';
import { RedirectState } from '../types';

const getRedirectUrl = (state: RedirectState) => {
  const isRedirectedFromOrganisation = state.redirectedFrom === RouterUrl.Organisation;
  const isRedirectedFromProfile = state.redirectedFrom === RouterUrl.ClientProfile;

  if (isRedirectedFromOrganisation) return `${state.redirectedFrom}/${state.orgId}/${state.searchParams}`;
  if (isRedirectedFromProfile) return `${RouterUrl.ClientProfile}/${state.id}`;
  return state.redirectedFrom || RouterUrl.Homepage;
};

const getRedirectData = (state: RedirectState) => {
  const isRedirectedFromProfile = state.redirectedFrom === RouterUrl.ClientProfile;

  return {
    state: { isScroll: isRedirectedFromProfile },
  };
};

export const redirectAfterLogin = (navigate: NavigateFunction, state: RedirectState) => {
  navigate(getRedirectUrl(state), getRedirectData(state));
};
