import { AxiosError, AxiosResponse } from 'axios';
import { tokenService } from '../../tokenService';

export class AccessDenied {
  public resolve = (response: AxiosResponse) => {
    const currentToken = tokenService.getAccessToken();
    const newToken = response.headers.authorization;

    if (newToken && currentToken !== newToken) {
      tokenService.setAccessToken(newToken);
    }

    return response;
  };

  public reject = async (error: AxiosError) => {
    if (error?.response?.status === 403) {
      // window.location.href = RouterUrl.NotPermission;
    }
    if (error?.response?.status === 402) {
      // window.location.href = RouterUrl.Subscription;
    }
    if (error?.response?.status === 401) {
      tokenService.removeAccessToken();
      window.location.reload();
    }

    return Promise.reject(error);
  };
}
