import { t } from 'i18next';
import { Nullable } from 'tsdef';
import { Button } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components';
import { GeolocationType } from '../../../types';
import { SendEmailFormFields } from './components/Appointments.definitions';
import { TodoType, BodyButtonProps, FullStatus } from './types';

export type OrganisationInfo = {
  name: string;
  address: string[];
  icon: string;
  category: string;
  coordinates: Nullable<GeolocationType>;
};

export const popupBody = {
  cancel: {
    title: 'Are you sure you want to cancel appointment?',
    leftButton: 'no',
    rightButton: 'yes',
  },
  manage: {
    title: 'What do you want to change your appointment?',
    cancelled: {
      topButton: 'rebookAppointment',
    },
    confirmed: {
      topButton: 'rescheduleAppointment',
      bottomButton: 'addToCalendar',
    },
    past: {
      topButton: 'rebookAppointment',
      middleButton: 'writeReview',
      bottomButton: 'downloadReceipt',
    },
  },
};

export enum EventStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  PAST = 'PAST',
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  NOSHOW = 'NO_SHOW',
  UNCLOSED = 'UNCLOSED',
}

export const statusList: FullStatus[] = [
  FullStatus.ALL,
  FullStatus.CONFIRMED,
  FullStatus.PAST,
  FullStatus.WAITING,
  FullStatus.CANCELLED,
];
export const confirmedList = [EventStatus.CONFIRMED, EventStatus.PENDING, EventStatus.INPROGRESS];

export const popupInfo = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return t('appointments.cancelAppointment');
    case 'manage':
      return t('appointments.manageAppointment');
    default:
      return '';
  }
};

export const bottomSheetLabel = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return `${t('docs.cancel')} ${t('appointments.appointment')}`;
    case 'manage':
      return `${t('docs.manage')} ${t('appointments.appointment')}`;
    case 'send':
      return t(`appointments.${popupBody.manage.past.bottomButton}`);
    default:
      return '';
  }
};

export const bottomSheetDescriptor = (whatToDo: Nullable<TodoType>) => {
  switch (whatToDo) {
    case 'cancel':
      return t('appointments.cancelAppointment');
    case 'manage':
      return t('appointments.manageAppointment');
    case 'send':
      return t(`appointments.${popupBody.manage.past.bottomButton}`);
    default:
      return '';
  }
};

export const BodyButton = ({ label, onClick, disabled = false, ...other }: BodyButtonProps) => (
  <Button disabled={disabled} width="100%" size="large" design="quaternary" mb="16px" onClick={onClick} {...other}>
    {label}
  </Button>
);

export const SendEmailInput = () => (
  <FormikInput
    design="white"
    id={SendEmailFormFields.Email}
    name={SendEmailFormFields.Email}
    placeholder={t('profile.email')}
    defaultValue=""
    mb="16px"
  />
);

export const modifiedStatus = status => {
  switch (status) {
    case FullStatus.CANCELLED:
    case FullStatus.NOSHOW:
      return FullStatus.CANCELLED;
    case FullStatus.PAST:
    case FullStatus.UNCLOSED:
      return FullStatus.PAST;
    case FullStatus.WAITING:
    case FullStatus.ALL:
      return status;
    default:
      return FullStatus.CONFIRMED;
  }
};
