import { useCallback, useEffect } from 'react';
import { getSelectedLanguage } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { currentTimeslot, setTimeslot } from '../../../../store/redux-slices/bookingSlice';
import { AvailableTimeslotType } from '../../../../types/timeslot';
import { formatDateForTimeslot } from './helpers';

export const useTimeslotDay = (day: string) => {
  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();

  const selectedTimeslot = useAppSelector(currentTimeslot);

  const onClickTimeSlot = useCallback((slot: AvailableTimeslotType) => {
    dispatch(setTimeslot(slot));
  }, []);

  const date = formatDateForTimeslot(day, language);

  useEffect(
    () => () => {
      dispatch(setTimeslot(null));
    },
    [],
  );

  return { selectedTimeslot, onClickTimeSlot, date };
};
