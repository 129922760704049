import React from 'react';
import { Caption, GoogleIcon } from '@beauty/beauty-market-ui';
import { useGoogleAuth } from '../../../hooks';
import { IconWithTextWrapper, IconWrapper } from '../style';

interface GoogleButtonProps {
  text?: string;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ text }) => {
  const { login } = useGoogleAuth();

  return text ? (
    <IconWithTextWrapper onClick={login}>
      <GoogleIcon />
      <Caption>{text}</Caption>
    </IconWithTextWrapper>
  ) : (
    <IconWrapper onClick={login}>
      <GoogleIcon />
    </IconWrapper>
  );
};

export default GoogleButton;
