import styled, { css } from 'styled-components';
import {
  BodySmall,
  Flex,
  TelegramMonoIcon,
  colors,
  Button,
  mediaQueries,
  RightIcon,
  Icon,
  H6,
} from '@beauty/beauty-market-ui';

export const hebrewTruncated = (isHebrew, truncated) => css`
  [dir='rtl'] & {
    ${!isHebrew &&
    truncated &&
    css`
      direction: ltr;
      text-align: end;
    `};
  }
`;

export const StyledBodySmall = styled(BodySmall)`
  @media screen and (max-width: 576px) {
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const NotificationItemWrapper = styled(Flex)`
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  ${mediaQueries.md} {
    padding: 16px;
  }
  box-sizing: border-box;
  gap: 8px;

  label {
    min-width: 40px;
  }
`;

export const PopupWrapper = styled(Flex)`
  & > div:first-child > div:first-child > div:first-child {
    padding-bottom: 0;
  }
`;

export const TelegramIcon = styled(TelegramMonoIcon)`
  path {
    stroke: none !important;
    fill: ${colors.white.standard};
  }
`;

export const StyledButton = styled(Button)<{ isRed: boolean }>`
  ${({ isRed }) =>
    isRed &&
    css`
      background-color: ${colors.red.standard}!important;
      border: none !important;
    `}
`;

export const StyledArrow = styled(RightIcon)`
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

export const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 0;
  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }
`;

export const StyledH6 = styled(H6)<{ isHebrew: boolean }>`
  margin-bottom: 4px;
  max-width: calc(100vw - 16px);
  ${({ isHebrew, truncated }) => hebrewTruncated(isHebrew, truncated)};
`;
