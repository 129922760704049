import styled from 'styled-components';
import { Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: 24px;
  width: 100%;

  ${mediaQueries.md} {
    gap: 40px;
  }
`;

export const SpecialistWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  ${mediaQueries.md} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
  }
`;
