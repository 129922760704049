import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { has } from 'lodash';
import { ResponseOrganisation } from '../../page/Organisation/types';
import { ServiceType } from '../../types';
import { OrgSpecialistType, SpecialistType } from '../../types/specialist';
import { CommonActions } from '../actions';
import { RootState } from '../store';

export interface OrganisationsState {
  organisation: ResponseOrganisation | null;
  selectedService: ServiceType | null;
  isShowSearch: boolean;
  isMap: boolean;
}

const initialState: OrganisationsState = {
  organisation: null,
  selectedService: null,
  isShowSearch: true,
  isMap: false,
};

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    setOrganisation: (state, action: PayloadAction<{ organisation: any }>) => ({
      ...state,
      organisation: action.payload.organisation,
    }),

    removeOrganisation: () => initialState,

    setSpecialists: (state, action: PayloadAction<{ specialists: OrgSpecialistType[] }>) => {
      if (!state.organisation) return state;

      let organisation = { ...state.organisation };

      if (organisation) {
        organisation = { ...organisation, orgSpecialist: action.payload.specialists };
      }

      return { ...state, organisation };
    },

    addSpecialist: (state, action: PayloadAction<{ specialist: SpecialistType }>) => {
      if (!state.organisation || !has(state.organisation, 'team')) return state;

      const existingMember = state.organisation.team.some(member => member.id === action.payload.specialist.id);

      if (existingMember) return state;

      return {
        ...state,
        organisation: {
          ...state.organisation,
          team: [...state.organisation.team, { ...action.payload.specialist }],
        },
      };
    },

    setSelectedService: (state, action: PayloadAction<ServiceType>) => ({
      ...state,
      selectedService: action.payload,
    }),

    setShowSearch: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isShowSearch: action.payload,
    }),

    setMap: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isMap: action.payload,
    }),
  },
  extraReducers: builder => builder.addCase(CommonActions.logout, () => initialState),
});

export const {
  setOrganisation,
  removeOrganisation,
  setSpecialists,
  addSpecialist,
  setSelectedService,
  setShowSearch,
  setMap,
} = organisationSlice.actions;

export const selectOrganisation = (state: RootState) => state.organisation;

export default organisationSlice.reducer;
