import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { H4, Button } from '@beauty/beauty-market-ui';
import LogoutImage from '../../assets/exit-image.png';
import { RouterUrl } from '../../routes/routes';
import { CommonActions } from '../../store/actions';
import { ServiceUnavailableWrapper } from './style';

const ServiceUnavailable = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onResetApp = () => {
    // location.reload is used cause of create-app scripts.
    // We need to refresh whole page to be able to display it, otherwise error-overlay won't let us do it
    dispatch(CommonActions.logout());
    navigate(RouterUrl.Login);
    window.location.reload();
  };

  return (
    <ServiceUnavailableWrapper>
      <img src={LogoutImage} alt={t('logout.altImg')} />
      <H4>{t('error.serviceUnavailable')}</H4>
      <Button design="primary" size="large" mt="32px" onClick={onResetApp}>
        {t('error.returnHome')}
      </Button>
    </ServiceUnavailableWrapper>
  );
};

export default ServiceUnavailable;
