import { useCallback, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Nullable } from 'tsdef';

export const useMemoizedState = <T>(initialState: T) => {
  const prevStateRef = useRef<Nullable<T>>(null);

  const [state, setState] = useState<T>(initialState);

  const updateState = useCallback((newValue: T) => {
    if (!isEqual(newValue, prevStateRef.current)) {
      setState(newValue);
      prevStateRef.current = newValue;
    }
  }, []);

  return [state, updateState] as const;
};
