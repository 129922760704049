import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { Nullable } from 'tsdef';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { RatingSidebarProps } from '../../../components/RatingSidebar/RatingSidebar';
import { getSelectedLanguage } from '../../../constants';
import { cancelAppointment, getAppointmentNotifyContent, getFullDate, getTranslation } from '../../../helpers';
import { useGetOrganisation } from '../../../hooks/useGetOrganisation';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setAppointmentStatus } from '../../../store/redux-slices/appointmentsSlice';
import { selectOrganisation } from '../../../store/redux-slices/organisationSlice';
import { updateSelectedAppointment } from '../../../store/redux-slices/userSlice';
import { AppointmentActions } from '../../../types/appointment';
import { getFormattedCurrency } from '../../Organisation/helpers';
import { EventStatus } from './constants';

export const useEventInfo = event => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const { organisation } = useAppSelector(selectOrganisation);

  const dispatch = useAppDispatch();
  const notify = useNotify();
  const navigate = useNavigate();

  const [appointmentDetails, setAppointmentDetails] = useState<Nullable<RatingSidebarProps>>(null);

  const { orgId } = event.orgService;
  const { organisation: organisationFromBE } = useGetOrganisation(orgId);
  const { categoryId } = event.orgService.headOrgService;
  const serviceId = event.orgServId;

  const { city, street, building, office, postal } = event.orgService.organization.address.address[0];
  const address = [city, street, building, office, postal];
  const orgAddress = organisation?.address.address[0];

  const service = organisationFromBE
    ? organisationFromBE.offers.categories
        .find(category => category.id === categoryId)
        ?.services.find(item => item.id === serviceId)
    : null;
  const specialist = useMemo(
    () => organisation?.orgSpecialist.find(spec => spec.orgSpecId === event.orgSpecId),
    [organisation, event],
  );

  const priceAmount = event.orgService.price;
  const totalAmount = priceAmount !== null ? priceAmount - Number(event?.discountAmount ?? '0') : priceAmount;
  const isDiscount = priceAmount && priceAmount !== totalAmount;

  const getPriceText = (price: Nullable<number>) =>
    price !== null
      ? getFormattedCurrency(price, organisation?.headOrganization?.currency, language)
      : t('organisation.booking.nullPrice');

  const servicePrice = getPriceText(priceAmount);
  const totalPrice = getPriceText(totalAmount);

  const organisationInfo = {
    address,
    icon: organisation?.mainPhoto?.url || '',
    category: uniq(organisation?.category.map(cat => getTranslation(cat.parent?.title, language))).join(', ') || '',
    name: event.orgService.organization.orgName
      ? getTranslation(event.orgService.organization.orgName, language)
      : event.orgService.organization.name,
    coordinates: orgAddress ? { lat: orgAddress.lat, lng: orgAddress.lng } : null,
  };

  const handleCancelAppointment = async () => {
    const { id, clientVisitId } = event;

    const response = await cancelAppointment(id, clientVisitId);
    if (response?.status === 200) {
      notify(getAppointmentNotifyContent(NotifyStatus.SUCCESS, AppointmentActions.Cancel, t));
      dispatch(setAppointmentStatus({ id: event.id, status: EventStatus.CANCELLED }));
    } else {
      notify(getAppointmentNotifyContent(NotifyStatus.ERROR, AppointmentActions.Cancel, t));
    }
  };

  const handleRebookAppointment = () => {
    dispatch(updateSelectedAppointment(event.id));
    navigate(`${RouterUrl.Booking}/${orgId}/${service?.id}`);
  };

  useEffect(() => {
    const { name, address: eventOrgAddress } = event.orgService.organization;
    setAppointmentDetails({
      appointmentId: event.id,
      date: getFullDate(event.start, t, language),
      address: {
        orgName: name,
        info: eventOrgAddress.address[0].fullAddress,
        url: organisation?.mainPhoto?.url || '',
      },
      specialist: specialist
        ? {
            orgSpecId: '',
            name: specialist ? `${specialist.name} ${specialist.surname}` : '',
            surname: '',
            avatarUrl: specialist.avatarUrl,
            specialization: specialist.specialization,
            rating: specialist.rating,
          }
        : undefined,
    });
  }, [event.id, organisation, language]);

  return {
    organisationInfo,
    specialist,
    appointmentDetails,
    isDiscount,
    price: { servicePrice, totalPrice },
    handleCancelAppointment,
    handleRebookAppointment,
  };
};
