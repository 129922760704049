import { Endpoints } from '../api.types';

export enum Directories {
  User = 'account',
  Auth = 'auth',
  Services = 'services',
  Client = 'client',
  Organisation = 'organization',
  Appointment = 'appointment',
  Email = 'email',
  Specialist = 'specialist',
  Search = 'search',
  Notification = 'notification',
  Service = 'service',
  Review = 'review',
  Rating = 'rating',
  Subscription = 'subscription',
  Password = 'password',
  HeadOrganisation = 'head-organization',
  GoogleAuth = 'google-auth',
  Visit = 'client-visit',
  Payment = 'payment',
}

export enum UserEndpoints {
  Root = 'user',
  SendMessage = 'sendMessage',
  JoinUs = 'joinUs',
  AvatarUpload = 'uploadProfilePhoto', // TODO Review after BE is ready
  NotificationUpdate = 'updateNotification', // TODO Review after BE is ready
  Appointments = 'appointments',
  CreateAppointment = 'createAppointment',
  SendToSupport = 'sendToSupport',
  Email = 'email',
  Phone = 'phone',
  DayCount = 'dayCount',
  Password = 'password',
  Favourites = 'favourites',
}

export enum OrganisationEndpoints {
  Organisation = 'organisation',
  OrganisationSpecialists = 'organisationSpecialists',
  OrganisationReviews = 'organisationReviews',
  OrganisationServiceDetails = 'organisationServiceDetails',
  Timeslots = 'timeslots',
  AvailableTimeslots = 'available-timeslots',
  Organisations = 'organisations',
  Specialists = 'specialists',
  Services = 'services',
  Photos = 'photos',
  DateSlots = 'dateSlots',
}

export enum HeadOrganisationEndpoints {
  SubscriptionPlans = 'subscriptionPlans',
  PublishedOrganisations = 'publishedOrganisations',
}

export enum AuthEndpoints {
  Root = 'signup',
  LoginByPhone = 'loginByPhone',
  LoginPhoneVerification = 'loginPhoneVerification',
  LoginByEmail = 'loginByEmail',
  EmailVerification = 'emailVerification',
  UpdatePassword = 'updatePassword',
  ResetPassword = 'resetPassword',
  SignupPhoneVerification = 'signupPhoneVerification',
  Logout = 'logout',
  InviteUser = 'inviteUser',
}

export enum ServicesEndpoints {
  Service = 'service',
  TopCategories = 'topCategories',
  Categories = 'categories',
  Appointment = 'appointment',
  CancelAppointment = 'cancelAppointment',
}

export enum NotificationEndpoints {
  NotificationTypes = 'notificationTypes',
  Notifications = 'notifications',
  Change = 'change',
  ViewAll = 'viewAll',
  Telegram = 'telegram',
}

export enum SpecialistEndpoints {
  Root = 'specialist',
  PersonalInformation = 'specialistPersonalInformation',
}

export enum SearchEndpoints {
  Root = 'search',
}

export enum ReviewEndpoints {
  OrganisationRating = 'organisationRating',
  OrganisationReview = 'organisationReview',
  SpecialistRating = 'specialistRating',
  SpecialistReview = 'specialistReview',
  Badges = 'badges',
  Appointment = 'appointmentReview',
}

export enum GoogleEndpoints {
  AuthAccount = 'authAccount',
}

export enum VisitEndpoints {
  RemoveAppointment = 'removeAppointment',
}

export enum DefaultEndpoints {
  Receipt = 'payment-receipt',
}

export const endpointsWhitelist = [UserEndpoints]; // used to verify where should send access token

export const endpoints: Endpoints = {
  [UserEndpoints.Root]: ({ id, queryParams }) =>
    `/${Directories.User}${id ? `/${id}` : ''}${queryParams ? `?${queryParams}` : ''}`,
  [UserEndpoints.SendMessage]: () => `/${Directories.User}/send-message`,
  [UserEndpoints.JoinUs]: () => `/${Directories.Notification}/${Directories.Email}/joinus`,
  [UserEndpoints.AvatarUpload]: (id: string) => `/${Directories.User}/${id}/avatar`,
  [UserEndpoints.NotificationUpdate]: ({ profileId, notificationId }) =>
    `/${Directories.User}/${profileId}/notification/${notificationId}`,
  [UserEndpoints.CreateAppointment]: () => `/${Directories.Appointment}`,
  [UserEndpoints.Appointments]: (id: string) => `/${Directories.Organisation}/${Directories.Client}/${id}/appointment`,
  [UserEndpoints.SendToSupport]: () => `/${Directories.Notification}/${Directories.Email}/support`,
  [UserEndpoints.Email]: () => `/${Directories.User}/email`,
  [UserEndpoints.Phone]: () => `/${Directories.User}/phone`,
  [UserEndpoints.DayCount]: () => `/${Directories.Appointment}/day-count`,
  [UserEndpoints.Password]: (id: string) => `/${Directories.User}/${Directories.Password}/${id}`,
  [UserEndpoints.Favourites]: ({ id, queryParams }: { id: string; queryParams?: string }) =>
    `/${Directories.User}/${id}/favourites${queryParams ? `?${queryParams}` : ''}`,

  /** Autorization endpoints * */
  [AuthEndpoints.Root]: (id?: string) => `/${Directories.Auth}/signup${id ? `/${id}` : ''}`,
  [AuthEndpoints.LoginByPhone]: () => `/${Directories.Auth}/login-phone`,
  [AuthEndpoints.LoginByEmail]: () => `/${Directories.Auth}/login-email`,
  [AuthEndpoints.LoginPhoneVerification]: () => `/${Directories.Auth}/login-phone-verification`,
  [AuthEndpoints.EmailVerification]: () => `/${Directories.Auth}/email-verification`,
  [AuthEndpoints.UpdatePassword]: () => `/${Directories.Auth}/update-password`,
  [AuthEndpoints.ResetPassword]: () => `/${Directories.Auth}/reset-password`,
  [AuthEndpoints.SignupPhoneVerification]: () => `/${Directories.Auth}/signup-phone-verification`,
  [AuthEndpoints.Logout]: () => `/${Directories.Auth}/logout`,
  [AuthEndpoints.InviteUser]: (hash: string) => `/${Directories.Auth}/client-complete-registration/${hash}`,

  /** Service endpoints * */
  [ServicesEndpoints.TopCategories]: () => `/service-category/top`,
  [ServicesEndpoints.Categories]: () => `/service-category`,
  [ServicesEndpoints.Appointment]: (id: string) => `/${Directories.Appointment}/${id}`,
  [ServicesEndpoints.CancelAppointment]: ({ clientVisitId, appointmentId }) =>
    `client-visit/${clientVisitId}/appointment/${appointmentId}`,
  [ServicesEndpoints.Service]: (id: string) => `/${Directories.Organisation}/${Directories.Service}/${id}`,

  /** Organisation endpoints * */
  [OrganisationEndpoints.Organisation]: ({ orgId, queryParams }) =>
    `/${Directories.Organisation}/${orgId}/test${queryParams}`,
  [OrganisationEndpoints.OrganisationSpecialists]: (orgId: string) =>
    `/${Directories.Organisation}/${orgId}/specialists`,
  [OrganisationEndpoints.OrganisationReviews]: (orgId: string) => `/${Directories.Organisation}/${orgId}/reviews`,
  [OrganisationEndpoints.OrganisationServiceDetails]: ({ orgId, serviceId }) =>
    `/${Directories.Organisation}/${orgId}/services/${serviceId}`,

  [OrganisationEndpoints.Timeslots]: ({ serviceId, queryParams }) =>
    `/${Directories.Organisation}/service/${serviceId}/timeslots${queryParams}`,
  [OrganisationEndpoints.AvailableTimeslots]: ({ serviceId, queryParams }) =>
    `/${Directories.Organisation}/service/${serviceId}/available-timeslots${queryParams}`,
  [OrganisationEndpoints.Organisations]: (topCategory?: string) =>
    `/${Directories.Organisation}/category/${topCategory ? `?query=${topCategory}` : ''}`,
  [OrganisationEndpoints.Specialists]: ({ orgId, queryParams }) =>
    `/${Directories.Organisation}/${orgId}/test${queryParams}`,
  [OrganisationEndpoints.Services]: (orgId: string) => `/${Directories.Organisation}/${orgId}/service`,
  [OrganisationEndpoints.DateSlots]: ({ serviceId, queryParams }) =>
    `/${Directories.Organisation}/service/${serviceId}/dateslots${queryParams}`,

  // this endpoint is for all services photos of organisation
  [OrganisationEndpoints.Photos]: (orgId: string) => `/${Directories.Organisation}/${orgId}/service/photo`,

  [NotificationEndpoints.NotificationTypes]: () => '/account-notification-type/CLIENT',
  [NotificationEndpoints.Notifications]: ({ profileId, isArchive }) =>
    `/${Directories.Notification}/${Directories.User}/${profileId}${isArchive ? '/archive' : ''}`,
  [NotificationEndpoints.Change]: (notificationId: string) => `/${Directories.Notification}/view/${notificationId}`,
  [NotificationEndpoints.ViewAll]: (profileId: string) => `/${Directories.Notification}/view-all/${profileId}`,
  [NotificationEndpoints.Telegram]: (profileId: string) => `/${Directories.Notification}/telegram/${profileId}`,

  /** Specialist endpoints */
  [SpecialistEndpoints.Root]: ({ id, queryParams }) => `/${Directories.Specialist}${id}${queryParams}`,
  [SpecialistEndpoints.PersonalInformation]: (id: string) => `${Directories.Specialist}/personal-information/${id}`,

  /** Search endpoints */
  [SearchEndpoints.Root]: (queryParams?: string) =>
    `/${Directories.Search}${queryParams ? `?query=${queryParams}` : ``}`,

  /** Rating and review endpoints * */
  [ReviewEndpoints.OrganisationRating]: (id: string) =>
    `/${Directories.Review}/${Directories.Organisation}/${Directories.Rating}/${id}`,
  [ReviewEndpoints.OrganisationReview]: ({ id, queryParams }) =>
    `/${Directories.Review}/${Directories.Organisation}/${id}${queryParams}`,
  [ReviewEndpoints.SpecialistReview]: ({ id, queryParams }) =>
    `/${Directories.Review}/${Directories.Specialist}/${id}${queryParams}`,
  [ReviewEndpoints.SpecialistRating]: (id: string) =>
    `/${Directories.Review}/${Directories.Specialist}/${Directories.Rating}/${id}`,

  [ReviewEndpoints.Badges]: (queryParams: string) => `/${Directories.Review}/badges/${queryParams}`,
  [ReviewEndpoints.Appointment]: () => `/${Directories.Review}/by-appointment`,

  /** HeadOrganisation endpoints * */
  [HeadOrganisationEndpoints.SubscriptionPlans]: () => `/${Directories.Subscription}/plans`,
  [HeadOrganisationEndpoints.PublishedOrganisations]: (id: string) =>
    `/${Directories.HeadOrganisation}/${id}/published-organization`,

  /** Google Auth endpoints * */
  [GoogleEndpoints.AuthAccount]: () => `/${Directories.GoogleAuth}/account`,

  [VisitEndpoints.RemoveAppointment]: ({ id, appointmentId }) =>
    `${Directories.Visit}/${id}/appointment/${appointmentId}`,

  /** Default endpoints */
  [DefaultEndpoints.Receipt]: id => `/${Directories.Payment}/${id}/receipt/pdf`,
};
