import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { BodyLarge, H3, ShowMore } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers';
import { aboutProps } from '../constants';
import { AboutAdvantages, AboutAdvantagesItem, AboutWrapper, Description } from '../style';
import { Organisation } from '../types';
import Offerings from './Offerings';
import PhotoGallery from './PhotoGallery';
import Team from './Team';

interface AboutProps {
  organisation: Organisation;
}

const About = ({ organisation }: AboutProps) => {
  const { t } = useTranslation();

  const { property, description, orgDescr, team, offers, organisationName, orgId, photo } = {
    ...aboutProps(organisation),
  };

  const language = getSelectedLanguage();

  return (
    <AboutWrapper>
      <H3>{t('organisation.about.about')}</H3>
      {!isEmpty(property) && (
        <AboutAdvantages>
          {property.map(prop => (
            <AboutAdvantagesItem key={prop.id}>
              <img src={prop.iconUrl} alt={prop.title} />
              <BodyLarge large>{prop.title}</BodyLarge>
            </AboutAdvantagesItem>
          ))}
        </AboutAdvantages>
      )}
      {!isEmpty(orgDescr) && (
        <Description>
          <ShowMore
            text={orgDescr ? getTranslation(orgDescr, language) : description}
            visibleLines={3}
            showMore={t('organisation.about.showMore')}
            showLess={t('organisation.about.showLess')}
          />
        </Description>
      )}
      {!!photo?.length && <PhotoGallery photos={photo} />}
      <Offerings {...offers} organisation={organisation} />
      {!!team?.length && <Team team={team} orgId={orgId} orgName={organisationName} />}
    </AboutWrapper>
  );
};

export default About;
