import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedLanguage } from '../constants';
import { fetchOrganisationDetails, fetchSpecialistsDetails } from '../helpers/organisation';
import { mapOrganisation } from '../page/Organisation/helpers';
import { Organisation } from '../page/Organisation/types';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setOrganisation, selectOrganisation, setSpecialists } from '../store/redux-slices/organisationSlice';
import { selectUser, updateBooking } from '../store/redux-slices/userSlice';

export const useGetOrganisation = (orgId: string, isBooking?: boolean) => {
  const { t } = useTranslation();
  const selectedLanguage = getSelectedLanguage();

  const dispatch = useAppDispatch();
  const { organisation } = useAppSelector(selectOrganisation);
  const { isLogin } = useAppSelector(selectUser);

  const [mappedOrganisation, setMappedOrganisation] = useState<Organisation | null>();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchOrganisation = async () => {
      setLoading(true);

      try {
        const orgDetails = await fetchOrganisationDetails(orgId, abortController.signal);

        !abortController.signal.aborted &&
          dispatch(
            setOrganisation({
              organisation: orgDetails,
            }),
          );
      } finally {
        setLoading(false);
      }
    };

    const fetchSpecialists = async () => {
      const result = await fetchSpecialistsDetails(orgId, abortController.signal);
      !abortController.signal.aborted && dispatch(setSpecialists({ specialists: result.orgSpecialist }));
    };

    fetchOrganisation();
    fetchSpecialists();
    !isBooking &&
      dispatch(
        updateBooking({
          timeslot: null,
          specialist: null,
        }),
      );

    return () => {
      abortController.abort();
    };
  }, [orgId, isBooking]);

  useEffect(() => {
    organisation && setMappedOrganisation(mapOrganisation(organisation, selectedLanguage, t, isLogin));
  }, [organisation, selectedLanguage, t, isLogin]);

  return {
    isLoading,
    organisation: mappedOrganisation,
  };
};
