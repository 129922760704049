import { Map, MapsLibrary } from 'google-maps-react-markers';
import { Div, Button, ArrowLeftIcon, ArrowRightIcon, BodySmall } from '@beauty/beauty-market-ui';
import { createMapElement } from '../../../../components/CustomGoogleMap';
import { MapButtonsType } from '../../../../types';
import { ArrowWrapper, LabelButton } from './style';

export const getSidebarHandleBtn = ({
  isVisible,
  onClick,
  label,
  isVisibleLeftBlock = false,
  theme,
}: MapButtonsType) => {
  if (!isVisibleLeftBlock) {
    return null;
  }

  return createMapElement(
    isVisible ? (
      <ArrowWrapper>
        <Button p="10px !important" size="small" design="withShadow" onClick={() => onClick && onClick(false)}>
          <ArrowLeftIcon />
        </Button>
      </ArrowWrapper>
    ) : (
      <Div mx="24px" mt="40px">
        <LabelButton
          size="small"
          design="withShadow"
          suffix={<ArrowRightIcon width="20" height="20" />}
          onClick={() => onClick && onClick(true)}
        >
          <BodySmall>{label}</BodySmall>
        </LabelButton>
      </Div>
    ),
    theme,
  );
};

export const clearMapButtons = (mapRef: Map, mapsControlsRef: MapsLibrary, isShowSearch: boolean, rtl: boolean) => {
  isShowSearch &&
    mapRef.controls[rtl ? mapsControlsRef.ControlPosition.RIGHT_TOP : mapsControlsRef.ControlPosition.LEFT_TOP].clear();
};
