import { AppointmentType } from '../../../types';

export type TodoType = 'cancel' | 'manage' | 'rebook' | 'send';

export enum FullStatus {
  ALL = 'ALL',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  PAST = 'PAST',
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  NOSHOW = 'NO_SHOW',
  UNCLOSED = 'UNCLOSED',
}

export type BodyButtonProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};

export type PopupBlockProps = {
  event: AppointmentType;
  whatToDo: TodoType | null;
  isSendPopup: boolean;
  setIsPopup: (isPopup: boolean) => void;
  setIsSendPopup: (isSendPopup: boolean) => void;
  onCancelAppointment: () => void;
  onRebookAppointment: () => void;
  onAddToCalendar: () => void;
  onWriteReview: () => void;
  onSendReceipt: () => void;
};
