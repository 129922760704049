import styled, { css } from 'styled-components';
import {
  H1,
  H2,
  H7,
  Caption,
  Flex,
  Button,
  Display,
  colors,
  mediaQueries,
  Div,
  getMaxWidthMediaQuery,
  Slider,
  ReviewCard,
} from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';
import { bubbleHeight, bubbles, bubbleWidth, croppedMultiLineText, titleShadow } from '../../style';

const titleWrapper = css`
  h1,
  h2 {
    ${titleShadow}
  }
`;

export const HomeWrapper = styled(Flex)`
  width: 1060px;
  ${mediaQueries.md} {
    margin: auto;
  }

  @media (max-width: 1060px) {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
    box-sizing: border-box;
  }

  transition: all 0.1s ease-in;
`;

export const StyledFlex = styled(Flex)`
  box-sizing: border-box;
`;

export const ColumnFlex = styled(StyledFlex)`
  flex-direction: column;
`;

export const SearchWrapper = styled(ColumnFlex)`
  position: relative;
  padding: 0px;
  padding-top: 80px;
  padding-bottom: 32px;

  ${mediaQueries.md} {
    padding-top: 160px;
    align-items: center;
    margin-top: -80px;
    padding-bottom: 40px;
  }

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};
    top: -300px;
    left: calc((100vw - ${`${bubbleWidth}px`}) / 2);
    ${bubbles};
    width: 1200px;
    height: 1200px;
    left: calc((100% - 250px - ${`${bubbleWidth}px`}) / 2);
    top: calc((100% - 250px - ${`${bubbleHeight}px`}) / 2);

    ${mediaQueries.md} {
      ${bubbles};
      left: calc((100% - ${`${bubbleWidth}px`}) / 2);
      top: -200px;
    }
  }
`;

export const SearchInputWrapper = styled(ColumnFlex)`
  z-index: ${zIndex.searchInputWrapper} !important;
  width: 100%;
  padding: 0px;
  padding-bottom: 32px;

  ${mediaQueries.md} {
    width: 620px;
    align-items: center;
    padding-bottom: 40px;
    position: static;
    top: 0px;
  }
`;

export const MobilePreviewWrapper = styled(Flex)`
  margin-bottom: 56px;
  position: relative;
  flex-direction: column-reverse;
  align-items: center;
  gap: 41px;
  ${mediaQueries.md} {
    flex-direction: row;
    margin-bottom: 80px;
  }
`;

export const LeftWrapper = styled(Flex)`
  position: relative;
  padding: 42px 0px 42px;
  gap: 24px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${titleWrapper};

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};
    ${bubbles};
    width: 1300px;
    height: 1300px;
    left: calc((100% - 350px - ${`${bubbleWidth}px`}) / 2);
    top: calc((100% - 350px - ${`${bubbleHeight}px`}) / 2);
    background-size: 100%;

    ${mediaQueries.md} {
      ${bubbles};
      left: calc((100% - ${`${bubbleWidth}px`} + 90px) / 2);
      top: calc((100% - ${`${bubbleHeight}px`}) / 2);
    }
  }
  ${mediaQueries.md} {
    width: 400px;
  }
  * {
    color: ${colors.white.standard}!important;
  }
`;

export const MobilePreviewImage = styled(Flex)`
  justify-content: center;
  width: -webkit-fill-available;

  img {
    position: relative;
    width: 100%;
    left: -16px;

    ${mediaQueries.md} {
      max-width: 619px;
    }
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: ${colors.black.standard}!important;
  font-weight: 500 !important;
  border: none !important;

  ${mediaQueries.sm} {
    width: fit-content;
  }
`;

export const StyledDisplay = styled(Display)`
  text-align: left;
  color: ${colors.white.standard}!important;
  margin-bottom: 40px;
  margin-top: 80px;
  width: 100%;
  text-align: center;
  ${titleShadow};
  letter-spacing: -2.4px;

  ${mediaQueries.md} {
    width: 620px;
  }
`;

export const PromoInfo = styled(StyledFlex)`
  flex-direction: column;
  ${mediaQueries.sm} {
    flex-direction: row;
  }
`;

export const OrderSectionWrapper = styled(ColumnFlex)`
  position: relative;
  gap: 40px;
  ${titleWrapper};

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};
    top: -300px;
    left: calc((100% - ${`${bubbleWidth}px`}) / 2);
    ${bubbles};
    background-size: auto;

    ${mediaQueries.md} {
      background-size: 110%;
    }
  }
  ${mediaQueries.md} {
    gap: 56px;
  }
`;

export const CRMWrapper = styled(Flex)`
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 136px;
  padding-bottom: 55px;
  gap: 41px;

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};
    left: calc((100% - 200px - ${`${bubbleWidth}px`}) / 2);
    ${bubbles};
    width: 1150px;
    height: 1150px;
    background-size: 110%;
    ${mediaQueries.sm} {
      top: 0px;
    }
  }

  ${mediaQueries.md} {
    flex-direction: row;
    padding-bottom: 0px;

    &:before {
      width: 0px;
      height: 0px;
    }
  }
`;

export const CRMRightWrapper = styled(ColumnFlex)`
  position: relative;
  right: 0;
  overflow-y: visible;
  width: 100%;
  align-items: center;
  ${titleWrapper};

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};
    left: calc((100% - ${`${bubbleWidth}px`}) / 2);
    top: -500px;
    background-size: 70%;

    ${mediaQueries.md} {
      ${bubbles};
      left: -66%;
      top: -110%;
      background-size: 99%;
    }
  }

  ${mediaQueries.md} {
    width: 38%;
  }
`;

export const CRMImage = styled(Flex)`
  justify-content: center;
  width: 100%;
  img {
    width: 100%;
  }
  ${mediaQueries.md} {
    width: 62%;
    img {
      min-width: 300px;
      justify-content: flex-start;
    }
  }
`;

export const CRMMobileTitle = styled(H1)`
  color: ${colors.white.standard} !important;
  text-align: center;
`;

export const CRMDesktopTitle = styled(H2)`
  color: ${colors.white.standard} !important;
  display: inline;
  text-align: center;
  max-width: 25rem;
`;

export const CRMSubTitle = styled(Caption)`
  color: ${colors.white.standard} !important;
  text-align: start;

  ${mediaQueries.md} {
    display: inline;
    text-align: center;
  }
`;

export const Description = styled(H7)`
  color: ${colors.white.standard} !important;
  text-align: center;
  line-height: 22px !important;

  ${mediaQueries.md} {
    display: inline;
    /* width: 28rem; */
    line-height: 20px !important;
  }
`;

export const StyledH7 = styled(H7)`
  line-height: 22px !important;

  ${mediaQueries.md} {
    line-height: 20px !important;
  }
`;

export const CRMButton = styled(Button)`
  width: 100%;
  background-color: ${colors.black.standard}!important;
  font-weight: 500 !important;
  border: none !important;
  margin-top: 8px !important;

  ${mediaQueries.md} {
    width: fit-content;
    height: 48px;
    margin-top: 16px !important;
  }
`;

export const AllOffersWrapper = styled(Div)<{ isWide: boolean }>`
  ${({ isWide, theme }) =>
    isWide &&
    css`
      display: flex;
      flex-direction: column;
      height: 100%;

      ${theme.mediaQueries.md} {
        margin-top: 0;
      }
    `}
`;

export const AllOffersWrapperContainer = styled(Div)`
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  box-sizing: border-box;
`;

export const AllOffersContainer = styled(Div)`
  @media screen and (max-width: 648px) {
    width: max-content;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
  }
`;

export const AllOffersHeader = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    margin-top: 24px;
  }

  @media screen and (min-width: 649px) {
    flex-direction: row;
  }

  @media screen and (max-width: 648px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const AllOffersButtons = styled(Flex)`
  width: 100%;
  max-width: 325px;
  gap: 0.5rem;

  @media screen and (max-width: 648px) {
    max-width: 100%;
  }
`;

export const OffersGrid = styled(Div)`
  width: 100%;

  & > div {
    width: 100% !important;
    aspect-ratio: 1.56;
    p,
    h4 {
      ${croppedMultiLineText}
    }
  }

  a {
    width: 100%;
    & > div {
      width: 100%;
      height: 100%;
    }
  }

  margin-top: 1rem;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQueries.md} {
    margin-top: 2.5rem;
  }

  @media screen and (max-width: 648px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const FilterSection = styled(Flex)`
  margin-top: 2.5rem;
  padding-bottom: 1.5rem;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey.light};
`;

export const TagButtonContainer = styled(Flex)`
  gap: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
`;

// Transform is needed for correct marker positioning.
// for details see https://github.com/google-map-react/google-map-react/issues/854#issuecomment-899021073
export const ClusterMarkerWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  background: ${colors.white.standard};
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.24),
    0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);

  & p {
    font-weight: 600;
  }
`;

export const MarkerWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  width: 344px;
`;

export const PointWrapper = styled(Flex)`
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;

export const CardWrapper = styled(Div)`
  position: absolute;
  transform: translateZ(0) translate(-50%, -70%);
`;

export const ZoomButtonsWrapper = styled(Flex)`
  margin-right: 24px;
  flex-direction: column;
  justify-content: center;

  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
`;

export const CardsWrapper = styled(Flex)`
  gap: 15px;
  justify-content: center;

  ${getMaxWidthMediaQuery('md')} {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

export const StyledSlider = styled(Slider)`
  position: relative;

  h3 {
    ${titleShadow};
  }

  &:before {
    content: '';
    z-index: ${zIndex.minusOne};

    ${bubbles};
    top: -400px;
    left: calc((100% - 900px - ${`${bubbleWidth}px`}) / 2);

    ${mediaQueries.md} {
      top: -550px;
    }

    background-size: 100%;
    width: 1550px;
    height: 1550px;
  }

  & h3 {
    color: ${colors.white.standard} !important;
  }
`;

export const RestoreButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

export { ReviewCard };
