import { apiRequest } from '../api/api';
import { VisitEndpoints } from '../api/endpoints/endpoints';

const removeAppointment = async ({ id, params, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.delete({
    endpoint: VisitEndpoints.RemoveAppointment,
    endpointParams: { id, appointmentId: params.appointmentId },
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const visitAPI = {
  removeAppointment,
};
