import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Flex, Caption, colors, Slider } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../constants';
import { getSliderMargin } from '../../../helpers/rtl';

export const PriceBlock = styled(Flex)`
  gap: 6px;
  align-items: baseline;
`;

export const OldPrice = styled(Caption)`
  text-decoration: line-through;
`;

export const ControlButtons = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

export const IconFlex = styled(Flex)<{ isSelected: boolean }>`
  svg {
    width: 48px;
    height: 48px;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      svg path {
        stroke: ${colors.blue.standard};
      }
    `}
`;

export const ServiceBlock = styled(Flex)<{ isSelected: boolean }>`
  cursor: pointer;
  flex-direction: column;
  min-width: 64px;
  height: 78px;
  gap: 4px;
  align-items: center;
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${colors.blue.standard};
      border-bottom: 2px solid ${colors.blue.standard};
    `}
  :hover {
    border-bottom: 2px solid ${colors.blue.standard};
  }

  span {
    width: max-content;
  }
`;

export const SliderWrapper = styled(Flex)`
  & > div:first-child {
    width: 100%;
    max-width: 440px;
  }

  img {
    width: 100% !important;
  }
`;

export const Wrapper = styled(Flex)<{ isBackground: boolean }>`
  ${({ isBackground }) =>
    isBackground &&
    css`
      background-color: ${colors.white.standard};
      & > div {
        z-index: ${zIndex.searchDropdownWrapper - 1};
      }
    `}
`;

export const StyledSlider = styled(Slider)`
  .swiper {
    ${({ theme }) => getSliderMargin(theme.rtl)};
    margin-left: 0;
    margin-right: 0;
    & + div {
      display: none;
    }
    .swiper-slide:first-child {
      ${({ theme }) => `margin-${theme.rtl ? 'right' : 'left'}:0!important;`}
    }
  }
`;
