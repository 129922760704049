/* global google */
import { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Supercluster from 'supercluster';
import { Nullable } from 'tsdef';
import { Flex } from '@beauty/beauty-market-ui';
import { useMarkers } from '../../../../components/CustomGoogleMap';
import { MapCardWrapper } from '../../../../components/CustomGoogleMap/components/MapCardWrapper';
import CustomGoogleMap from '../../../../components/CustomGoogleMap/CustomGoogleMap';
import { FilterType, getSelectedLanguage, telAvivBounds, telAvivGeolocation } from '../../../../constants';
import { getTranslation } from '../../../../helpers';
import { useMediaScreen, useMemoizedState, useTheme } from '../../../../hooks';
import { useGetOrganisations } from '../../../../hooks/useGetOrganisations';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { currentTopCategory } from '../../../../store/redux-slices/categoriesSlice';
import { updateShowFooter } from '../../../../store/redux-slices/footerSlice';
import { updateShowMenu } from '../../../../store/redux-slices/headerSlice';
import { selectAppliedFiltersCount } from '../../../../store/redux-slices/offersFilterSlice';
import { selectOrganisation } from '../../../../store/redux-slices/organisationSlice';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import { LatLngLiteral, MarkerType, MapPropsType, GeolocationType, CustomGoogleMapRef } from '../../../../types';
import { OrganisationRenderType } from '../../../../types/organisation';
import { ID_ALL_CATEGORY } from '../../constants';
import FilterResultsSidebar from './FilterResultsSidebar';
import { clearMapButtons, getSidebarHandleBtn } from './helpers';
import { OrganizationMapWrapper } from './style';

type CustomMapProps = {
  onChangeView: () => void;
  mapZoom?: number;
  centerOfMap?: GeolocationType;
};

const clusterOptions = {
  maxZoom: 22,
  radius: 72,
};

const OrganizationMap = ({ onChangeView, mapZoom = 12, centerOfMap }: CustomMapProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const topCategory = useAppSelector(currentTopCategory);
  const topCategoryId = topCategory?.id || ID_ALL_CATEGORY;

  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);
  const { geolocation, bounds: area } = useAppSelector(searchState);
  const { isShowSearch } = useAppSelector(selectOrganisation);
  const selectedFiltersCount = useAppSelector(selectAppliedFiltersCount);

  const [isOffersSidebarVisible, setIsOffersSidebarVisible] = useState(true);
  const [treesPoints, setTreesPoints] = useState<MarkerType[]>([]);
  const [clusterPoints, setClusterPoints] = useState<Supercluster>([]);
  const [hoveredElement, setHoveredElement] = useState<Nullable<MarkerType>>(null);
  const [activeCard, setActiveCard] = useState<Nullable<MarkerType>>(null);
  const [infoCardData, setInfoCardData] = useState<Nullable<MarkerType>>(null);

  const [visibleOrgs, setVisibleOrgs] = useMemoizedState<(OrganisationRenderType & MarkerType)[]>([]);

  const mapCenter: LatLngLiteral = centerOfMap || geolocation || telAvivGeolocation;
  const [mapProps, setMapProps] = useState<MapPropsType>({
    center: mapCenter,
    zoom: mapZoom,
    bounds:
      area && area.NE && area.SW
        ? [area.SW.lng, area.SW.lat, area.NE.lng, area.NE.lat]
        : [telAvivBounds.SW.lng, telAvivBounds.SW.lat, telAvivBounds.NE.lng, telAvivBounds.NE.lat],
  });

  const mapRefs = useRef<CustomGoogleMapRef>(null);
  const mapWrapperRef = useRef<HTMLElement>(null);

  const { isDesktop, isMobile } = useMediaScreen('md');

  const { organisations: categoryOrganisations } = useGetOrganisations(topCategoryId);

  const language = getSelectedLanguage();

  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;

  const mapResultsCount = visibleOrgs?.length || 0;
  const mapResultsLabel = `${t('home.map.chooseFrom')} ${mapResultsCount} ${t('home.map.offerings')}`;

  const supercluster = useMemo(() => {
    const cluster = new Supercluster(clusterOptions);
    cluster.load(
      organisations.map(org => ({
        ...org,
        title: org.orgName ? getTranslation(org.orgName, language) : org.name,
        image: org.mainPhoto,
        headline: 'remove after...',
        geometry: { coordinates: [org.coordinates.lng || 31, org.coordinates.lat || 31] },
      })),
    );
    return cluster;
  }, [organisations]);

  const onMarkerClick = (marker: MarkerType) => {
    setInfoCardData(marker);
    setActiveCard(clusterPoints[marker.index]);
  };

  const markers = useMarkers({
    clusterTreesPoints: treesPoints,
    onMarkerClick,
    hoverElementId: hoveredElement?.id || activeCard?.id,
    mapRef: mapRefs.current?.mapRef,
    mapContainerRef: mapWrapperRef.current,
    supercluster,
    mapCenter,
    activeCardId: infoCardData?.index,
    setInfoCardData,
  });

  const onChangeMap = useCallback(({ center, zoom, bounds }) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    setMapProps({ center, zoom, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()] });
  }, []);

  const handleElementHover = (marker: MarkerType) => {
    if (!hoveredElement || hoveredElement.id !== marker.id) {
      setHoveredElement(marker);
    }
  };

  useEffect(() => {
    dispatch(updateShowMenu({ isShowMenu: false }));
    dispatch(updateShowFooter({ isShowFooter: false }));

    return () => {
      dispatch(updateShowMenu({ isShowMenu: true }));
      dispatch(updateShowFooter({ isShowFooter: true }));
    };
  }, []);

  useEffect(() => {
    const zoomLevel = mapProps?.zoom || mapZoom;

    const visibleMarkers = supercluster
      .getClusters(mapProps.bounds, zoomLevel)
      .map(item => {
        if (item.type === 'Feature') {
          return supercluster.getLeaves(item.id, Infinity);
        }
        return item;
      })
      .flat(1);

    setVisibleOrgs(visibleMarkers);

    setTreesPoints(supercluster.trees[zoomLevel].points);

    setClusterPoints(supercluster.points);
  }, [mapProps, supercluster, organisations]);

  useEffect(() => {
    const map = mapRefs.current?.mapRef;
    const maps = mapRefs.current?.mapsControlsRef;
    if (!map || !maps) return;

    clearMapButtons(map, maps, isShowSearch, theme.rtl);

    if (!isDesktop) return;

    const offerSidebarHandleBtn = getSidebarHandleBtn({
      isVisible: isOffersSidebarVisible,
      onClick: value => setIsOffersSidebarVisible(value),
      label: mapResultsLabel,
      isVisibleLeftBlock: true,
      theme,
    }) as HTMLDivElement;

    map.controls[theme.rtl ? maps.ControlPosition.RIGHT_TOP : maps.ControlPosition.LEFT_TOP].push(
      offerSidebarHandleBtn,
    );
  }, [selectedFiltersCount, isOffersSidebarVisible, isDesktop, mapCenter, mapRefs.current?.mapRef, mapResultsLabel]);

  return (
    <OrganizationMapWrapper ref={mapWrapperRef}>
      <CustomGoogleMap
        centerOfMap={mapCenter}
        markers={markers}
        onChangeMap={onChangeMap}
        ref={mapRefs}
        mapZoom={mapZoom}
        onClickCloseMap={onChangeView}
      >
        {isMobile && infoCardData && (
          <Flex justifyContent="center">
            <MapCardWrapper
              marker={clusterPoints[infoCardData.index]}
              mapContainerRef={mapWrapperRef.current}
              onClickOutside={() => setInfoCardData(null)}
            />
          </Flex>
        )}
        {isOffersSidebarVisible && (
          <FilterResultsSidebar
            sidebarLabel={mapResultsLabel}
            hoveredItemId={hoveredElement?.id}
            onHover={marker => {
              setInfoCardData(marker);
              handleElementHover(marker);
            }}
            onLeave={() => setHoveredElement(null)}
            organizationList={visibleOrgs}
            currentActiveCard={activeCard}
          />
        )}
      </CustomGoogleMap>
    </OrganizationMapWrapper>
  );
};

export default OrganizationMap;
