export enum RouterUrl {
  Homepage = '/',
  AllOffers = '/all-offers',
  LoginIntro = '/login-intro',
  LogOut = '/logout',
  Login = '/login',
  LoginSMS = '/login/sms',
  Welcome = '/welcome',
  Business = '/login/business',
  EveryOne = '/login/everyone',
  SignUp = '/login/signup',
  EmailVerification = '/login/email-verification',
  Registration = '/login/registration',
  PhoneVerification = '/login/phone-verification',
  UpdatePassword = '/login/update-password',
  ResetPassword = '/login/reset-password',
  SentEmailNotification = '/login/reset-password/sent-email-notification',
  Organisation = '/organisation',
  NotPermission = '/not-permission',
  Subscription = 'subscription',
  Booking = '/organisation/booking',
  BookingTimeslot = 'booking-timeslot',
  Client = '/client',
  ClientProfile = '/client/profile',
  ClientAppointments = '/client/appointments',
  ClientFavourites = '/client/favourites',
  ClientDocs = '/client/docs',
  FAQ = '/faq',
  FAQQuestions = '/faq/questions',
  Support = '/support',
  PrivacyPolicy = '/privacy-policy',
  TermsOfService = '/terms-of-service',
  TermsOfUse = '/terms-of-use',
  AboutUs = '/about-us',
  ContactUs = '/contact-us',
  ForBusiness = '/for-business',
  Search = '/search',
  BusinessJoin = '/business-join',
  JoinAsBusiness = '/join-business',
  JoinAsClient = '/join-as-client',
  Notifications = '/notifications',
  ChangePlanPage = '/profile-settings/change-plan',
  AccountSetting = '/account-setting',
}
