import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'tsdef';
import { Organisation } from '../../page/Organisation/types';
import { ServiceType } from '../../types';
import { SpecialistType } from '../../types/specialist';
import { AvailableTimeslotType } from '../../types/timeslot';
import { RootState } from '../store';

export interface BookingState {
  service: Nullable<ServiceType>;
  timeslot: Nullable<AvailableTimeslotType>;
  specialist: Nullable<SpecialistType>;
  organization: Nullable<Organisation>;
  daySelected: Nullable<string>;
  isOpenCheckoutSidebar: boolean;
}

const initialState: BookingState = {
  service: null,
  timeslot: null,
  specialist: null,
  organization: null,
  isOpenCheckoutSidebar: false,
  daySelected: null,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setService: (state, action: PayloadAction<Nullable<ServiceType>>) => ({ ...state, service: action.payload }),

    setTimeslot: (state, action: PayloadAction<Nullable<AvailableTimeslotType>>) => ({
      ...state,
      timeslot: action.payload,
    }),

    setSpecialist: (state, action: PayloadAction<Nullable<SpecialistType>>) => ({
      ...state,
      specialist: action.payload,
    }),

    setOrganization: (state, action: PayloadAction<Nullable<Organisation>>) => ({
      ...state,
      organization: action.payload,
    }),

    setOpenCheckoutSidebar: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOpenCheckoutSidebar: action.payload,
    }),

    setSelectedDay: (state, action: PayloadAction<Nullable<string>>) => ({
      ...state,
      daySelected: action.payload,
    }),

    resetBooking: () => initialState,
  },
});

export const {
  setService,
  setTimeslot,
  setSpecialist,
  setOrganization,
  resetBooking,
  setOpenCheckoutSidebar,
  setSelectedDay,
} = bookingSlice.actions;

export const currentService = (state: RootState) => state.booking.service;
export const currentTimeslot = (state: RootState) => state.booking.timeslot;
export const currentSpecialist = (state: RootState) => state.booking.specialist;
export const currentOrganization = (state: RootState) => state.booking.organization;
export const daySelected = (state: RootState) => state.booking.daySelected;
export const isOpenCheckoutSidebar = (state: RootState) => state.booking.isOpenCheckoutSidebar;

export default bookingSlice.reducer;
