import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Separator,
  Button,
  H2,
  BodySmall,
  Div,
  Flex,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components';
import { AccType, InvalidVariants, getSelectedLanguage } from '../../../constants';
import { getInvalidType } from '../../../helpers';
import { fetchInviteUserData } from '../../../helpers/fetchInviteUserData';
import { sendEmailLink } from '../../../helpers/sendEmailLink';
import { useGetSearchParams } from '../../../routes/hooks/useGetSearchParams';
import { RouterUrl } from '../../../routes/routes';
import GoogleButton from '../componets/GoogleButton';
import { Rules } from '../componets/Rules';
import { EmailField, checkEmailSchema, EmailForm } from '../Login.definitions';
import { StyledForm } from '../style';

const SignUp = () => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const [isLinkSent, setIsLinkSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [decodedToken, setDecodedToken] = useState({ id: '', orgId: '', email: '' });
  const { state } = useLocation();
  const isWrongLink = state?.isWrongLink;
  const currentLanguage = getSelectedLanguage();
  const [searchParamsAsObject] = useGetSearchParams();
  const navigate = useNavigate();

  const referral = useMemo(() => searchParamsAsObject?.referral || '', [searchParamsAsObject]);

  useEffect(() => {
    if (!searchParamsAsObject?.client) return;

    fetchInviteUserData(searchParamsAsObject.client).then(res => {
      if (res) {
        setDecodedToken(prevState => ({
          ...prevState,
          ...res,
        }));
      }
    });
  }, [searchParamsAsObject]);

  const onFormSubmit = useCallback(
    async (data: EmailForm) => {
      setEmail(data.email);
      const response = await sendEmailLink(
        data,
        AccType.USER,
        currentLanguage,
        decodedToken.id,
        decodedToken.orgId,
        referral,
      );

      if (response.success) {
        if (response.statusCode === 200) {
          navigate(`${RouterUrl.Login}/email-verification?token=${response.data.emailVerificationToken}`);
        }
        setIsLinkSent(true);
        setInvalidType(null);
      } else {
        setInvalidType(getInvalidType(response.statusCode, 'signUp'));
        setErrorMessage(response.message);
      }
    },
    [decodedToken, referral],
  );

  const formikContextValue = {
    initialValues: { email: decodedToken.email },
    validationSchema: checkEmailSchema(errorMessage, t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
    enableReinitialize: true,
  };

  return isLinkSent && !isWrongLink ? (
    <Div>
      <H2 mb="16px" textAlign={isDesktop ? 'center !important' : 'start'}>
        {t('signUp.checkInbox')}
      </H2>
      <BodySmall large textAlign={isDesktop ? 'center !important' : 'start'}>
        {`${t('signUp.linkToVerify')} ${email}`}
      </BodySmall>
    </Div>
  ) : (
    <Flex flexDirection="column">
      <H2 mb="16px">{isWrongLink ? t('signUp.wrongLink') : t('signUp.title')}</H2>
      <BodySmall mb="32px">{t('signUp.enterEmail')}</BodySmall>
      <Formik {...formikContextValue}>
        {({ isValid }) => (
          <StyledForm>
            <FormikInput
              autoFocus
              id={EmailField.Email}
              name={EmailField.Email}
              placeholder="E-mail"
              invalidType={invalidType}
              onInput={() => setInvalidType(null)}
            />
            <Button
              type="submit"
              disabled={!isValid || invalidType}
              design="primary"
              mt="14px"
              width="100%"
              size="large"
            >
              {t('button.continue')}
            </Button>
          </StyledForm>
        )}
      </Formik>

      <Separator text={t('login.or')} mt={32} mb={20} />

      <Flex alignItems="center" justifyContent="center" mb={15}>
        <GoogleButton text={t('signUp.signUpWihGoogle')} />
      </Flex>
      <Rules />
    </Flex>
  );
};

export default SignUp;
