import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { setSpecialist } from '../../../store/redux-slices/bookingSlice';
import { BookingParams } from '../../../types/booking';
import { ArrowBack } from '../components';
import { ActionButton } from '../components/ActionButton';
import Popup from '../components/Popup';

interface PopupProps extends React.PropsWithChildren {
  specId?: string;
}

const PopupBookingSpecialist = ({ specId }: PopupProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { orgId, serviceId } = useParams() as BookingParams;

  const handleBooking = useCallback(() => {
    if (!specId) return;

    navigate(`${RouterUrl.Booking}/${orgId}/${serviceId}/${specId}`);
  }, [dispatch, orgId, serviceId, specId]);

  const actionButton = <ActionButton onClick={handleBooking}>{t('organisation.booking.bookAppointment')}</ActionButton>;

  const handleReturn = useCallback(() => {
    dispatch(setSpecialist(null));
    navigate(`${RouterUrl.Booking}/${orgId}/`);
  }, [dispatch]);

  return createPortal(
    <Popup actionButton={actionButton} controlButton={<ArrowBack onClick={handleReturn} />} />,
    document.getElementById('root') as HTMLElement,
  );
};

export default PopupBookingSpecialist;
