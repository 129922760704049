import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import { KEY_TERMS_SECTIONS_COUNT, KEY_TERMS_SECTION_6_SUBSECTIONS_COUNT, transComponents } from '../constants';
import { Section } from './Section';

type Props = {
  id: string;
};

export const KeyTerms = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.keyTerms.title')}</PrivacyItemTitleWrapper>
      <BodySmall mb="16px">{t('termsOfService.keyTerms.article1')}</BodySmall>
      {Array(KEY_TERMS_SECTIONS_COUNT)
        .fill(0)
        .map((__, index) => (
          <Section
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
            isTitleNotH6
            title={t(`termsOfService.keyTerms.section${index + 1}.title`)}
            body={t(`termsOfService.keyTerms.section${index + 1}.body`)}
            subSection={
              index === 5
                ? Array(KEY_TERMS_SECTION_6_SUBSECTIONS_COUNT)
                    .fill(0)
                    .map((___, i) => (
                      <Flex
                        // eslint-disable-next-line react/no-array-index-key
                        key={i + 1}
                        flexDirection="column"
                        mb={i === KEY_TERMS_SECTION_6_SUBSECTIONS_COUNT - 1 ? '24px' : '24px'}
                      >
                        <BodySmall large mb="8px">
                          <Trans components={transComponents}>
                            {t(`termsOfService.keyTerms.section${index + 1}.subsections.subsection${i + 1}.title`)}
                          </Trans>
                        </BodySmall>
                        <BodySmall>
                          <Trans components={transComponents}>
                            {t(`termsOfService.keyTerms.section${index + 1}.subsections.subsection${i + 1}.body`)}
                          </Trans>
                        </BodySmall>
                      </Flex>
                    ))
                : undefined
            }
          />
        ))}
    </PrivacyItemWrapper>
  );
});

KeyTerms.displayName = 'KeyTerms';
