import { GenericAbortSignal } from 'axios/index';
import { Nullable } from 'tsdef';
import { apiRequest } from '../api/api';
import { OrganisationEndpoints, ServicesEndpoints, SpecialistEndpoints } from '../api/endpoints/endpoints';
import { GeolocationType } from '../types/general';
import { createQueryParams } from './utils';

export const handleSeeDirection = (coordinates: Nullable<GeolocationType>, userLocation: Nullable<GeolocationType>) => {
  const generalUserLocation = userLocation ? `${userLocation.lat},${userLocation.lng}` : null;
  const generalMapCenter = coordinates ? `${coordinates.lat},${coordinates.lng}` : null;
  const mapsUrl = `https://www.google.com/maps/dir/${generalUserLocation}/${generalMapCenter}`;
  userLocation && coordinates && window.open(mapsUrl, '_blank');
};

export const fetchOrganisationDetails = async (orgId: string, signal?: GenericAbortSignal) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.Organisation,
    endpointParams: {
      orgId,
      queryParams: `?include=headOrganization,orgSpecialist,contact,address,orgService,orgBusinessHours,photo,category,property`,
    },
    config: { signal },
  });

  return response?.data;
};

export const fetchOrganisationServices = async (orgId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.Services,
    endpointParams: orgId,
  });

  return response?.data;
};

export const fetchOrganisationSpecialists = async (orgId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.OrganisationSpecialists,
    endpointParams: orgId,
  });

  return response?.data;
};

export const fetchOrganisationReviews = async (orgId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.OrganisationReviews,
    endpointParams: orgId,
  });

  return response?.data;
};

export const fetchOrganisationServiceDetails = async (orgId: string, serviceId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.Timeslots,
    endpointParams: { orgId, serviceId },
  });

  return response?.data;
};

export const fetchServiceDetails = async (serviceId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: ServicesEndpoints.Service,
    endpointParams: serviceId,
  });

  return response?.data;
};

export const fetchAvailableTimeslots = async (
  serviceId: string,
  params: { date: string; days: number; orgSpecId?: string },
  signal?: GenericAbortSignal,
) => {
  const queryParams = createQueryParams(params);
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.AvailableTimeslots,
    endpointParams: { serviceId, queryParams },
    config: { signal },
  });

  return response?.data || [];
};

export const fetchDateSlots = async (
  serviceId: string,
  params: { date: string; days: number; orgSpecId?: string },
  signal?: GenericAbortSignal,
) => {
  const queryParams = createQueryParams(params);
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.DateSlots,
    endpointParams: { serviceId, queryParams },
    config: { signal },
  });

  return response?.data;
};

export const fetchCategoriesDetails = async () => {
  const [, response] = await apiRequest.get({
    endpoint: ServicesEndpoints.Categories,
  });

  return response?.data;
};

export const fetchSpecialistsDetails = async (orgId: string, signal?: GenericAbortSignal) => {
  const [, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.Specialists,
    endpointParams: { orgId, queryParams: '?include=orgSpecialist' },
    config: { signal },
  });

  return response?.data;
};

/* export const fetchSpecialistDetails = async (specId: string) => {
  const [, response] = await apiRequest.get({
    endpoint: SpecialistEndpoints.Root,
    endpointParams: { id: specId, queryParams: '?include=account' },
  });

  return response?.data;
}; */
