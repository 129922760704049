import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedEventCard, Flex, H6, BodyLarge, colors } from '@beauty/beauty-market-ui';
import { RatingSidebar } from '../../../../components/RatingSidebar/RatingSidebar';
import { getSelectedLanguage } from '../../../../constants';
import { getTranslation } from '../../../../helpers';
import { getFullDate } from '../../../../helpers/appointments';
import { useMediaScreen } from '../../../../hooks';
import { AppointmentType } from '../../../../types/appointment';
import { EventStatus } from '../constants';
import { ButtonsWrapper, CardWrapper, CrossedCaption, PriceWrapper, StyledSeparator } from '../style';
import { TodoType } from '../types';
import { useEventInfo } from '../useEventInfo';
import { HandleButtons } from './HandleButtons';
import { Organisation } from './Organisation';
import { PopupBlock } from './PopupBlock';

type EventInfoProps = {
  event: AppointmentType;
  toReview?: boolean;
};

const EventInfo = ({ event, toReview = false }: EventInfoProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen('md');

  const [isPopup, setIsPopup] = useState(false);
  const [isSendPopup, setIsSendPopup] = useState(false);
  const [whatToDo, setWhatToDo] = useState<TodoType | null>(event.status === EventStatus.PAST ? 'manage' : null);
  const [reviewAppointmentOpen, setReviewAppointmentOpen] = useState(toReview);

  const language = getSelectedLanguage();

  const { organisationInfo, appointmentDetails, isDiscount, price, handleCancelAppointment, handleRebookAppointment } =
    useEventInfo(event);

  const toggleReviewAppointment = () => {
    setReviewAppointmentOpen(!reviewAppointmentOpen);
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      maxWidth={isMobile ? 'auto' : `calc(100% - 338px)`}
      mt={['20px', '20px', '20px', 0]}
    >
      <Organisation info={organisationInfo} id={event.orgService.orgId} />

      <CardWrapper crossed={isDiscount}>
        <ExtendedEventCard
          title={`${getFullDate(event.start, t, language)}`}
          service={getTranslation(event.orgService.headOrgService.title, language)}
          price={<BodyLarge large>{price.totalPrice}</BodyLarge>}
          oldPrice={
            isDiscount ? (
              <CrossedCaption large color={colors.grey.dark}>
                {price.servicePrice}
              </CrossedCaption>
            ) : (
              ''
            )
          }
        />
      </CardWrapper>

      <StyledSeparator />

      <PriceWrapper>
        <H6>{t('appointments.price')}</H6>
        <H6>{price.totalPrice} </H6>
      </PriceWrapper>

      <ButtonsWrapper>
        <HandleButtons
          status={event.status}
          onRebookAppointment={handleRebookAppointment}
          onSetAction={setWhatToDo}
          onOpenPopup={() => setIsPopup(true)}
        />
      </ButtonsWrapper>
      {isPopup && (
        <PopupBlock
          event={event}
          whatToDo={whatToDo}
          isSendPopup={isSendPopup}
          onCancelAppointment={handleCancelAppointment}
          onRebookAppointment={handleRebookAppointment}
          onAddToCalendar={() => window.open('https://calendar.google.com/calendar/u/0/r?pli=1', '_blank')}
          setIsPopup={setIsPopup}
          setIsSendPopup={setIsSendPopup}
          onWriteReview={toggleReviewAppointment}
          onSendReceipt={() => {
            setWhatToDo('send');
            setIsSendPopup(false);
          }}
        />
      )}

      {reviewAppointmentOpen && appointmentDetails && (
        <RatingSidebar {...appointmentDetails} setOpen={setReviewAppointmentOpen} />
      )}
    </Flex>
  );
};

export { EventInfo };
