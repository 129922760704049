import React, { useEffect } from 'react';
import { RaiteTab } from '@beauty/beauty-market-ui';
import MapMarker from '../../../components/CustomGoogleMap/components/MapMarker';
import { PointWrapper } from '../../../components/CustomGoogleMap/components/style';
import CustomGoogleMap from '../../../components/CustomGoogleMap/CustomGoogleMap';
import { useAppDispatch } from '../../../store/hooks';
import { updateShowFooter } from '../../../store/redux-slices/footerSlice';
import { LatLngLiteral } from '../../../types';
import { ResponseOrganisation } from '../types';

interface Props extends React.PropsWithChildren {
  onChangeView: () => void;
  mapZoom?: number;
  organization: ResponseOrganisation;
}

const Map: React.FC<Props> = ({ organization, mapZoom, onChangeView }) => {
  const dispatch = useAppDispatch();

  const address = organization.address?.address[0];

  if (!address) return null;

  const mapCenter: LatLngLiteral = {
    lat: address.lat || 0,
    lng: address.lng || 0,
  };

  useEffect(() => {
    dispatch(updateShowFooter({ isShowFooter: false }));

    return () => {
      dispatch(updateShowFooter({ isShowFooter: true }));
    };
  }, []);

  const markers = [
    <MapMarker key={organization?.id} lng={address?.lng} lat={address?.lat} zIndex={99}>
      <PointWrapper>
        <RaiteTab rate={organization.rating} />
      </PointWrapper>
    </MapMarker>,
  ];

  return <CustomGoogleMap centerOfMap={mapCenter} markers={markers} mapZoom={mapZoom} onClickCloseMap={onChangeView} />;
};

export default Map;
