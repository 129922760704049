import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface FooterState {
  isShowFooter: boolean;
}

const initialState: FooterState = {
  isShowFooter: true,
};

export const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    updateShowFooter: (state, action: PayloadAction<{ isShowFooter: boolean }>) => {
      state.isShowFooter = action.payload.isShowFooter;
    },
  },
});

export const { updateShowFooter } = footerSlice.actions;

export const isShowFooter = (state: RootState) => state.footer.isShowFooter;

export default footerSlice.reducer;
