import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'tsdef';
import { BodySmall, Caption, colors } from '@beauty/beauty-market-ui';
import AvatarSpecialist, { avatarSizes } from './component/AvatarSpecialist';
import { Wrapper, BlockView } from './style';

type SpecialistBookingCardProps = {
  avatarUrl?: string;
  title?: string;
  label?: string;
  avatarSize?: keyof typeof avatarSizes;
  onClick?: () => void;
  checked?: boolean;
  maxDiscount?: Nullable<number>;
  isShowDiscountText?: boolean;
};

export const SpecialistBookingCard = ({
  avatarUrl,
  title,
  label,
  avatarSize = 'xs',
  onClick = undefined,
  checked,
  maxDiscount,
  isShowDiscountText = true,
}: SpecialistBookingCardProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={onClick} checked={checked}>
      <AvatarSpecialist avatarSize={avatarSize} avatarUrl={avatarUrl} />
      <BlockView>
        {isShowDiscountText && maxDiscount && maxDiscount > 0 ? (
          <Caption truncated directed color={colors.blue.standard}>
            {`${t('organisation.booking.saveUpTo')} -${maxDiscount}%`}
          </Caption>
        ) : null}
        {title && (
          <BodySmall truncated directed>
            {title}
          </BodySmall>
        )}
        {label && (
          <BodySmall truncated directed color={colors.grey.dark}>
            {label}
          </BodySmall>
        )}
      </BlockView>
    </Wrapper>
  );
};
