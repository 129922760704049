import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNotify } from '@beauty/beauty-market-ui';
import { ID_FOR_ANY_SPECIALIST } from '../constants';
import { getErrorNotifyContent } from '../helpers';
import { fetchAvailableTimeslots } from '../helpers/organisation';
import { TimeslotsResponse } from '../types/timeslot';

export const useGetTimeslots = (date: Date, serviceId?: string, orgSpecId?: string) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [timeslots, setTimeslots] = useState<TimeslotsResponse | null>(null);

  const notify = useNotify();

  const specId = orgSpecId !== ID_FOR_ANY_SPECIALIST ? orgSpecId : undefined;

  useEffect(() => {
    const abortController = new AbortController();

    const fetchTimeslots = async () => {
      if (!serviceId) return;

      setIsLoading(true);

      const adjustedDate = moment(date).format('YYYY-MM-DDT00:00:00[Z]');

      const emptySlotsState = {
        date: moment(adjustedDate).format('YYYY-MM-DD'),
        timeSlots: [],
      };

      try {
        const [response]: [TimeslotsResponse] = await fetchAvailableTimeslots(
          serviceId as string,
          {
            date: adjustedDate.toString(),
            days: 1,
            orgSpecId: specId,
          },
          abortController.signal,
        );

        if (!abortController.signal.aborted) {
          response?.date && response.timeSlots ? setTimeslots(response) : setTimeslots(emptySlotsState);
        }
      } catch (error) {
        notify(getErrorNotifyContent(t));
        setTimeslots(emptySlotsState);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTimeslots();

    return () => {
      abortController.abort();
    };
  }, [serviceId, orgSpecId, date]);

  return {
    isLoading,
    timeslots,
  };
};
