import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '@beauty/beauty-market-ui';
import { REDIRECT_AFTER_LOGIN } from '../../constants';
import { hasScroll } from '../../helpers';
import { useAppSelector } from '../../store/hooks';
import { selectIsLogin } from '../../store/redux-slices/userSlice';
import { ImageBlock, InfoWrapper, LoginWrapper } from './style';

const Login = () => {
  const [isMarginTop, setMarginTop] = useState(false);
  const infoRef = useRef<HTMLElement>(null);

  const navigate = useNavigate();
  const { state } = useLocation();

  const isLogin = useAppSelector(selectIsLogin);

  const topDistance = infoRef.current?.getBoundingClientRect().top;

  useEffect(() => {
    const handleResize = () => setMarginTop(hasScroll());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (state?.[REDIRECT_AFTER_LOGIN] && isLogin) {
      navigate(state[REDIRECT_AFTER_LOGIN]);
    }
  }, [state, isLogin, navigate]);

  return (
    <LoginWrapper mt={['0', '0', '0', isMarginTop && topDistance && topDistance < 104 ? '24px' : '0']}>
      <InfoWrapper ref={infoRef}>
        <Flex width={['100%', '100%', '100%', '400px']} flexDirection="column">
          <Outlet />
        </Flex>
      </InfoWrapper>
      <ImageBlock />
    </LoginWrapper>
  );
};

export default Login;
