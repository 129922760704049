import { forwardRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Input, InputProps, PlaceholderColorsVariants } from '@beauty/beauty-market-ui';
import { invalidHints, InvalidVariants } from '../../../../constants';
import { useStatus } from '../hooks/use-status';
import { InputWrapper } from './style';

type FormikInputProps = InputProps & {
  placeholder?: string;
  name: Pick<InputProps, 'name'>;
  disableDefaultOnBlur?: boolean;
  invalidType?: InvalidVariants;
  autoComplete?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: PlaceholderColorsVariants;
};

export const FormikInput = memo(
  forwardRef((props: FormikInputProps, ref) => {
    const {
      placeholder,
      name,
      onBlur: explicitOnBlur,
      disableDefaultOnBlur,
      variant = 'default',
      invalidType = false,
      autoComplete = 'nope',
      ...otherExplicitProps
    } = props;
    const [formikProps, meta] = useField(name as string);
    const { onBlur: formikDefaultOnBlur, value, ...otherFormikProps } = formikProps;
    const { t } = useTranslation();
    const status = useStatus(meta);

    const caption = useMemo(() => {
      if (status === 'invalid') return meta.error;
      if (invalidType) return t(invalidHints[invalidType]);
      return undefined;
    }, [status, meta.error, invalidHints, invalidType, t]);

    return (
      <InputWrapper>
        <Input
          autoComplete={autoComplete}
          width="100%"
          ref={ref}
          placeholder={placeholder}
          aria-label={placeholder}
          nonValid={status === 'invalid' || Boolean(invalidType)}
          caption={caption}
          onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
            !disableDefaultOnBlur && formikDefaultOnBlur(event);
            explicitOnBlur?.(event);
          }}
          value={value || props.defaultValue}
          {...otherFormikProps}
          {...otherExplicitProps}
          {...{ variant }}
        />
      </InputWrapper>
    );
  }),
);
