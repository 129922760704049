import React, { HTMLAttributeAnchorTarget } from 'react';
import { ResetLinkStyle } from './style';

interface Props extends React.PropsWithChildren {
  className?: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
}

export const CustomLink: React.FC<Props> = ({ className, to, children, target }) => (
  <ResetLinkStyle className={className} to={to} target={target}>
    {children}
  </ResetLinkStyle>
);
