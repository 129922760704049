import { ReactNode } from 'react';
import { Nullable } from 'tsdef';
import { Flex } from '@beauty/beauty-market-ui';
import { Wrapper, Layout, Content, RightBlock } from './style';

interface BookingPopupProps {
  primaryButton: ReactNode;
  secondaryButton: ReactNode;
  content?: Nullable<ReactNode>;
  disabled?: boolean;
}

const BookingPopup = ({ primaryButton, secondaryButton, content, disabled = false }: BookingPopupProps) => (
  <Wrapper disabled={disabled}>
    <Layout>
      <Content>
        <Flex minWidth={['auto', 'auto', 'auto', '290px']}>{secondaryButton}</Flex>

        <RightBlock>
          {content}
          {primaryButton}
        </RightBlock>
      </Content>
    </Layout>
  </Wrapper>
);

export default BookingPopup;
