import { useState } from 'react';
import { Slider } from '@beauty/beauty-market-ui';
import { isRtl } from '../../helpers';
import { useMediaScreen } from '../../hooks';
import { MenuWrapper, SpyLink } from './style';

type AnchorProps = {
  title: string;
  key: string;
};

type AnchorMenuProps = {
  menuItems: AnchorProps[];
};

export const AnchorMenuSpy = ({ menuItems }: AnchorMenuProps) => {
  const { isMobile } = useMediaScreen('md');
  const rtl = isRtl();

  const [activeId, setActiveId] = useState<string | null>(null);

  const renderLink = (item: AnchorProps) => (
    <SpyLink
      key={item.key}
      width={isMobile ? 'max-content' : 'auto'}
      design={activeId === item.key ? 'blue' : 'black'}
      size="md"
      to={item.key}
      spy
      onSetActive={key => setActiveId(key)}
      smooth
    >
      {item.title}
    </SpyLink>
  );

  const content = menuItems.map(renderLink);

  return <MenuWrapper>{isMobile ? <Slider inlineSlider slides={content} rtl={rtl} /> : content}</MenuWrapper>;
};
