import styled, { css } from 'styled-components';
import { Flex, colors, Div, mediaQueries, Button } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';
import { iconRevert } from '../../../../helpers/rtl';

export const OrganizationMapWrapper = styled(Div)`
  height: 100%;
  margin-top: 10px;
`;

export const FilterResultsWrapper = styled(Div)`
  position: absolute;
  top: 8px;
  bottom: 8px;
  ${({ theme }) =>
    theme.rtl
      ? `right: 8px;
    direction: rtl`
      : `left: 8px;`};
  z-index: ${zIndex.googleFilterResultsWrapper};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
  opacity: 1;
  background: ${colors.white.standard};
`;

export const FilterResultsContent = styled(Flex)`
  width: 360px;
  height: calc(100% - 50px);
  box-sizing: border-box;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  padding-right: 6px;
  margin-right: -10px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey.light};
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* @media screen and (max-width: 992px) {
    overflow-y: auto;
  } */
`;

export const MapPointLocationWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: ${colors.blue.light};
  border: 1px solid ${colors.blue.medium};
  border-radius: 50%;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;

export const MapPointLocationCenter = styled(Div)`
  width: 10px;
  height: 10px;
  background: ${colors.blue.medium};
  border-radius: 50%;
`;

export const ArrowWrapper = styled(Div)`
  margin-top: 40px;
  ${({ theme }) => css`
    ${theme.rtl ? 'margin-right: ' : 'margin-left: '}399px;
    ${theme.rtl && iconRevert};
  `}
`;

export const LabelButton = styled(Button)`
  ${({ theme }) =>
    theme.rtl &&
    css`
      direction: rtl;
      svg {
        transform: scaleX(-1);
      }
    `};
`;
