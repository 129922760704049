import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 30px;
`;

export const Day = styled(Flex)`
  width: 100%;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DayTimeslots = styled(Flex)`
  width: 100%;
  display: grid !important;
  grid-gap: 8px;

  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const MissingTimeslotsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 241px;
  gap: 8px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 44px;

  ${mediaQueries.md} {
    padding-top: 16px;
  }
`;

export const WrapperLoader = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
