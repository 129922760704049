import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Div, H6, BodySmall, Button, LoginIcon, colors } from '@beauty/beauty-market-ui';
import { REDIRECT_AFTER_LOGIN } from '../../../constants';
import { RouterUrl } from '../../../routes/routes';
import { AccountLogInWrapper } from '../style';

const AccountLogIn = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const onSignInClick = useCallback(() => {
    navigate(RouterUrl.Login, {
      state: { [REDIRECT_AFTER_LOGIN]: location.pathname },
    });
  }, [location]);

  return (
    <AccountLogInWrapper mt="16px">
      <Div>
        <H6 mb="4px" color={colors.blue.standard}>
          {t('login.logInto')}
        </H6>
        <BodySmall>{t('login.continueAsGuest')}</BodySmall>
      </Div>
      <Button prefix={<LoginIcon />} size="small" width="206px" onClick={onSignInClick}>
        {t('login.signIntoAccount')}
      </Button>
    </AccountLogInWrapper>
  );
};

export default AccountLogIn;
