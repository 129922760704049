import { useEffect } from 'react';
import { useAppDispatch } from '../store/hooks';
import { updateShowHeader } from '../store/redux-slices/headerSlice';
import { useMediaScreen } from './useMediaScreen';

export const useDisableHeaderForMobile = () => {
  const dispatch = useAppDispatch();

  const { isDesktop } = useMediaScreen('md');

  useEffect(() => {
    dispatch(updateShowHeader({ isShowHeader: !!isDesktop }));
    return () => {
      dispatch(updateShowHeader({ isShowHeader: true }));
    };
  }, [isDesktop]);
};
