import styled, { css } from 'styled-components';
import { Caption, colors, Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const ServicePrice = styled(Flex)`
  flex-direction: column;
  gap: 2px;
  align-items: end;
  text-align: end;

  ${({ theme }) =>
    theme.rtl &&
    css`
      align-items: flex-start;
      text-align: start;
    `}
`;

export const OldPrice = styled(Caption)`
  text-decoration: line-through;
  color: ${colors.grey.dark} !important;
`;

export const StickyWrapper = styled(Div)`
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 30px 0 10px;
  margin-top: -30px;
  background-color: ${colors.white.standard};

  ${mediaQueries.md} {
    padding: 0;
    margin: 0;
    position: static;
  }
`;
