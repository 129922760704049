import { useTranslation } from 'react-i18next';
import { Caption, colors, CoverStack, CoverStackSpecialist, Link } from '@beauty/beauty-market-ui';
import { CustomLink } from '../../../components';
import { RouterUrl } from '../../../routes/routes';
import { useAppSelector } from '../../../store/hooks';
import { currentService } from '../../../store/redux-slices/bookingSlice';
import { Organisation } from '../../Organisation/types';
import { StatePanelWrapper, StatePanelInfo, StatePanelSticky } from '../style';
import { PoliticInfo } from './PoliticInfo';
import SelectedService from './SelectedService';

type StatePanelType = {
  organisation: Organisation;
};

const StatePanel = ({ organisation }: StatePanelType) => {
  const { t } = useTranslation();

  const coverStackProps = {
    specialistType: `${t(organisation.offers.categories.map(cat => cat.name).join(', '))}`,
    specialistLabel: organisation.name,
    rating: organisation.rating,
    imgUrl: organisation.mainPhoto,
  };

  const service = useAppSelector(currentService);

  const content = service && (
    <StatePanelInfo>
      <SelectedService />
      {/* <SelectedSpecialist /> */}
    </StatePanelInfo>
  );

  return (
    <StatePanelWrapper>
      <StatePanelSticky>
        <CoverStack
          header={
            <CustomLink to={`${RouterUrl.Organisation}/${organisation.id}`}>
              <CoverStackSpecialist {...coverStackProps} />
            </CustomLink>
          }
          width="100%"
          content={content as JSX.Element}
        />
        <PoliticInfo />
      </StatePanelSticky>
    </StatePanelWrapper>
  );
};

export default StatePanel;
