import { useTranslation } from 'react-i18next';
import { getSelectedLanguage } from '../../constants';
import { useAppSelector } from '../../store/hooks';
import {
  currentOrganization,
  currentService,
  currentSpecialist,
  currentTimeslot,
} from '../../store/redux-slices/bookingSlice';
import { getFormattedCurrency } from '../Organisation/helpers';
import { getMaxPercentDiscount } from './helpers';

export const usePrice = () => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const organisation = useAppSelector(currentOrganization);
  const service = useAppSelector(currentService);
  const specialist = useAppSelector(currentSpecialist);
  const timeslot = useAppSelector(currentTimeslot);

  const nullPrice = t('organisation.booking.nullPrice');

  const priceBase =
    (timeslot?.price && getFormattedCurrency(timeslot.price, organisation?.currency, language)) ||
    service?.price ||
    nullPrice;

  const priceDiscount =
    timeslot?.discountPrice && getFormattedCurrency(timeslot.discountPrice, organisation?.currency, language);

  const discountPercent = timeslot
    ? null
    : specialist?.discount?.reduce((prev, cur) => (cur.percent > prev ? cur.percent : prev), 0) ||
      getMaxPercentDiscount(service?.discounts);

  return {
    priceBase,
    priceDiscount,
    discountPercent,
  };
};
