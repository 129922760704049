import { useState, useCallback } from 'react';
import { Link } from 'react-scroll';
import { TagButton } from '@beauty/beauty-market-ui';
import { isRtl } from '../../../helpers';
import { CategoryType } from '../../../types';
import { StyledSlider } from './style';

interface ServicesCategorizerProps {
  categories: CategoryType[];
}

const ServicesCategorizer = ({ categories }: ServicesCategorizerProps) => {
  const rtl = isRtl();

  const [activeTag, setActiveTag] = useState<number>(0);

  const handleSetActive = useCallback((index: number) => {
    setActiveTag(index);
  }, []);

  const handleClick = (index: number) => {
    // timer for correctly display of the active tag
    setTimeout(() => {
      setActiveTag(index);
    }, 300);
  };

  const slides = categories.map((category, index) => {
    const design = index === activeTag ? 'active' : 'default';

    return (
      <Link
        containerId="services"
        to={`category-${category.id}`}
        smooth
        duration={250}
        key={category.id}
        spy
        offset={-10}
        onSetActive={() => handleSetActive(index)}
        onClick={() => handleClick(index)}
        activeClass="active"
      >
        <TagButton text={category.name} number={String(category.offersCount)} design={design} />
      </Link>
    );
  });

  return <StyledSlider inlineSlider slides={slides} marginBottom="40px" rtl={rtl} />;
};

export default ServicesCategorizer;
