import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { dateReg, emailReg, ID_NUMBER_REGEXP } from '../../../constants';
import { formatDate } from '../../../helpers/utils';
import { ProfileType } from '../../../types/user';

export enum ProfileFormFields {
  Name = 'name',
  Surname = 'surname',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
  Region = 'code',
  Phone = 'number',
  Email = 'email',
  IdNumber = 'idNumber',
  Country = 'country',
  City = 'city',
  FullAddress = 'fullAddress',
}

export enum DeleteProfileFormFields {
  Reason = 'reason',
  Description = 'description',
}

export enum EmailFormFields {
  Email = 'email',
}

export enum PhoneFormFields {
  Region = 'code',
  Phone = 'number',
}

export type ProfileForm = {
  [ProfileFormFields.Name]: string;
  [ProfileFormFields.Surname]: string;
  [ProfileFormFields.DateOfBirth]: string;
  [ProfileFormFields.Gender]: string;
  [ProfileFormFields.Region]: string;
  [ProfileFormFields.Phone]: string;
  [ProfileFormFields.Email]: string;
  [ProfileFormFields.IdNumber]: string;
  [ProfileFormFields.Country]: string;
  [ProfileFormFields.City]: string;
  [ProfileFormFields.FullAddress]: string;
};

export type DeleteProfileForm = {
  [DeleteProfileFormFields.Reason]: string;
  [DeleteProfileFormFields.Description]: string;
};

export type EmailForm = {
  [EmailFormFields.Email]: string;
};

export type PhoneForm = {
  [PhoneFormFields.Region]: string;
  [PhoneFormFields.Phone]: string;
};

export const initialProfileFormValues: ProfileForm = {
  [ProfileFormFields.Name]: '',
  [ProfileFormFields.Surname]: '',
  [ProfileFormFields.DateOfBirth]: '',
  [ProfileFormFields.Gender]: '',
  [ProfileFormFields.Region]: '',
  [ProfileFormFields.Phone]: '',
  [ProfileFormFields.Email]: '',
  [ProfileFormFields.IdNumber]: '',
  [ProfileFormFields.Country]: '',
  [ProfileFormFields.City]: '',
  [ProfileFormFields.FullAddress]: '',
};

export const initialDeleteProfileFormValues: DeleteProfileForm = {
  [DeleteProfileFormFields.Reason]: '',
  [DeleteProfileFormFields.Description]: '',
};

export const initialEmailFormValues: EmailForm = {
  [EmailFormFields.Email]: '',
};

export const initialPhoneFormValues: PhoneForm = {
  [PhoneFormFields.Region]: '',
  [PhoneFormFields.Phone]: '',
};

export const editProfileFormValidationSchema = (t: TFunction<'translation', undefined>) => {
  const nameSchema = Yup.string()
    .min(2, t('validation.minName'))
    .max(50, t('validation.maxName'))
    .required(t('validation.notEmpty'));
  const surnameSchema = Yup.string()
    .min(2, t('validation.minName'))
    .max(50, t('validation.maxName'))
    .required(t('validation.notEmpty'));

  return Yup.object({
    [ProfileFormFields.Name]: nameSchema,
    [ProfileFormFields.Surname]: surnameSchema,
    [ProfileFormFields.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [ProfileFormFields.DateOfBirth]: Yup.string().matches(dateReg, t('validation.correctDate')),
    [ProfileFormFields.Region]: Yup.string().required(t('validation.notEmpty')),
    [ProfileFormFields.Phone]: Yup.string()
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.notEmpty')),
    [ProfileFormFields.IdNumber]: Yup.string().matches(ID_NUMBER_REGEXP, t('validation.validIdNumber')),
    [ProfileFormFields.Country]: Yup.string(),
    [ProfileFormFields.City]: Yup.string(),
    [ProfileFormFields.FullAddress]: Yup.string(),
  });
};

export const deleteProfileFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [DeleteProfileFormFields.Reason]: Yup.string().required(t('validation.notEmpty')),
  });

export const emailFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EmailFormFields.Email]: Yup.string()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
  });

export const phoneFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [PhoneFormFields.Region]: Yup.string().required(t('validation.notEmpty')),
    [PhoneFormFields.Phone]: Yup.string()
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.notEmpty')),
  });

export const setInitialProfileFormValues = (profile: ProfileType) => ({
  [ProfileFormFields.Name]: profile[ProfileFormFields.Name],
  [ProfileFormFields.Surname]: profile[ProfileFormFields.Surname],
  [ProfileFormFields.DateOfBirth]: profile.dateOfBirth ? formatDate(profile.dateOfBirth) : '',
  [ProfileFormFields.Gender]: profile[ProfileFormFields.Gender],
  [ProfileFormFields.Region]: profile[ProfileFormFields.Region],
  [ProfileFormFields.Phone]: profile[ProfileFormFields.Phone],
  [ProfileFormFields.Email]: profile[ProfileFormFields.Email],
  [ProfileFormFields.IdNumber]: profile[ProfileFormFields.IdNumber] || '',
  [ProfileFormFields.Country]: profile[ProfileFormFields.Country],
  [ProfileFormFields.City]: profile[ProfileFormFields.City],
  [ProfileFormFields.FullAddress]: profile[ProfileFormFields.FullAddress],
});
