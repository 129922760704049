import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { RouterUrl } from '../routes/routes';
import { updateUser } from '../store/redux-slices/userSlice';
import { AppDispatch } from '../store/store';
import { UserType } from '../types';

interface Provider {
  login: boolean;
  account: UserType & { id: string };
  data: {
    email: string;
    familyName: string;
    givenName: string;
  };
}

function handleUserWithAccount(account: Provider['account'], dispatch: AppDispatch, navigate: NavigateFunction) {
  if (!account) return;

  const {
    email = '',
    name = '',
    surname = '',
    code = '',
    number = '',
    id = '',
    avatarUrl = '',
    idNumber = '',
  } = account;

  dispatch(
    updateUser({
      isLogin: true,
      user: { name, surname, email, code, number, userId: id, avatarUrl, idNumber },
    }),
  );

  navigate(RouterUrl.Homepage);
}

function handleUserWithoutAccount(data: Provider['data'], navigate: NavigateFunction) {
  if (!data) return;

  const { email = '', familyName: surname = '', givenName: name = '' } = data;

  navigate(RouterUrl.Registration, { state: { email, name, surname } });
}

export function authorizeWithProvider(provider: Provider, dispatch: AppDispatch, navigate: NavigateFunction) {
  if (!provider || !provider.login) return;

  try {
    if (provider.account) {
      handleUserWithAccount(provider.account, dispatch, navigate);
    } else if (provider.data) {
      handleUserWithoutAccount(provider.data, navigate);
    } else {
      throw new Error('provider.data or provider.account is undefined');
    }
  } catch (error) {
    throw new Error(`Error during authorization: ${error instanceof Error ? error.message : String(error)}`);
  }
}
