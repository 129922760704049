import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Search } from '../../components/Search';
import { ServiceIconsBlock } from '../../components/Services/components/ServiceIconsBlock';
import { createQueryParams } from '../../helpers';
import { useMediaScreen } from '../../hooks';
import { useGetSearchParams } from '../../routes/hooks/useGetSearchParams';
import OrganizationMap from '../HomePage/components/GoogleMap/OrganizationMap';
import { OffersList } from '../HomePage/components/OffersList';
import { AllOffersWrapper, AllOffersWrapperContainer } from '../HomePage/style';

export const AllOffers = () => {
  const { isMobile } = useMediaScreen('md');
  const navigate = useNavigate();
  const location = useLocation();

  const [queryParams] = useGetSearchParams();

  const [offersView, setOffersView] = useState(queryParams.map ? 'map' : 'list');

  const handleChangeView = () => {
    const newOffersView = offersView === 'list' ? 'map' : 'list';
    setOffersView(newOffersView);

    const params = createQueryParams({ ...queryParams, map: newOffersView === 'map' ? true : '' });
    navigate(location.pathname + params);
  };

  useEffect(() => {
    if (queryParams.map) {
      setOffersView('map');
    }
  }, [queryParams]);

  return (
    <AllOffersWrapper isWide={offersView === 'map'}>
      <AllOffersWrapperContainer>
        {isMobile && <Search />}
        <ServiceIconsBlock isBackground={isMobile && offersView === 'map'} isSlideLink />
        {offersView === 'list' ? <OffersList onChangeView={handleChangeView} /> : null}
      </AllOffersWrapperContainer>

      {offersView === 'map' ? <OrganizationMap onChangeView={handleChangeView} /> : null}
      {/* <FilterSidebar isOpen={sidebarIsOpen} onOpen={handleOpenSidebar} /> */}
    </AllOffersWrapper>
  );
};
