import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper, StyledBodySmall } from '../../../style';
import { SUMMARY_ARTICLES_COUNT, transComponents } from '../constants';

type Props = {
  id: string;
};

export const Summary = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.summary.title')}</PrivacyItemTitleWrapper>
      {Array(SUMMARY_ARTICLES_COUNT)
        .fill(0)
        .map((__, i) => (
          <StyledBodySmall key={i} mb="24px" isMargin={i === 2}>
            <Trans components={transComponents}>{t(`termsOfService.summary.article${i + 1}`)}</Trans>
          </StyledBodySmall>
        ))}
    </PrivacyItemWrapper>
  );
});

Summary.displayName = 'Summary';
