import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { H4, BodySmall, BodyLarge } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper } from '../../../style';

type Props = {
  id: string;
};

export const OurServices = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <H4 mb="24px">{t('termsOfService.ourServices.title')}</H4>
      <BodySmall mb="16px">{t('termsOfService.ourServices.article1')}</BodySmall>
      <BodyLarge large mb="16px">
        {t('termsOfService.ourServices.article2')}
      </BodyLarge>
      <BodySmall mb="16px">{t('termsOfService.ourServices.article3')}</BodySmall>
    </PrivacyItemWrapper>
  );
});

OurServices.displayName = 'OurServices';
