import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { H3, H5, ReviewsRait, FilterByRating, Flex, Slider } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { isRtl } from '../../../../helpers/rtl';
import { getTranslation } from '../../../../helpers/utils';
import { useMediaScreen } from '../../../../hooks';
import { OrganisationRating } from '../../../../types/organisation';
import { BadgesWrapper, RatingWrapper, SliderWrapper } from '../../style';
import { BadgeItem } from './BadgeItem';

interface RatingProps {
  rating: OrganisationRating;
}

const Rating = ({ rating }: RatingProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen();

  const language = getSelectedLanguage();
  const rtl = isRtl();

  const { percentages, badges, reviews, photos } = rating;
  const nonEmptyMark = Object.entries(percentages)
    .sort((firstMark, secondMark) => +secondMark[0] - +firstMark[0])
    .find(percentage => percentage[1]);
  const [active, setActive] = useState(nonEmptyMark ? nonEmptyMark[0] : '0');

  const ratingInfo = {
    reviewsText: t('organisation.reviews', { count: reviews }),
    photosText: t('organisation.photos', { count: photos ? photos - 1 : 0 }),
  };

  const ratingProps = { ...rating, total: rating.rating, ...ratingInfo };

  const badgesBody = badges[active]?.map(badge => (
    <BadgeItem
      key={badge.title?.text}
      avatar={badge.activeIconUrl}
      title={getTranslation(badge.title, language)}
      subtitle={t(`organisation.rating.ratings`, { count: badge.count })}
    />
  ));

  return (
    <RatingWrapper>
      <H3 mb={['32px', '32px', '40px']}>{t('organisation.rating.rating')}</H3>
      <Flex flexDirection="column" gap="40px">
        <ReviewsRait {...ratingProps} containerStyles={(isMobile && { width: '290px' }) || undefined} />
        <Flex flexDirection="column">
          <H5 mb="24px">{t('organisation.rating.rating')}</H5>
          <FilterByRating rating={percentages} active={active} onClick={setActive} />
        </Flex>
        <Flex flexDirection="column">
          <H5 mb="24px">{t('organisation.rating.badges')}</H5>
          <BadgesWrapper>
            {isMobile
              ? !!badgesBody?.length && (
                  <SliderWrapper>
                    <Slider inlineMode slides={badgesBody} rtl={rtl} />
                  </SliderWrapper>
                )
              : badgesBody}
          </BadgesWrapper>
        </Flex>
      </Flex>
    </RatingWrapper>
  );
};

export default Rating;
