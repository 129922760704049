import { useTranslation } from 'react-i18next';
import { H4, BodySmall, colors } from '@beauty/beauty-market-ui';
import LogoutImage from '../../../../../assets/exit-image.png';
import { MissingTimeslotsWrapper } from '../style';

const MissingTimeslots = () => {
  const { t } = useTranslation();

  return (
    <MissingTimeslotsWrapper>
      <img src={LogoutImage} alt={t('logout.altImg')} />
      <H4>{t('organisation.booking.sorryEmpty')}</H4>
      <BodySmall color={colors.grey.dark}>{t('organisation.booking.noSpecialistsForDay')}</BodySmall>
    </MissingTimeslotsWrapper>
  );
};

export default MissingTimeslots;
