import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import moment from 'moment';
import { EventCard } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../../constants';
import { getDateSuffix, getTranslation, scrollTo } from '../../../../helpers';
import { dateFormatter, getCardStatus, timeFormatter } from '../../../../helpers/appointments';
import { useMediaScreen } from '../../../../hooks';
import { AppointmentType } from '../../../../types';
import { EventStatus } from '../constants';
import { CardsWrapper, ScrollWrapper } from '../style';

type EventsListProps = {
  events: AppointmentType[];
  selected: AppointmentType | undefined;
  onButtonClick: (id: string) => void;
};

const eventsContainerId = 'eventsContainer';

export const EventsList = ({ events, selected, onButtonClick }: EventsListProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen('md');

  const language = getSelectedLanguage();
  const eventsRef = useRef<Element>();

  const cards = useMemo(
    () => (
      <CardsWrapper id={eventsContainerId} ref={eventsRef}>
        {events.map(event => {
          const dateSuffix = getDateSuffix(event.start);
          const time = moment.parseZone(event.start).format('HH:mm');
          return (
            <Element data-element-identifier={event.id} name={`event_${event.id}`} key={event.id}>
              <EventCard
                key={event.id}
                label={time}
                service={getTranslation(event.orgService.headOrgService.title, language)}
                placeholder={event.orgService.organization.name}
                status={getCardStatus(event.status as unknown as EventStatus)}
                title={`${dateFormatter(language).format(new Date(event.start))}${dateSuffix ? `, ${t(`appointments.${dateSuffix}`)}` : ''}`}
                isActive={event.id === selected?.id}
                onClick={() => onButtonClick(event.id)}
                maxLeftBlockWidth="100%"
              />
            </Element>
          );
        })}
      </CardsWrapper>
    ),
    [events, selected, language],
  );

  useEffect(() => {
    selected?.id && scrollTo(`event_${selected.id}`, eventsContainerId, isMobile);
  }, [selected?.id]);

  return <ScrollWrapper>{cards}</ScrollWrapper>;
};
