import { Map } from 'google-maps-react-markers';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import {
  BMTheme,
  Button,
  CloseIcon,
  Div,
  MinusIcon,
  NavigationPointerIcon,
  PlusIcon,
  Theme,
} from '@beauty/beauty-market-ui';
import { telAvivGeolocation } from '../../constants';
import { GoogleGeometryType, LatLngLiteral, MapButtonsType, MarkerType } from '../../types';
import { NavigationButton, StyledButton, ZoomButtonsWrapper } from './style';

export const getStyledComponentWrapper = (children: JSX.Element, theme: Theme) => (
  <BMTheme theme={theme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </BMTheme>
);

export const createMapElement = (children: JSX.Element, theme: Theme) => {
  const divWrapper = document.createElement('div');
  const root = createRoot(divWrapper);
  root.render(getStyledComponentWrapper(children, theme));
  return divWrapper;
};

const getZoomControlButtons = (map: Map, defaultZoom: number) => {
  const handleZoomIn = () => {
    map.setZoom((map.getZoom() || defaultZoom) + 1);
  };

  const handleZoomOut = () => {
    map.setZoom((map.getZoom() || defaultZoom) - 1);
  };

  return (
    <ZoomButtonsWrapper>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderBottomRightRadius="0"
        borderBottomLeftRadius="0"
        onClick={handleZoomIn}
      >
        <PlusIcon />
      </Button>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderTopRightRadius="0"
        borderTopLeftRadius="0"
        onClick={handleZoomOut}
      >
        <MinusIcon />
      </Button>
    </ZoomButtonsWrapper>
  );
};

export const getControlButtons = (
  map: Map,
  userLocation: LatLngLiteral,
  defaultZoom: number,
  theme: Theme,
  isDesktop = false,
) =>
  createMapElement(
    <>
      {isDesktop ? (
        getZoomControlButtons(map, defaultZoom)
      ) : (
        <Div mr="16px">
          {/* <Button size="small" design="withShadow" p="10px !important" count={count || false} onClick={onClick}>
          <SlidersIcon />
        </Button> */}
        </Div>
      )}
      <NavigationButton>
        <Button size="small" design="withShadow" p="10px !important" onClick={() => map?.panTo(userLocation)}>
          <NavigationPointerIcon />
        </Button>
      </NavigationButton>
    </>,
    theme,
  );

export const getCloseBtn = ({ onClose, theme }: MapButtonsType) => {
  if (!onClose) return null;

  return createMapElement(
    <StyledButton mt="40px">
      <Button size="small" design="withShadow" onClick={() => onClose()} p="10px !important;">
        <CloseIcon />
      </Button>
    </StyledButton>,
    theme,
  );
};

export const calculateDistance = (geometry: GoogleGeometryType | null, from: LatLngLiteral, to: LatLngLiteral) => {
  const distanceInMeters = geometry ? geometry.computeDistanceBetween(from, to) : 0;
  const distanceInKm = (distanceInMeters / 1000).toFixed(2);
  return distanceInKm;
};

export const getMarkerLatLngLiteral = (marker: MarkerType) => {
  const lat = marker.geometry?.coordinates[1] || telAvivGeolocation.lat;
  const lng = marker.geometry?.coordinates[0] || telAvivGeolocation.lng;
  return { lat, lng };
};

// see https://github.com/mapbox/supercluster/blob/8d2461505b8c3f245b878448be530d0df052959d/index.js#L389
export const xLng = (x: number) => (x - 0.5) * 360;

export const yLat = (y: number) => {
  const y2 = ((180 - y * 360) * Math.PI) / 180;
  return (360 * Math.atan(Math.exp(y2))) / Math.PI - 90;
};
