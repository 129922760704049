import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { H5 } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../../hooks';
import { useAppSelector } from '../../../../store/hooks';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { FoundResponse, MarkerType } from '../../../../types';
import { OrganisationRenderType } from '../../../../types/organisation';
import OrganisationCardWrapper from './OrganisationCardWrapper';
import { FilterResultsWrapper, FilterResultsContent } from './style';

type FilterResultsSidebarProps = {
  sidebarLabel: string;
  hoveredItemId?: string;
  onHover?: (organisation: MarkerType) => void;
  onLeave?: () => void;
  organizationList: (OrganisationRenderType & MarkerType)[];
  currentActiveCard?: MarkerType | null;
};

const FilterResultsSidebar = ({
  sidebarLabel,
  hoveredItemId,
  onHover,
  onLeave,
  organizationList,
  currentActiveCard,
}: FilterResultsSidebarProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const { favourites } = useAppSelector(selectUser);

  const organisationsResults = useMemo(() => {
    let organisationsFiltered = [...organizationList] as (MarkerType & FoundResponse)[];

    if (currentActiveCard) {
      organisationsFiltered = organisationsFiltered.sort((a, b) => {
        if (a.id === currentActiveCard.id) return -1;
        if (b.id === currentActiveCard.id) return 1;
        return 0;
      });
    }

    return organisationsFiltered.map(organisation => (
      <OrganisationCardWrapper
        key={organisation.id}
        organisation={organisation}
        onLeave={onLeave}
        onHover={onHover}
        isActive={organisation.id === currentActiveCard?.id}
      />
    ));
  }, [organizationList, t, hoveredItemId, favourites.ids, currentActiveCard]);

  const content = (
    <>
      <H5>{sidebarLabel}</H5>
      <FilterResultsContent>{organisationsResults}</FilterResultsContent>
    </>
  );

  return isDesktop ? <FilterResultsWrapper>{content}</FilterResultsWrapper> : null;
};

export default FilterResultsSidebar;
