import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export const useScrollBelowLine = (threshold: number, delay = 200) => {
  const [isThreshold, setIsThreshold] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      window.pageYOffset >= threshold ? setIsThreshold(true) : setIsThreshold(false);
    }, delay);

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold, delay]);

  return isThreshold;
};
