import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  H4,
  H5,
  Flex,
  colors,
  Sidebar,
  Button,
  Separator,
  ShowMore,
  ShareButton,
  FavouriteButton,
  Slider,
  DynamicComponent,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { getTranslation } from '../../../helpers/utils';
import { useGetServiceInfo } from '../../../hooks/useGetServiceInfo';
import { useToggle } from '../../../hooks/useToggle';
import { getHoursAndMinutesForUserLocale } from '../../../page/Organisation/helpers';
import useSetCrumbsQuery from '../../../routes/hooks/useSetCrumbsQuery';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectOrganisation } from '../../../store/redux-slices/organisationSlice';
import { updateBooking } from '../../../store/redux-slices/userSlice';
import { TextWithTranslations } from '../../../types';
import { ShareOrganisationModal } from '../../Organisation';
import { ControlButtons, PriceBlock, OldPrice, SliderWrapper } from './style';

interface ServicesSidebarProps {
  setIsOpen: (open: boolean) => void;
  organisationName: string;
  serviceId: string;
  isServiceSelected?: boolean;
}

const getSlides = (photos: string[]) => photos.map(photo => <DynamicComponent as="img" src={photo} key={photo} />);

const ServicesSidebar = ({ setIsOpen, organisationName, serviceId }: ServicesSidebarProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);
  const [isShareModalOpen, setShareModalOpen] = useToggle();
  const { orgId } = useParams();
  const navigate = useNavigate();

  const { serviceInfo: service, isLoading } = useGetServiceInfo(serviceId);
  const { organisation } = useAppSelector(selectOrganisation);

  const dispatch = useAppDispatch();

  const crumbsQuery = useSetCrumbsQuery(organisation?.name || '');

  const serviceSeparator = <Separator mt="24px" mb="32px" />;

  const handleModalOpen = () => {
    setShareModalOpen();
  };

  const closeSidebar = () => setIsOpen(false);

  const handleClickBookNow = useCallback(() => {
    if (!service) return;

    dispatch(updateBooking({ service, timeslot: null }));

    navigate(`${RouterUrl.Booking}/${orgId}${crumbsQuery}`);
  }, [navigate, orgId, crumbsQuery, service, language]);

  const overviewSection = (
    <>
      <Flex mt="24px" justifyContent="space-between">
        <Flex flexDirection="column">
          <H4 mb="16px">{service?.title ? getTranslation(service.title as TextWithTranslations, language) : ''}</H4>
          <PriceBlock>
            <H4 color={colors.blue.standard}>{service?.price}</H4>
            <OldPrice lowline color={colors.grey.dark}>
              {service?.oldPrice}
            </OldPrice>
          </PriceBlock>
        </Flex>
        <ControlButtons>
          {/* <FavouriteButton onClick={setIsOpenModal} /> */}
          <ShareButton onClick={handleModalOpen} />
        </ControlButtons>
      </Flex>
      {serviceSeparator}
    </>
  );

  const serviceAboutSection = (
    <>
      <H5 mb="24px">{t('organisation.booking.serviceAbout')}</H5>
      <ShowMore
        text={service?.description ? getTranslation(service?.description as TextWithTranslations, language) : ''}
        visibleLines={6}
        showMore={t('organisation.about.showMore')}
        showLess={t('organisation.about.showLess')}
      />
      {serviceSeparator}
    </>
  );

  const sidebarConfirmationButton = (
    <>
      <Button width="100%" onClick={handleClickBookNow} disabled={isLoading}>
        {t(`organisation.bookNow`)}
      </Button>
      <Button width="100%" design="secondary" onClick={closeSidebar}>
        {t(`button.cancel`)}
      </Button>
    </>
  );

  const slides = getSlides(service?.photos || []);

  const content = (
    <>
      <SliderWrapper height="244px">
        <Slider slides={slides} isSingleSlide isPaginationEnabled />
      </SliderWrapper>
      {overviewSection}
      {serviceAboutSection}
      {/* <FavouritePopup isOpen={isOpenModal} handlePopupClose={setIsOpenModal} /> */}
      {isShareModalOpen && (
        <ShareOrganisationModal open={isShareModalOpen} onClose={setShareModalOpen} organisationId={orgId || ''} />
      )}
    </>
  );

  const generalProps = {
    isOpen: true,
    onClose: closeSidebar,
    handleClose: closeSidebar,
    label: t('organisation.about.services'),
    descriptor: organisationName,
    FooterBody: sidebarConfirmationButton,
  };

  return isLarge ? (
    <Sidebar {...generalProps}>{content}</Sidebar>
  ) : (
    <BottomSheet {...generalProps} content={content} detent="content-height" />
  );
};

export default ServicesSidebar;
