import { apiRequest } from '../api/api';
import { AuthEndpoints } from '../api/endpoints/endpoints';

export const fetchInviteUserData = async (hash: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: AuthEndpoints.InviteUser,
    endpointParams: hash,
  });
  return response ? { ...response.data, statusCode: response.status } : error?.data;
};
