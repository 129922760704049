import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@beauty/beauty-market-ui';
import { useMediaScreen } from '../../../../hooks';
import { EventStatus } from '../constants';
import { StyledButton } from '../style';
import { TodoType } from '../types';

type HandleButtonsProps = {
  status: EventStatus;
  onRebookAppointment: () => void;
  onSetAction: (action: TodoType) => void;
  onOpenPopup: () => void;
};

export const HandleButtons = ({ status, onRebookAppointment, onSetAction, onOpenPopup }: HandleButtonsProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaScreen();
  const getSpecificButton = (action: TodoType, isDouble = false) => (
    <StyledButton
      width="100%"
      design={isDouble && action === 'manage' ? 'primary' : 'quaternary'}
      onClick={() => {
        if (action === 'rebook') {
          onRebookAppointment();
        } else {
          onSetAction(action);
          onOpenPopup();
        }
      }}
    >
      {t(`appointments.${action}`)}
    </StyledButton>
  );

  switch (status) {
    case EventStatus.CANCELLED:
    case EventStatus.NOSHOW:
      return getSpecificButton('rebook');
    case EventStatus.PAST:
    case EventStatus.UNCLOSED:
      return getSpecificButton('manage');
    case EventStatus.CONFIRMED:
    case EventStatus.PENDING: {
      return (
        <Flex flexDirection={['column', 'column', 'column', 'row']} gap={isMobile ? '8px' : '24px'} width="100%">
          {getSpecificButton('cancel', true)}
          {getSpecificButton('manage', true)}
        </Flex>
      );
    }
    default:
      return <div />;
  }
};
