import React, { useEffect } from 'react';
// TODO: Fix eslint issue
// eslint-disable-next-line
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from 'styled-components';
import { BMTheme } from '@beauty/beauty-market-ui';
import packageJson from '../package.json';
import { useTheme, useEqualWeb } from './hooks';
import Router from './routes/Router';
import { useAppSelector } from './store/hooks';
import { isShowFooter } from './store/redux-slices/footerSlice';
import { isShowHeader } from './store/redux-slices/headerSlice';
import { AppWrapper } from './style';

ReactGA.initialize('G-BQ4GX4GDZD');

const App = () => {
  const theme = useTheme();
  const isFooterVisible = useAppSelector(isShowFooter);
  const isHeaderVisible = useAppSelector(isShowHeader);

  useEqualWeb();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const isVersionNotMatch = localStorage.getItem('version') !== packageJson.version;
    if (isVersionNotMatch) {
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }, []);

  return (
    <BMTheme theme={theme}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
          <AppWrapper isShowFooter={isFooterVisible} isShowHeader={isHeaderVisible}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </AppWrapper>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </BMTheme>
  );
};

export default withTranslation()(App);
