import React from 'react';
import { MarkerWrapper } from './style';

interface MapMarkerProps extends React.PropsWithChildren {
  zIndex?: number;
  onClick?: () => void;
  lng?: number;
  lat?: number;
}

const MapMarker = ({ onClick, zIndex, children, ...props }: MapMarkerProps) => (
  <MarkerWrapper onClick={onClick} zIndex={zIndex} {...props}>
    {children}
  </MarkerWrapper>
);

export default MapMarker;
