import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SlideObject } from '@beauty/beauty-market-ui/dist/cjs/components/molecules/CalendarSlider/types';
import moment from 'moment/moment';
import { CalendarSlider } from '@beauty/beauty-market-ui';
import { getSelectedLanguage } from '../../../constants';
import { DateSlot, DateslotStatus } from '../../../types/booking';
import { StickyWrapper } from './style';

interface SliderDatesProps extends React.PropsWithChildren {
  onSelectDate: (newDate: Date) => void;
  currentDate: Date;
  title: JSX.Element;
  onCalendarCardClick: (date: Date) => void;
  dates: DateSlot[];
}

const SliderDates = ({ onSelectDate, currentDate, title, onCalendarCardClick, dates }: SliderDatesProps) => {
  const { t } = useTranslation();
  const selectedLanguage = getSelectedLanguage();

  const sliderDates: SlideObject[] = useMemo(
    () =>
      dates.map(day => {
        const currentDay = moment(day.date, 'YYYY-MM-DD').startOf('day');
        return {
          date: currentDay.toDate(),
          disabled: day.status === DateslotStatus.Holiday,
          isBenefit: day.discount.length > 0 && day.status === DateslotStatus.Available,
        };
      }),
    [dates],
  );

  return (
    <StickyWrapper>
      <CalendarSlider
        key={selectedLanguage}
        dates={sliderDates}
        userLocale={selectedLanguage.toLowerCase()}
        selectedDate={currentDate}
        onSelectedDate={date => onSelectDate(date as Date)}
        todayTitle={t('organisation.booking.today').toUpperCase()}
        titleBottomMargin={16}
        onCalendarCardClick={onCalendarCardClick}
        buttonLabel={t('organisation.booking.selectDate')}
        title={title}
        isWheel
      />
    </StickyWrapper>
  );
};

export default SliderDates;
