import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import { Flex, H2 } from '@beauty/beauty-market-ui';
import { AnchorMenuSpy } from '../../components/AnchorMenuSpy/AnchorMenuSpy';
import { PrivacyWrapper, StyledCaption, StyledContainer } from '../../style';
import {
  HowToWork,
  Introduction,
  KeyTerms,
  OurPartnersServices,
  OurServices,
  PayingForPartners,
  Summary,
  UsingPlatform,
  Termination,
  General,
} from './components';
import { Changes } from './components/Changes';

export const sections = {
  summary: Summary,
  introduction: Introduction,
  keyTerms: KeyTerms,
  ourServices: OurServices,
  ourPartnersServices: OurPartnersServices,
  usingPlatform: UsingPlatform,
  howToWork: HowToWork,
  payingForPartners: PayingForPartners,
  changes: Changes,
  termination: Termination,
  general: General,
};

export const TermsOfService = () => {
  const { t } = useTranslation();

  const anchors = Object.keys(sections).map(title => ({
    title: t(`termsOfService.${title}.title`),
    key: title,
  }));

  useEffect(() => {
    const { hash } = window.location;
    if (!hash) return;

    scroller.scrollTo(hash.substring(1), {
      duration: 500,
      smooth: true,
    });
  }, []);

  return (
    <StyledContainer>
      <PrivacyWrapper>
        <AnchorMenuSpy menuItems={anchors} />

        <Flex width="100%" flexDirection="column" ml={['0', '0', '0', '38px']}>
          <H2 mb="8px">{t('termsOfService.title')}</H2>
          <StyledCaption>{t('termsOfService.lastUpdated')}</StyledCaption>
          <Flex flexDirection="column" maxWidth="620px">
            {Object.keys(sections).map(key => {
              const Section = sections[key];
              return <Section key={key} id={key} />;
            })}
          </Flex>
        </Flex>
      </PrivacyWrapper>
    </StyledContainer>
  );
};
