import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, BodySmall } from '@beauty/beauty-market-ui';
import { PrivacyItemWrapper, PrivacyItemTitleWrapper } from '../../../style';
import {
  transComponents,
  USING_PLATFORM_SECTIONS_BODY_PARTS_COUNT,
  USING_PLATFORM_SECTIONS_COUNT,
  USING_PLATFORM_SECTION_2_SUBSECTIONS_COUNT,
} from '../constants';
import { Section } from './Section';

type Props = {
  id: string;
};

export const UsingPlatform = memo(({ id }: Props) => {
  const { t } = useTranslation();

  return (
    <PrivacyItemWrapper id={id}>
      <PrivacyItemTitleWrapper>{t('termsOfService.usingPlatform.title')}</PrivacyItemTitleWrapper>
      <BodySmall mb="24px">{t('termsOfService.usingPlatform.article1')}</BodySmall>
      {Array(USING_PLATFORM_SECTIONS_COUNT)
        .fill(0)
        .map((__, index) => (
          <Section
            // eslint-disable-next-line react/no-array-index-key
            key={index + 1}
            title={t(`termsOfService.usingPlatform.section${index + 1}.title`)}
            body={Array(USING_PLATFORM_SECTIONS_BODY_PARTS_COUNT[index])
              .fill(0)
              .map((___, i) => t(`termsOfService.usingPlatform.section${index + 1}.body.part${i + 1}`))}
            subSection={
              index === 1
                ? Array(USING_PLATFORM_SECTION_2_SUBSECTIONS_COUNT)
                    .fill(0)
                    .map((____, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Flex key={i + 1} flexDirection="column" mb="24px">
                        <BodySmall large mb="8px">
                          <Trans components={transComponents}>
                            {t(`termsOfService.usingPlatform.section${index + 1}.subsections.subsection${i + 1}.title`)}
                          </Trans>
                        </BodySmall>
                        <BodySmall>
                          <Trans components={transComponents}>
                            {t(`termsOfService.usingPlatform.section${index + 1}.subsections.subsection${i + 1}.body`)}
                          </Trans>
                        </BodySmall>
                      </Flex>
                    ))
                : undefined
            }
          />
        ))}
    </PrivacyItemWrapper>
  );
});

UsingPlatform.displayName = 'UsingPlatform';
