import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, H3, DropdownMenu } from '@beauty/beauty-market-ui';
import { ID_FOR_ANY_SPECIALIST } from '../../../../constants';
import { useGetDateSlots } from '../../../../hooks/useGetDateSlots';
import { useGetTimeslots } from '../../../../hooks/useGetTimeslots';
import { RouterUrl } from '../../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  currentOrganization,
  currentService,
  currentSpecialist,
  setSelectedDay,
} from '../../../../store/redux-slices/bookingSlice';
import { BookingParams } from '../../../../types/booking';
import { getAnySpecialistState } from '../../helpers';
import LoaderComponent from '../LoaderComponent';
import SliderDates from '../SliderDates';
import MissingTimeslots from './components/MissingTimeslots';
import TimeslotsDay from './components/TimeslotsDay';
import { convertTeamForDropDown } from './helpers';
import { Wrapper } from './style';

const Timeslots = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { orgId, serviceId, specialistId } = useParams() as BookingParams;

  const service = useAppSelector(currentService);
  const organisation = useAppSelector(currentOrganization);
  const specialist = useAppSelector(currentSpecialist);

  const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));

  const { timeslots, isLoading: isLoadingTimeslots } = useGetTimeslots(selectedDate, service?.id, specialist?.id);

  const { dateSlots, isLoading: isLoadingDates } = useGetDateSlots({
    serviceId: service?.id,
    specialistId: specialist?.id,
  });

  const team = service?.orgSpecialist;
  const specialists = useMemo(() => {
    const allSpecialistInfo = convertTeamForDropDown(
      [
        getAnySpecialistState(
          t('organisation.booking.allSpecialists'),
          specialistId === ID_FOR_ANY_SPECIALIST,
          organisation?.mainPhoto,
        ),
      ],
      specialist?.id,
    );

    if (!team) {
      return allSpecialistInfo;
    }

    const updatedTeam = convertTeamForDropDown(team, specialist?.id);

    return [...allSpecialistInfo, ...updatedTeam].filter(person => person.published);
  }, [specialist, team, t]);

  const handleClickSpecialist = specialistList => {
    const selectedSpecialist = specialistList?.find(spec => spec.selected);
    if (!selectedSpecialist) return;

    navigate(`${RouterUrl.Booking}/${orgId}/${serviceId}/${selectedSpecialist.id}`);
  };

  const specialistDropdownMenu = (
    <Flex>
      <DropdownMenu
        options={specialists}
        onChange={handleClickSpecialist}
        selectedOption={specialists.find(spec => spec.selected) || specialists[0]}
      />
    </Flex>
  );

  useEffect(() => {
    dispatch(setSelectedDay(timeslots?.date || null));

    return () => {
      dispatch(setSelectedDay(null));
    };
  }, [timeslots]);

  if (isLoadingDates) {
    return (
      <>
        <H3 mb={16}>{t('organisation.booking.dateOfVisit')}</H3>
        <LoaderComponent />
      </>
    );
  }

  return (
    <>
      {dateSlots && (
        <SliderDates
          title={specialistDropdownMenu}
          currentDate={selectedDate}
          onCalendarCardClick={setSelectedDate}
          onSelectDate={setSelectedDate}
          dates={dateSlots}
        />
      )}
      {isLoadingTimeslots ? (
        <LoaderComponent />
      ) : (
        <Wrapper>
          {timeslots && timeslots.timeSlots?.length > 0 ? (
            <TimeslotsDay day={timeslots.date} timeslots={timeslots.timeSlots} />
          ) : (
            <MissingTimeslots />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Timeslots;
