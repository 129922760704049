import React from 'react';
import { Avatar, Flex } from '@beauty/beauty-market-ui';
import { StyledProfileIcon } from '../style';

export const avatarSizes = {
  xxs: '24px',
  xs: '40px',
  s: '56px',
  m: '80px',
  l: '100px',
  xl: '120px',
};

interface Props extends React.PropsWithChildren {
  avatarUrl?: string;
  avatarSize?: keyof typeof avatarSizes;
}

const AvatarSpecialist: React.FC<Props> = ({ avatarUrl, avatarSize = 'xs' }) => (
  <Flex minWidth={avatarSizes[avatarSize]}>
    {avatarUrl ? <Avatar url={avatarUrl} size={avatarSize} /> : <StyledProfileIcon />}
  </Flex>
);

export default AvatarSpecialist;
