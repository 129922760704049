import React from 'react';
import { Loader } from '@beauty/beauty-market-ui';
import { WrapperLoader } from './Timeslots/style';

const LoaderComponent = () => (
  <WrapperLoader>
    <Loader />
  </WrapperLoader>
);

export default LoaderComponent;
