import styled, { css } from 'styled-components';
import { Flex, colors, Div, mediaQueries } from '@beauty/beauty-market-ui';
import { iconRevert } from '../../helpers/rtl';

export const GoogleMapWrapper = styled(Div)`
  position: relative;
  width: 100%;
  height: 100%;
  direction: ltr;
`;

export const MapPointLocationWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: ${colors.blue.light};
  border: 1px solid ${colors.blue.medium};
  border-radius: 50%;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
`;

export const MapPointLocationCenter = styled(Div)`
  width: 10px;
  height: 10px;
  background: ${colors.blue.medium};
  border-radius: 50%;
`;

export const CardWrapper = styled(Div)<{ isDown: boolean }>`
  position: absolute;

  ${({ isDown }) => (isDown ? `top: 12px;` : `bottom: 22px;`)}

  ${mediaQueries.md} {
    bottom: 44px;
    transform: translateZ(0) translate(-50%);
  }
  [dir='rtl'] & {
    direction: rtl;
  }
`;

export const NavigationButton = styled(Div)`
  margin-top: 24px;
  margin-bottom: 24px;

  ${({ theme }) => css`
        margin-${theme.rtl ? 'left' : 'right'}: 24px;
        ${theme.rtl && iconRevert}
    `};
`;

export const ZoomButtonsWrapper = styled(Flex)`
  ${({ theme }) => `margin-${theme.rtl ? 'left' : 'right'}: 24px;`};
  flex-direction: column;
  justify-content: center;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
`;

export const StyledButton = styled(Flex)`
  margin-top: 40px;
  button {
    ${({ theme }) => `margin-${theme.rtl ? 'left' : 'right'}: 24px;`};
  }
`;
