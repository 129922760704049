import styled, { css } from 'styled-components';
import { Div } from '@beauty/beauty-market-ui';
import { ContainerProps } from '../Container/types';

export const BreadcrumbsContainer = styled(Div)<ContainerProps>`
  margin-bottom: 30px;
  padding-bottom: 10px;

  ${({ fixed }) =>
    fixed &&
    css`
      position: sticky;
      top: 80px;
      z-index: 3;
      width: 100%;
      background-color: white;
    `};

  @media (max-width: 991px) {
    display: none !important;
  }
`;
