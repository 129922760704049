import { useEffect, useState } from 'react';
import moment from 'moment';
import { ID_FOR_ANY_SPECIALIST } from '../constants';
import { fetchDateSlots } from '../helpers/organisation';
import { DateSlot } from '../types/booking';

interface GetDateSlotsParams {
  date?: Date;
  serviceId?: string;
  specialistId?: string;
  days?: number;
}

export const useGetDateSlots = (params: GetDateSlotsParams) => {
  const { date, serviceId, specialistId, days = 90 } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [dateSlots, setDateSlots] = useState<DateSlot[] | null>(null);

  const specId = specialistId !== ID_FOR_ANY_SPECIALIST ? specialistId : undefined;

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const request = async () => {
      if (!serviceId) return;
      setIsLoading(true);

      const now = moment();
      const adjustedDate = moment(date).isBefore(now) ? now : moment(date).startOf('day');

      try {
        const response: DateSlot[] = await fetchDateSlots(
          serviceId,
          {
            date: adjustedDate.format('YYYY-MM-DD'),
            days,
            orgSpecId: specId,
          },
          signal,
        );

        !signal.aborted && setDateSlots(response);
      } finally {
        !signal.aborted && setIsLoading(false);
      }
    };

    request();

    return () => {
      abortController.abort();
      setIsLoading(false);
    };
  }, [serviceId, specId, date]);

  return {
    isLoading,
    dateSlots,
  };
};
