import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from '../api/api';
import { ServicesEndpoints } from '../api/endpoints/endpoints';
import { getSelectedLanguage } from '../constants';
import { getTranslation } from '../helpers';
import { ID_ALL_CATEGORY, ServiceIcons, sortedTopCategories } from '../page/HomePage/constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { allTopCategories, setAllTopCategories } from '../store/redux-slices/categoriesSlice';
import { TopCategory, TopCategoryWithIcon } from '../types';

export const useGetTopCategories = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const language = getSelectedLanguage();

  const allCategories = useAppSelector(allTopCategories);

  const topCategoriesWithIcons: TopCategoryWithIcon[] = useMemo(
    () =>
      allCategories
        .map(category => ({
          icon: ServiceIcons[category.id] as ReactNode,
          title: category.title,
          id: category.id,
        }))
        .sort((a, b) => sortedTopCategories.indexOf(a.id) - sortedTopCategories.indexOf(b.id)),
    [allCategories, sortedTopCategories],
  );

  const AllCategoryItem = { title: t('home.services.all'), id: ID_ALL_CATEGORY };

  useEffect(() => {
    const fetchTopCategories = async () => {
      setIsLoading(true);
      const [, response] = await apiRequest.get({
        endpoint: ServicesEndpoints.TopCategories,
      });

      if (!response) return;
      const topCategoriesFromApi: TopCategory[] = response.data.map(category => ({
        id: category.title!.text,
        title: getTranslation(category.title, language),
      }));

      dispatch(setAllTopCategories([AllCategoryItem, ...topCategoriesFromApi]));

      setIsLoading(false);
    };

    fetchTopCategories();
  }, [language, t]);

  return {
    isLoading,
    topCategoriesWithIcons,
  };
};
