import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BodyLarge, BottomSheet, Button, Flex, Sidebar } from '@beauty/beauty-market-ui';
import { tokenService } from '../../api';
import LogoutImage from '../../assets/exit-image.png';
import { authAPI } from '../../helpers/authAPI';
import { useMediaScreen } from '../../hooks';
import { RouterUrl } from '../../routes/routes';
import { CommonActions } from '../../store/actions';

interface LogoutSidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutSidebar = ({ isOpen, onClose }: LogoutSidebarProps) => {
  const { t } = useTranslation();
  const { isDesktop } = useMediaScreen('md');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = async () => {
    await authAPI.logout();
    dispatch(CommonActions.logout());
    tokenService.removeAccessToken();
    return onClose();
  };

  const onLoginAnotherUser = async () => {
    await onLogout();
    navigate(RouterUrl.Login);
  };

  const FooterBody = (
    <Flex flexDirection="column" justifyContent="space-between" alignItems="center" width="100%" minHeight={200}>
      <Button size="large" width="100%" onClick={onLogout}>
        {t('logout.logoutButton')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={onClose}>
        {t('logout.cancelButton')}
      </Button>
      <Button design="tertiary" onClick={onLoginAnotherUser} size="medium">
        {t('logout.logIn')}
      </Button>
    </Flex>
  );
  const content = <BodyLarge>{t('logout.description')}</BodyLarge>;
  const logoutSomeProps = {
    isOpen,
    label: t('logout.title'),
    onClose,
    handleClose: onClose,
    FooterBody,
  };

  return isDesktop ? (
    <Sidebar {...logoutSomeProps} descriptor={t('logout.description')}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <img src={LogoutImage} alt={t('logout.altImg')} />
      </Flex>
    </Sidebar>
  ) : (
    <BottomSheet {...logoutSomeProps} content={content} detent="content-height" />
  );
};

export default LogoutSidebar;
